/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { Text as NBText } from 'native-base';
import { Text as RNText } from 'react-native';

/* ----- INTERFACES ----- */
import PlatformInterface from './src';


const App = () => {
    useEffect(() => {
        // Add the lines below to set default props for Text component
        // Set default prop for React Native Text
        if (RNText.defaultProps == null) RNText.defaultProps = {};
        RNText.defaultProps.allowFontScaling = false;

        // Set default prop for Native Base Text
        if (NBText.defaultProps == null) NBText.defaultProps = {};
        NBText.defaultProps.allowFontScaling = false;

        // Cleanup function (optional)
        return () => {
            // Perform cleanup if needed
        };
    }, []); // Empty dependency array ensures that the effect runs once when the component mounts

    return <PlatformInterface />;
};

export default App;