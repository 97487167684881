import React from "react";
import {Modal, VStack, HStack, Text } from "native-base";
import PropTypes from "prop-types";
import Moment from "moment";

const ViewTransactionDetails = (props) => {
  const { rowData } = props;
  return (
    <Modal.Content>
      <Modal.Header alignItems={'center'}>{"Stripe Transaction Details"}</Modal.Header>
      <Modal.Body>
        {rowData?.stripePaymentId === null || rowData?.stripePaymentId ==="" ?
        <Text paddingY={15} fontWeight={'700'} textAlign={'center'} fontSize={16}>No transaction details found!</Text>
      :
      <VStack space={3} px="3">
          <HStack space={20}>
              <Text underline={false} fontWeight={'700'} fontSize={16} w={140}>Transaction Id</Text>
              <Text underline={false} fontWeight={'500'} fontSize={16} >{rowData?.stripePaymentId}</Text>
          </HStack>

          <HStack space={20}>
              <Text underline={false} fontWeight={'700'} fontSize={16} w={140}>Transaction Time</Text>
              <Text underline={false} fontWeight={'500'} fontSize={16} >{rowData?.transactionDate && Moment(rowData?.transactionDate).format('MM/DD/YYYY')} {rowData?.transactionTime}</Text>
          </HStack>

          <HStack space={20}>
              <Text underline={false} fontWeight={'700'} fontSize={16} w={140}>Deposit Amount</Text>
              <Text underline={false} fontWeight={'500'} fontSize={16} >{rowData?.stripeSubTotal && rowData?.stripeSubTotal.toFixed(2)}</Text>
          </HStack>

          <HStack space={20}>
              <Text underline={false} fontWeight={'700'} fontSize={16} w={140}>Total Amount</Text>
              <Text underline={false} fontWeight={'500'} fontSize={16} >{rowData?.stripeTotal && rowData?.stripeTotal.toFixed(2)}</Text>
          </HStack>
      </VStack>}

      </Modal.Body>
    </Modal.Content>
  );
};

ViewTransactionDetails.propTypes = {
  loginResponse: PropTypes.any,
};


export default ViewTransactionDetails;
