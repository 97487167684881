// Redux :: Action Types
import {
  RESERVATION_IDLE,
  RESERVATION_PENDING,
  RESERVATION_FAILED,
  RESERVATION_SUCCEEDED,
  //Reservation Detail
  RESERVATION_DETAIL_IDLE,
  RESERVATION_DETAIL_PENDING,
  RESERVATION_DETAIL_FAILED,
  RESERVATION_DETAIL_SUCCEEDED,
  //user Detail
  GET_USER_DETAIL_IDLE,
  GET_USER_DETAIL_PENDING,
  GET_USER_DETAIL_FAILED,
  GET_USER_DETAIL_SUCCEEDED,
  // Table Detail for Reservation 
  GET_TABLE_DETAIL_IDLE,
  GET_TABLE_DETAIL_PENDING,
  GET_TABLE_DETAIL_FAILED,
  GET_TABLE_DETAIL_SUCCEEDED,
  // Web Reservation
  GET_WEB_RESERVATION_BY_VENUE_ID_IDLE,
  GET_WEB_RESERVATION_BY_VENUE_ID_PENDING,
  GET_WEB_RESERVATION_BY_VENUE_ID_FAILED,
  GET_WEB_RESERVATION_BY_VENUE_ID_SUCCEEDED,

  //** Get current date by venue id */
  GET_CURRENT_DATE_BY_VENUE_ID_IDLE,
  GET_CURRENT_DATE_BY_VENUE_ID_PENDING,
  GET_CURRENT_DATE_BY_VENUE_ID_FAILED,
  GET_CURRENT_DATE_BY_VENUE_ID_SUCCEEDED,

  SET_RESERVATION_FILTER_STATE,

  //Filter reservation for venue online reservation screen 
  FILTER_WIDGET_RESERVATION_IDLE,
  FILTER_WIDGET_RESERVATION_PENDING,
  FILTER_WIDGET_RESERVATION_FAILED,
  FILTER_WIDGET_RESERVATION_SUCCEEDED,
  SET_FILTER_WIDGET_RESERVATION_FILTER_STATE,

  //Header Filter reservation for venue online reservation screen 
  HEADER_FILTER_WIDGET_RESERVATION_IDLE,
  HEADER_FILTER_WIDGET_RESERVATION_PENDING,
  HEADER_FILTER_WIDGET_RESERVATION_FAILED,
  HEADER_FILTER_WIDGET_RESERVATION_SUCCEEDED,

  // Widget Venue Time for Table
  GET_WIDGET_VENUE_TIME_PENDING,
  GET_WIDGET_VENUE_TIME_IDLE,
  GET_WIDGET_VENUE_TIME_FAILED,
  GET_WIDGET_VENUE_TIME_SUCCEEDED

} from '../constants';

/* ----- INITIAL STATE ----- */
const initialState = {
  loading: false,
  idleErr: null,
  reservationErr: null,
  reservationResponse: [],
  getVenueCurrentDate: ""
}

/* ----- RESERVATION REDUCER ----- */
const reservationReducer = (state = initialState, action) => {
  switch (action.type) {

    case RESERVATION_PENDING:
      return {
        ...state,
        loading: true,
        reservationResponse: []
      };
    case RESERVATION_IDLE:
      return {
        ...state,
        loading: false,
        idleErr: action.payload
      };
    case RESERVATION_SUCCEEDED:
      return {
        ...state,
        loading: false,
        reservationResponse: action.payload
      };

    case RESERVATION_FAILED:
      return {
        ...state,
        loading: false,
        reservationErr: action.payload
      };

    case GET_CURRENT_DATE_BY_VENUE_ID_PENDING:
      return {
        ...state,
        loading: true,
        getVenueCurrentDate: ""
      };
    case GET_CURRENT_DATE_BY_VENUE_ID_IDLE:
      return {
        ...state,
        loading: false,
        idleErr: action.payload
      };
    case GET_CURRENT_DATE_BY_VENUE_ID_SUCCEEDED:
      return {
        ...state,
        loading: false,
        getVenueCurrentDate: action.payload?.venueCurrentDate
      };

    case GET_CURRENT_DATE_BY_VENUE_ID_FAILED:
      return {
        ...state,
        loading: false,
        reservationErr: action.payload
      };

    default:
      return state;

  }
}


/* ----- RESERVATION DETAIL INITIAL STATE ----- */
const ReservationDetailInitialState = {
  loading: false,
  idleErr: null,
  reservationDetailErr: null,
  reservationDetailResponse: [],
}

/* ----- RESERVATION DETAIL REDUCER ----- */
const reservationDetailReducer = (state = ReservationDetailInitialState, action) => {
  switch (action.type) {

    case RESERVATION_DETAIL_PENDING:
      return {
        ...state,
        loading: true,
        reservationDetailResponse: []
      };
    case RESERVATION_DETAIL_IDLE:
      return {
        ...state,
        loading: false,
        idleErr: action.payload
      };
    case RESERVATION_DETAIL_SUCCEEDED:
      return {
        ...state,
        loading: false,
        reservationDetailResponse: action.payload
      };

    case RESERVATION_DETAIL_FAILED:
      return {
        ...state,
        loading: false,
        reservationErr: action.payload
      };

    default:
      return state;

  }
}


/* -----  USER SEARCH INITIAL STATE ----- */
const userSearchInitialState = {
  loading: false,
  idleErr: null,
  userSearchErr: null,
  userSearchResponse: [],
}

/* ----- User Search REDUCER ----- */
const userSearchReducer = (state = userSearchInitialState, action) => {
  switch (action.type) {

    case GET_USER_DETAIL_PENDING:
      return {
        ...state,
        loading: true,
        userSearchResponse: []
      };
    case GET_USER_DETAIL_IDLE:
      return {
        ...state,
        loading: false,
        idleErr: action.payload
      };
    case GET_USER_DETAIL_SUCCEEDED:
      return {
        ...state,
        loading: false,
        userSearchResponse: action.payload
      };
    case GET_USER_DETAIL_FAILED:
      return {
        ...state,
        loading: false,
        userSearchErr: action.payload
      };

    default:
      return state;

  }
}

/* -----  CREATE RESERVATION INITIAL STATE ----- */
const createReservationInitialState = {
  loading: false,
  idleErr: null,
  createReservationErr: null,
  createReservationResponse: [],
}

/* ----- Create Reservation REDUCER ----- */
const createReservationReducer = (state = createReservationInitialState, action) => {
  switch (action.type) {

    case GET_USER_DETAIL_PENDING:
      return {
        ...state,
        loading: true,
        createReservationResponse: []
      };
    case GET_USER_DETAIL_IDLE:
      return {
        ...state,
        loading: false,
        idleErr: action.payload
      };
    case GET_USER_DETAIL_SUCCEEDED:
      return {
        ...state,
        loading: false,
        createReservationResponse: action.payload
      };
    case GET_USER_DETAIL_FAILED:
      return {
        ...state,
        loading: false,
        createReservationErr: action.payload
      };

    default:
      return state;

  }
}

/* -----  GET TABLE DETAIL INITIAL STATE ----- */
const tableDetailInitialState = {
  loading: false,
  idleErr: null,
  tableErr: null,
  tableResponse: [],
  tableLookUp: [],
  venueTimeDetails: {},
}

/* ----- GET TABLE DETAIL REDUCER ----- */
const tableDetailReducer = (state = tableDetailInitialState, action) => {
  switch (action.type) {

    case GET_TABLE_DETAIL_PENDING:
      return {
        ...state,
        loading: true,
        tableResponse: [],
        // tableLookUp: [],
      };
    case GET_TABLE_DETAIL_IDLE:
      return {
        ...state,
        loading: false,
        idleErr: action.payload
      };
    case GET_TABLE_DETAIL_SUCCEEDED: {
      let LookUpArr = action.payload && action.payload.length > 0 ? action.payload : [];
      LookUpArr = LookUpArr.map(e => {
        return {
          value: e.tableid,
          label: e.tableName,
          amount: e.minimumSpendAmount,
          guestCount: e.count,
          itemType: 'TABLE'
        }
      });
      return {
        ...state,
        loading: false,
        tableResponse: action.payload,
        tableLookUp: LookUpArr,
      };
    }
    case GET_TABLE_DETAIL_FAILED:
      return {
        ...state,
        loading: false,
        tableErr: action.payload
      };

    // get venue time details for tables

    case GET_WIDGET_VENUE_TIME_PENDING:
      return {
        ...state,
        loading: true,
        venueTimeDetails: {},
      };
    case GET_WIDGET_VENUE_TIME_IDLE:
      return {
        ...state,
        loading: false,
        venueTimeDetails: action.payload
      };
    case GET_WIDGET_VENUE_TIME_SUCCEEDED:
      return {
        ...state,
        loading: false,
        venueTimeDetails: action.payload
      };

    case GET_WIDGET_VENUE_TIME_FAILED:
      return {
        ...state,
        loading: false,
        venueTimeDetails: action.payload
      };

    default:
      return state;

  }
}

/* ----- WEB RESERVATION INITIAL STATE ----- */
const webReservationInitialState = {
  loading: false,
  idleErr: null,
  webReservationErr: null,
  webReservationResponse: [],
}

/* ----- WEB RESERVATION REDUCER ----- */
const webReservationReducer = (state = webReservationInitialState, action) => {
  switch (action.type) {

    case GET_WEB_RESERVATION_BY_VENUE_ID_PENDING:
      return {
        ...state,
        loading: true,
        webReservationResponse: []
      };
    case GET_WEB_RESERVATION_BY_VENUE_ID_IDLE:
      return {
        ...state,
        loading: false,
        idleErr: action.payload
      };
    case GET_WEB_RESERVATION_BY_VENUE_ID_SUCCEEDED:
      return {
        ...state,
        loading: false,
        webReservationResponse: action.payload
      };

    case GET_WEB_RESERVATION_BY_VENUE_ID_FAILED:
      return {
        ...state,
        loading: false,
        webReservationErr: action.payload
      };

    default:
      return state;

  }
}

/* -----  RESERVATION INITIAL STATE ----- */
const initialReservationFilterState = {
  date: new Date(),
  filterBy: 'timeslot'
}

/* -----  RESERVATION REDUCER ----- */
const reservationFilterReducer = (state = initialReservationFilterState, action) => {
  switch (action.type) {
    case SET_RESERVATION_FILTER_STATE:
      return {
        ...state,
        date: action.payload?.date,
        filterBy: action.payload?.filterBy
      };
    default:
      return state;
  }
}

/* ----- Online Widget reservation  ----- */
const onlineWidgetFilterInitialState = {
  loading: false,
  idleErr: null,
  reservationErr: null,
  reservationResponse: [],
}

const onlineWidgetFilterReducer = (state = onlineWidgetFilterInitialState, action) => {
  switch (action.type) {

    case FILTER_WIDGET_RESERVATION_PENDING:
      return {
        ...state,
        loading: true,
        reservationResponse: []
      };
    case FILTER_WIDGET_RESERVATION_IDLE:
      return {
        ...state,
        loading: false,
        idleErr: action.payload
      };
    case FILTER_WIDGET_RESERVATION_SUCCEEDED:
      return {
        ...state,
        loading: false,
        reservationResponse: action.payload
      };

    case FILTER_WIDGET_RESERVATION_FAILED:
      return {
        ...state,
        loading: false,
        reservationErr: action.payload
      };

    default:
      return state;

  }
}

/* ----- Header Online Widget reservation  ----- */
const onlineHeaderWidgetFilterInitialState = {
  loading: false,
  idleErr: null,
  reservationErr: null,
  reservationResponse: [],
}

const onlineHeaderWidgetFilterReducer = (state = onlineHeaderWidgetFilterInitialState, action) => {
  switch (action.type) {

    case HEADER_FILTER_WIDGET_RESERVATION_PENDING:
      return {
        ...state,
        loading: true,
        reservationResponse: []
      };
    case HEADER_FILTER_WIDGET_RESERVATION_IDLE:
      return {
        ...state,
        loading: false,
        idleErr: action.payload
      };
    case HEADER_FILTER_WIDGET_RESERVATION_SUCCEEDED:
      return {
        ...state,
        loading: false,
        reservationResponse: action.payload
      };

    case HEADER_FILTER_WIDGET_RESERVATION_FAILED:
      return {
        ...state,
        loading: false,
        reservationErr: action.payload
      };

    default:
      return state;

  }
}

//temp 
const incrementMonthByCount = (count) => {
  let d = new Date();
  d.setMonth(d.getMonth() + count);

  return d;
};
/* ----- Online Widget reservation state ----- */
const initialOnlineReservationFilterState = {
  date: new Date(),
  endDate: incrementMonthByCount(1),
  filterBy: 'timeslot'
}

const onlineWidgetReservationFilterStateReducer = (state = initialOnlineReservationFilterState, action) => {
  switch (action.type) {

    case SET_FILTER_WIDGET_RESERVATION_FILTER_STATE:
      return {
        ...state,
        date: action.payload?.date,
        endDate: action.payload?.endDate,
        filterBy: action.payload?.filterBy
      };

    default:
      return state;
  }
}

export { reservationReducer, reservationDetailReducer, userSearchReducer, createReservationReducer, tableDetailReducer, webReservationReducer, reservationFilterReducer, onlineWidgetReservationFilterStateReducer, onlineWidgetFilterReducer, onlineHeaderWidgetFilterReducer };