import messaging from '@react-native-firebase/messaging';
import {isTablet} from 'react-native-device-info';

/* ----- REDUX :: STORE ----- */
import { persistor } from '../../shared/redux/store/configureStore';

// const isTablet = () => isTablet();

const formatPhoneNumber = (value, previousValue) => {
  // return nothing if no value
  if (!value) return value;

  // only allows 0-9 inputs
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {

    // returns: "x", "xx", "xxx"
    if (cvLength < 4) return currentValue;

    // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
    if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;

    // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
  }
};

const UpdateNotifiedRouteName = (route) => {
  switch (route) {
    case "Cancelation Request":
      return "Reservation";
      case "Reservation Request":
        return "Reservation";
    case "Check In":
      return "Queue";
    case "New Request":
      return "Queue";
    case "Requested Manager":
      return "Queue";

    default:
      return ""
  }
}

const purgePersistStorage = async () => {
  await persistor.purge();
}

const validateFields = (fieldName) => {
  let isValidFields = false;
  if ((fieldName && fieldName !== "")) {
    isValidFields = true;
  }

  return isValidFields;
}

const isVenueUser = (loggedInResponse) => {
  let isVenueUserAccess = false;
  if (loggedInResponse && (loggedInResponse?.userRoles?.includes("VenueAdmin") || loggedInResponse?.userRoles?.includes("VenueUser"))) {
    isVenueUserAccess = true;
  }

  return isVenueUserAccess;
}

const splitArrayIntoChunksOfLen = (arr, len) => {
  var chunks = [], i = 0, n = arr.length;
  while (i < n) {
    chunks.push(arr.slice(i, i += len));
  }

  return chunks;
}

const capitalize = (s) => {
  return s;
  // return s[0].toUpperCase() + s.slice(1);
}

const getFirebaseToken = async () => {
  const token = await messaging().getToken();
  return token;
};

const incrementMonthByCount = (count) => {
  let d = new Date();
  d.setMonth(d.getMonth() + count);

  return d;
};

const pushNotification = (data) => {
  console.log(data, "=====pushNotification=====")
};

const setTimoutMsHelper = (ms) => new Promise((res) => setTimeout(res, ms));

const generatePassword = (len) => {
  var charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  var retVal = "";
  for (var i = 0, n = charset.length; i < len; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }

  return retVal;
}

const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const generateTimes = () => {

  let times = [
    {
      label: "06:00 PM",
      value: "18:00"
    },
    {
      label: "07:00 PM",
      value: "19:00"
    },
    {
      label: "08:00 PM",
      value: "20:00"
    },
    {
      label: "09:00 PM",
      value: "21:00"
    },
    {
      label: "10:00 PM",
      value: "22:00"
    },
    {
      label: "11:00 PM",
      value: "23:00"
    },
    {
      label: "12:00 AM",
      value: "24:00"
    },
    {
      label: "01:00 AM",
      value: "01:00"
    },
    {
      label: "02:00 AM",
      value: "02:00"
    },
    {
      label: "03:00 AM",
      value: "03:00"
    },
    {
      label: "04:00 AM",
      value: "04:00"
    },
    {
      label: "05:00 AM",
      value: "05:00"
    },
    {
      label: "06:00 AM",
      value: "06:00"
    },
    {
      label: "07:00 AM",
      value: "07:00"
    },
    {
      label: "08:00 AM",
      value: "08:00"
    },
    {
      label: "09:00 AM",
      value: "09:00"
    },
    {
      label: "10:00 AM",
      value: "10:00"
    },
    {
      label: "11:00 AM",
      value: "11:00"
    },
    {
      label: "00:00 PM",
      value: "12:00"
    },
    {
      label: "01:00 PM",
      value: "13:00"
    },
    {
      label: "02:00 PM",
      value: "14:00"
    },
    {
      label: "03:00 PM",
      value: "15:00"
    },
    {
      label: "04:00 PM",
      value: "16:00"
    },
    {
      label: "05:00PM",
      value: "17:00"
    },
  ];

  return times;
}


const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

const convertTimeToDate = (time, desiredDate) => {
  const datetime = new Date(desiredDate);
  const [hours, minutes, seconds] = time?.split(':').map(Number);

  datetime?.setHours(hours);
  datetime?.setMinutes(minutes);
  datetime?.setSeconds(seconds);
  const formattedDateTime = datetime?.toISOString().split('T')[0] + 'T' + datetime?.toTimeString().slice(0, 8);
  return formattedDateTime;
};

export {
  isTablet,
  formatPhoneNumber,
  purgePersistStorage,
  validateFields,
  isVenueUser,
  splitArrayIntoChunksOfLen,
  capitalize,
  getFirebaseToken,
  incrementMonthByCount,
  pushNotification,
  setTimoutMsHelper,
  generatePassword,
  isJsonString,
  generateTimes,
  toBase64,
  UpdateNotifiedRouteName,
  convertTimeToDate
}