import React, { useEffect, useState } from "react";
import { StyleSheet, ScrollView } from 'react-native';
import { Flex, Input, Icon, FormControl, Box, Center, Fade, Button, useToast, Heading, Divider } from "native-base";
import { Ionicons } from "@expo/vector-icons";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Redux :: Actions
import * as FloorActions from '../../../shared/redux/actions/floor';
//Redux :: Components
import { SpinnerLoading } from '../../../shared/templates/SpinnerLoading';
import { ImageUpload } from '../../../shared/templates';
import Linking from "../../../shared/templates/customLinking";

const AddFloor = (props) => {

  const {
    PostAddFloor,
    loader, 
    type,
    route,
    FloorListResponse,
    GetFloorListByVenueId 
  } = props;
  const [isReady] = useState(true);
  const [floorName, setFloorName] = useState('');
  const [floorImagePath, setFloorImagePath] = useState('');
  const [VenueId, setVenueId] = useState(0);
  const [floorId, setFloorId] = useState(0);
  const [errors, setErrors] = React.useState({});
  const toast = useToast();

  useEffect(() => {
    if (route?.params) {
      const { venueID, floorid } = route.params;  
      setVenueId(venueID || 0);  
      if(floorid != undefined){
        setFloorId(floorid)
        const selectedFloor = FloorListResponse?.filter((el=>el.id === parseInt(floorid)))
        setFloorName(selectedFloor[0]?.floorName);
        setFloorImagePath(selectedFloor[0]?.imagePath);
      } 
    }
  }, [route?.params,FloorListResponse]);

  useEffect(()=>{
    const { venueID } = route.params; 
    GetFloorListByVenueId(venueID);
  },[])

  const validate = () => {
    if (floorName === "") {
      setErrors({ ...errors,
        floorName: 'FloorName is Required'
      });
      return false;
    }

    return true;
  };

  const redirectURL = (redirectBaseURL, isTargetBlank) => {
		if (isTargetBlank) {
			Linking.openURL(redirectBaseURL, '_blank')
		} else {
			Linking.openURL(redirectBaseURL)
		}
	}
 
  const HandleSubmit = async () => {
    if(validate()){
     setErrors({});   
     const { loginResponse } = props;

    toast.closeAll();

    if (loginResponse && loginResponse !== "") {
      let param = {
        floorId:floorId,
        floorName:floorName,
        venueId:parseInt(VenueId),
        imagePath:floorImagePath
      };

      const PostAddFloor_Response = await PostAddFloor(param).then((response) => response);
      if (PostAddFloor_Response?.data?.status === "Success") {
        toast.show({
          render: () => <Box bg="emerald.500" px="2" py="1" rounded="sm" mb={5}>
            {floorId && floorId > 0 ? "Floor Updated Successfully" : "Floor Added Successfully!"}
          </Box>
        });

        redirectURL(`/VenueWebsiteOperations/${VenueId}`, false)
      } else {
        toast.show({
          render: () => <Box bg="error.600" px="2" py="1" rounded="sm" mb={5}>Failed! Please try again later</Box>
        });
      }
    } else {
      toast.show({
        render: () => <Box bg="error.600" px="2" py="1" rounded="sm" mb={5}>Please fill all required fields</Box>
      });
    }
   }
  }

  const CancelSubmit = () => redirectURL(`/VenueWebsiteOperations/${VenueId}`, false);

  return <ScrollView contentContainerStyle={{ flexGrow: 1, backgroundColor: 'white' }}>
    {
      isReady ?
        <Fade in={isReady}>
          <Flex flexDirection="row" alignItems="center" justifyContent="space-between" my="3" w={"100%"} mt={6}>
                <Box px="8" pt="4">
                  <Heading size='lg'>Floor Information</Heading>
                </Box>
          </Flex>
          <Box px="8">
            <Divider my="3" bg="muted.400" />
          </Box>
          <Flex flexDirection="row" alignItems="flex-start">
            <Box w="75%">
              <Flex flexDirection="row" alignItems="center" px={!type ? "8" : "0"} w="100%" mt={6}>
                <Box style={styles.inputFieldWrapper}>
                  <FormControl
                    isInvalid={'floorName' in errors}
                    isRequired
                  >
                    <FormControl.Label>Floor Name</FormControl.Label>
                    <Input
                      variant="filled"
                      w="500"
                      placeholder="Main Floor"
                      value={floorName || ""}
                      onChange={(event) => setFloorName(event.target.value)}
                      selectionColor="white"
                    />
                     {'floorName' in errors && <FormControl.ErrorMessage>{errors?.floorName}</FormControl.ErrorMessage>}
                  </FormControl>
                </Box>
              </Flex>

              <Flex flexDirection="row" alignItems="flex-start" px={!type ? "8" : "0"} w="100%" mb={10} mt={6}>
            <Button
              mt="2"
              mr="2"
              bgColor="#fad295"
              color="#000000"
              w="100"
              leftIcon={<Icon as={Ionicons} name="ios-save" size="xs" />}
              _text={{
                color: "#000000"
              }}
              disabled={loader || false}
              onPress={HandleSubmit}
            >
              Save
            </Button>
            <Button
              mt="2"
              bgColor="#fc1a17"
              color="primary.50"
              w="100"
              leftIcon={<Icon as={Ionicons} name="ios-close" size="xs" />}
              onPress={CancelSubmit}
            >
              Cancel
            </Button>
          </Flex>
            </Box>
            <Box w="20%">
              <Center mt="30">
                <ImageUpload
                  imageuri={floorImagePath}
                  isEdit={true}
                  id={VenueId}
                  component='Table'
                  onChange={(path) => {
                    setFloorImagePath(path)
                  }}

                />
              </Center>
            </Box>
          </Flex>    
        </Fade>
        :
        <Flex flexDirection="row" alignItems="center" my="4" px={!type ? "8" : "0"} w="90%" mt={6} mh="100%">
          <SpinnerLoading />
        </Flex>
    }
  </ScrollView>;
}

AddFloor.propTypes = {
    loginResponse: PropTypes.any,
    PostTable: PropTypes.func,
    route: PropTypes.any,
    FloorListResponse: PropTypes.any,
    GetFloorListByVenueId: PropTypes.func,
}

const styles = StyleSheet.create({
  inputFieldWrapper: {
    width: '20%',
    marginRight: 30
  },
});

const mapStateToProps = (state) => {
  const { loginResponse } = state.auth;
  const { FloorListResponse} = state.venue;
  return {
    loginResponse: loginResponse || "",
    FloorListResponse
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    GetFloorListByVenueId: (venueId) => dispatch(FloorActions.GetFloorListByVenueId(venueId)).then((response) => response),
    PostAddFloor: (postreq) => dispatch(FloorActions.PostAddFloor(postreq)).then((response) => response),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddFloor);