/* eslint-disable no-unsafe-optional-chaining */
import axios from 'axios';

// Init :: Config
import { API_URL } from '../../config';

// API Endpoints
import * as API_Interface from '../../services/apiURLs';

// Other Redux Actions
import { setAppLoading } from './common';

// Redux :: Action Types
import {
  GET_FLOOR_LIST_BY_VENUE_ID_IDLE, 
  GET_FLOOR_LIST_BY_VENUE_ID_PENDING,
  GET_FLOOR_LIST_BY_VENUE_ID_FAILED,
  GET_FLOOR_LIST_BY_VENUE_ID_SUCCEEDED,
  SELECTED_FLOOR
} from '../constants';

const PostAddFloor = (data) => async (dispatch) => {
  dispatch(setAppLoading(true));
  try {
    const response = await axios({
      method: "POST",
      baseURL: API_URL,
      url: API_Interface.POST_ADD_FLOOR,
      data: data,
    }).then((response) => response)
      .catch((error) => error.response)
    dispatch(setAppLoading(false));
    return response;
  } catch (exception) {
    dispatch(setAppLoading(false));
    return exception;
  }
}

const GetFloorListByVenueId = (id) => async (dispatch) => {
  dispatch({ type: GET_FLOOR_LIST_BY_VENUE_ID_PENDING, payload: true });
  try {
    const response = await axios({
      method: 'GET',
      baseURL: API_URL,
      url: API_Interface.GET_FLOOR_LIST + id,
    }).then((response) => response)
      .catch((error) => error.response);

    if (response.status !== 200) {
      dispatch({ type: GET_FLOOR_LIST_BY_VENUE_ID_IDLE, payload: response });
    } else if (response.status == 417) {
      await dispatch({ type: GET_FLOOR_LIST_BY_VENUE_ID_FAILED, payload: response.data });
    } else {
      await dispatch({ type: GET_FLOOR_LIST_BY_VENUE_ID_SUCCEEDED, payload: response.data });
    }
    return response;
  } catch (exception) {
    dispatch(setAppLoading(false));
    dispatch({ type: GET_FLOOR_LIST_BY_VENUE_ID_FAILED, payload: exception });
  }
}

const DeleteFloorById = (Id) => async (dispatch) => {
  dispatch(setAppLoading(true));
  try {
    const response = await axios({
      method: 'POST',
      baseURL: API_URL,
      url: API_Interface.DELETE_FLOOR + "" + Id,
    }).then((response) => response)
      .catch((error) => error.response);
    dispatch(setAppLoading(false));
    return response;
  } catch (exception) {
    dispatch(setAppLoading(false));
  }
}

const updateSelectedFloor = (floorDetail) => async (dispatch) =>{
  dispatch({ type: SELECTED_FLOOR, payload: floorDetail });
}

export {
  PostAddFloor,
  GetFloorListByVenueId,
  updateSelectedFloor,
  DeleteFloorById
}

