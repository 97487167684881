const RecyclerViewTypes = {
  FULL: 0,
  HALF_LEFT: 1,
  HALF_RIGHT: 2
};

const NotificationTypes = {
  RESERVATION_REQUEST: 'Reservation Request',
  CANCELLATION_REQUEST: 'Cancelation Request',
  REQUEST_MANAGER: 'Requested Manager',
  CHECK_IN: 'Check In',
  NEW_REQUEST: 'New Request',
}

const datalistByGuest = [
  {
    reservationId: 1,
    guestName: "Brett Capuano",
    tableName: "Mazzanine Table 1",
    minimumSpend: '2000',
    depositPaid: '2000',
    reservationDate: '2024-01-19T10:30:00Z',
    reservationTime: '02:49:23',
    activityLogs: [{

      "orderId": 1773,
      "time": "02:48:05",
      orderDetail: [{ productName: "Grey Goose", totalQty: 3, totalAmount: 300 }, { productName: "Don Julio", totalQty: 1, totalAmount: 250 }],
      "venueId": 71,
      "categoryId": 0,
      "status": "completed",
      "activityType": "New Order",
      "endTime": "02:49:23"
    },

    {

      "orderId": 1773,
      "time": "02:48:05",
      orderDetail: [{ productName: "Grey Goose", totalQty: 3, totalAmount: 300 }, { productName: "Ace of Spade", totalQty: 1, totalAmount: 250 }],
      "venueId": 71,
      "categoryId": 0,
      "status": "completed",
      "activityType": "New Order",
      "endTime": "02:49:23"
    },

    {

      "orderId": 0,
      "time": "02:48:05",
      orderDetail: [],
      "venueId": 71,
      "categoryId": 0,
      "status": "completed",
      "activityType": "Request Manager",
      "endTime": "02:49:23"
    }

    ],
    orderSummary: [{ productName: "Grey Goose", totalQty: 3, totalAmount: 300 }, { productName: "Ace of Spade", totalQty: 1, totalAmount: 250 }]
  },
  {
    reservationId: 2,
    guestName: "Benjaminn sherrick",
    tableName: "Floor Table 1",
    minimumSpend: '1500',
    depositPaid: '2000',
    reservationDate: '2024-01-19T08:15:00Z',
    reservationTime: '02:49:23',
    activityLogs: [{

      "orderId": 1773,
      "time": "02:48:05",
      orderDetail: [{ productName: "Grey Goose", totalQty: 3, totalAmount: 300 }, { productName: "Ace of Spade", totalQty: 1, totalAmount: 250 }],
      "venueId": 71,
      "categoryId": 0,
      "status": "completed",
      "activityType": "New Order",
      "endTime": "02:49:23"
    },

    {

      "orderId": 1773,
      "time": "02:48:05",
      orderDetail: [{ productName: "Grey Goose", totalQty: 3, totalAmount: 300 }, { productName: "Ace of Spade", totalQty: 1, totalAmount: 250 }],
      "venueId": 71,
      "categoryId": 0,
      "status": "pending",
      "activityType": "New Order",
      "endTime": "02:49:23"
    },

    {

      "orderId": 0,
      "time": "02:48:05",
      orderDetail: [],
      "venueId": 71,
      "categoryId": 0,
      "status": "canceled",
      "activityType": "Request Manager",
      "endTime": "02:49:23"
    },
    {

      "orderId": 0,
      "time": "02:48:05",
      orderDetail: [],
      "venueId": 71,
      "categoryId": 0,
      "status": "cancelled",
      "activityType": "Request Manager",
      "endTime": "02:49:23"
    }

    ],
    orderSummary: [{ productName: "Grey Goose", totalQty: 3, totalAmount: 300 }, { productName: "Ace of Spade", totalQty: 1, totalAmount: 250 }]
  }
]

export {
  RecyclerViewTypes,
  NotificationTypes,
  datalistByGuest
}