/* eslint-disable react-native/no-color-literals */
import React, { useState, useRef, useEffect } from 'react';
import { View, TouchableWithoutFeedback, StyleSheet, Animated, Easing } from 'react-native';
import { Text, Box, Button, Icon, Flex } from 'native-base';
import PropTypes from 'prop-types';
import { FontAwesome } from '@expo/vector-icons';

// Utils
import { primaryColors } from '../utils/colors';
import { capitalize } from '../utils/common';

const AccordionListItem = ({ title, children, ipOpen, addProductHandler, deleteCategoryHandler, editCategoryHandler, type }) => {

	const [open, setOpen] = useState(ipOpen || false);
	const animatedController = useRef(new Animated.Value(0)).current;
	const [bodySectionHeight, setBodySectionHeight] = useState();

	const bodyHeight = animatedController.interpolate({
		inputRange: [0, 1],
		outputRange: [0, bodySectionHeight],
	});

	useEffect(() => {
		if (open) {
			Animated.timing(animatedController, {
				duration: 300,
				toValue: 1,
				easing: Easing.bezier(0.4, 0.0, 0.2, 1),
			}).start();
		}
		else {

			Animated.timing(animatedController, {
				duration: 300,
				toValue: 0,
				easing: Easing.bezier(0.4, 0.0, 0.2, 1),
			}).start();
		}
	}, [open]);

	const arrowAngle = animatedController.interpolate({
		inputRange: [0, 1],
		outputRange: ['0rad', `${Math.PI}rad`],
	});

	const toggleListItem = () => setOpen(!open);

	return <>
		<TouchableWithoutFeedback onPress={() => toggleListItem()}>
			<View style={[styles.titleContainer, open && { backgroundColor: 'rgb(59, 130, 246)' }]}>
				<Flex flexDirection="row" alignItems="center">
					<Box pr="2">
						<Animated.View style={{ transform: [{ rotateZ: arrowAngle }] }}>
							<Icon as={FontAwesome} name="chevron-circle-down" size="6" fontWeight="bold" color={open ? "white" : "black"} />
						</Animated.View>
					</Box>
					<Text fontSize="lg" style={open && { color: 'white' }}>{capitalize(title || '')}</Text>
				</Flex>
				<Flex flexDirection="row" alignItems="flex-end">
					<Button
						background={primaryColors.luigiGreen}
						w="125"
						pl="3"
						pr="3"
						pt="1"
						pb="1"
						ml="2"
						mr="2"
						variant="outline"
						borderRadius="full"
						borderWidth="0"
						leftIcon={<Icon as={FontAwesome} name="plus-circle" size="xs" fontWeight="bold" color="white" />}
						_text={{
							fontSize: 'xs',
							fontWeight: "bold",
							color: 'white'
						}}
						onPress={() => { addProductHandler() }}
					>
						{type === "floor" ? "Add Table" : "Add Product"}
					</Button>
					<Button
						background={primaryColors.primaryYellow}
						w="125"
						pl="3"
						pr="3"
						pt="1"
						pb="1"
						ml="2"
						mr="2"
						variant="outline"
						borderRadius="full"
						borderWidth="0"
						leftIcon={<Icon as={FontAwesome} name="edit" size="xs" fontWeight="bold" color="black" />}
						_text={{
							fontSize: 'xs',
							fontWeight: "bold",
							color: 'black'
						}}
						onPress={() => { editCategoryHandler() }}
					>
						{type === "floor" ? "Edit Floor" :"Edit Category"}
					</Button>
					<Button
						background={primaryColors.primaryRed}
						w="125"
						pl="3"
						pr="3"
						pt="1"
						pb="1"
						ml="2"
						mr="2"
						variant="outline"
						borderRadius="full"
						borderWidth="0"
						leftIcon={<Icon as={FontAwesome} name="trash" size="xs" fontWeight="bold" color="white" />}
						_text={{
							fontSize: 'xs',
							fontWeight: "bold",
							color: 'white'
						}}
						onPress={() => { deleteCategoryHandler() }}
					>
						{type === "floor" ? "Delete Floor" :"Delete Category"}
					</Button>
				</Flex>
			</View>
		</TouchableWithoutFeedback>
		<Animated.View style={[styles.bodyBackground, { height: bodyHeight }]}>
			<View
				style={[styles.bodyContainer]}
				onLayout={event => setBodySectionHeight(event.nativeEvent.layout.height)}
			>
				{children}
			</View>
		</Animated.View>
	</>;
};

AccordionListItem.propTypes = {
	title: PropTypes.any,
	children: PropTypes.any,
	ipOpen: PropTypes.any,
}

const styles = StyleSheet.create({
	bodyBackground: {
		backgroundColor: '#EFEFEF',
		overflow: 'hidden',
		marginBottom: 2
	},
	titleContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingTop: 10,
		paddingBottom: 10,
		borderRadius: 15,
		paddingLeft: '1.5rem',
		borderBottomWidth: 1,
		backgroundColor: '#dcdcdc',
		marginBottom: 8,
		borderColor: '#EFEFEF',
	},
	bodyContainer: {
		paddingLeft: '1.5rem',
		position: 'absolute',
		bottom: 0,
	},
});

export default AccordionListItem;

