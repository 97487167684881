import React, { useState, useEffect } from 'react';
import { extendTheme, NativeBaseProvider } from 'native-base';
import { NavigationContainer } from '@react-navigation/native';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import {
  useFonts,
  SourceSansPro_200ExtraLight,
  SourceSansPro_200ExtraLight_Italic,
  SourceSansPro_300Light,
  SourceSansPro_300Light_Italic,
  SourceSansPro_400Regular,
  SourceSansPro_400Regular_Italic,
  SourceSansPro_600SemiBold,
  SourceSansPro_600SemiBold_Italic,
  SourceSansPro_700Bold,
  SourceSansPro_700Bold_Italic,
  SourceSansPro_900Black,
  SourceSansPro_900Black_Italic,
} from '@expo-google-fonts/source-sans-pro';

/* ----- COMPONENTS ----- */
import RootInterface from './main';

/* ----- REDUX :: STORE ----- */
import { store, persistor } from '../shared/redux/store/configureStore';

const WebInterface = () => {
  // const [fontsLoaded] = useFonts({
  //   SourceSansPro_200ExtraLight,
  //   SourceSansPro_200ExtraLight_Italic,
  //   SourceSansPro_300Light,
  //   SourceSansPro_300Light_Italic,
  //   SourceSansPro_400Regular,
  //   SourceSansPro_400Regular_Italic,
  //   SourceSansPro_600SemiBold,
  //   SourceSansPro_600SemiBold_Italic,
  //   SourceSansPro_700Bold,
  //   SourceSansPro_700Bold_Italic,
  //   SourceSansPro_900Black,
  //   SourceSansPro_900Black_Italic,
  // });

  const [theme, setTheme] = useState(extendTheme({
    components: {
      Button: {
        // Can simply pass default props to change default behaviour of components.
        baseStyle: {
          rounded: 'md',
        },
        defaultProps: {
          colorScheme: 'red',
        },
      }
    },
  }));

  // useEffect(() => {
  //   console.log(`Font Loaded : ${Date.now()}`, fontsLoaded);
  //   setTheme(extendTheme({
  //     components: {
  //       Button: {
  //         // Can simply pass default props to change default behaviour of components.
  //         baseStyle: {
  //           rounded: 'md',
  //         },
  //         defaultProps: {
  //           colorScheme: 'red',
  //         },
  //       }
  //     },
  //     fontConfig: {
  //       SourceSansPro: {
  //         100: {
  //           normal: 'SourceSansPro_300Light',
  //           italic: 'SourceSansPro_300Light_Italic',
  //         },
  //         200: {
  //           normal: 'SourceSansPro_300Light',
  //           italic: 'SourceSansPro_300Light_Italic',
  //         },
  //         300: {
  //           normal: 'SourceSansPro_300Light',
  //           italic: 'SourceSansPro_300Light_Italic',
  //         },
  //         400: {
  //           normal: 'SourceSansPro_400Regular',
  //           italic: 'SourceSansPro_400Regular_Italic',
  //         },
  //         500: {
  //           normal: 'SourceSansPro_600SemiBold',
  //         },
  //         600: {
  //           normal: 'SourceSansPro_600SemiBold',
  //           italic: 'SourceSansPro_600SemiBold_Italic',
  //         },
  //         700: {
  //           normal: 'SourceSansPro_700Bold',
  //           italic: 'SourceSansPro_700Bold_Italic'
  //         },
  //         800: {
  //           normal: 'SourceSansPro_700Bold',
  //           italic: 'SourceSansPro_700Bold_Italic',
  //         },
  //         900: {
  //           normal: 'SourceSansPro_900Black',
  //           italic: 'SourceSansPro_900Black_Italic',
  //         },
  //       },
  //     },

  //     // Make sure values below matches any of the keys in `fontConfig`
  //     fonts: {
  //       heading: 'SourceSansPro',
  //       body: 'SourceSansPro',
  //       mono: 'SourceSansPro',
  //     },
  //   }))
  // }, [fontsLoaded]);

  const config = {
    screens: {
      Login: '/Login',
      ForgotPassword: '/ForgotPassword',
      Venue: '/Venue',
      VenueDetails: '/Venue/:id',
      AddOrEditVenue: '/AddOrEditVenue/:id',
      ViewVenue: '/ViewVenue',
      Users: '/Users',
      AddOrEditUsers: '/AddOrEditUsers',
      Menu: '/Menu',
      AddOrEditTables: '/AddOrEditTables/:id/:tableid/:floorid',
      AddFloor: '/AddFloor/:venueID/:floorid?',
      VenueWebsiteOperations: '/VenueWebsiteOperations/:id',
      Settings: '/Settings',
      Widget: '/Widget',
      AddOrEditProduct: '/AddOrEditProduct/:id/:categoryid/:productid',
      ReservationReport: '/ReservationReport/:venueID'
    }
  };

  const linking = {
    prefixes: ['https://clubq.com', 'clubq://'],
    config,
  };

  return <Provider store={store}>
    <NativeBaseProvider theme={theme}>
      <NavigationContainer linking={linking}>
        <PersistGate loading={null} persistor={persistor}>
          <RootInterface />
        </PersistGate>
      </NavigationContainer>
    </NativeBaseProvider>
  </Provider>;
}

export default WebInterface;