/* eslint-disable no-unused-vars */
// @flow
const APP_NAME = "REVLR Venue"   // REVLR Venue;
// const DEV_LOGIN = false;
const APP_TYPE = "VenueAdmin"; // User, Admin, VenueAdmin, VenueUser
const END_USER_BUNDLE_IDENTIFIER = "com.capacitancellc.clubq.enduser";
const VENUE_USER_BUNDLE_IDENTIFIER = "com.capacitancellc.clubq.venueuser";
const ENV = "PROD"; // DEV or PROD or LOCAL
const INTERNAL_HOST = "http://103.104.124.146:9000/api";
const PROD_HOST = "http://103.104.124.146/api";
const LOC_HOST = "https://localhost:44331/api";

const BOOKING_PORTAL = "https://bookings.revlr.app/";
const ADMIN_SITE = "https://admin.revlr.app/";



// const AZURE_HOST = "https://api.clubq.app/api";
const AZURE_HOST = "https://api.revlr.app/api";
const DEV_AZURE_HOST = "https://devclubqapi.azurewebsites.net/api";
const PROD_AZURE_HOST = "https://revlrapi.azurewebsites.net/api";
// const DEV_AZURE_HOST = "https://devclubqbooking.azurewebsites.net/api";

const PROXY_HOST = "https://cors-anywhere.herokuapp.com/";
const SWAGGER_URL = "https://clubq-api.azurewebsites.net/swagger/index.html";
//const SWAGGER_URL = "https://devclubqapi.azurewebsites.net/swagger/index.html";  //** for testing and development */
//const DEV_HOST="https://devclubqapi.azurewebsites.net/api";

const DEV_HOST = ENV === "DEV" ? DEV_AZURE_HOST : ENV === "PROD" ? AZURE_HOST : AZURE_HOST;
const API_URL = DEV_HOST;
const STATUS_BAR_TYPE = `default`;
const HEADER_MENU_OPTIONS = [
  {
    id: "venues",
    name: "Venues",
    route: "Venue",
    subMenuOptions: []
  },
  {
    id: "users",
    name: "Users",
    route: "Users",
    subMenuOptions: []
  },
  {
    id: "settings",
    name: "Settings",
    route: "Settings",
    subMenuOptions: []
  },
  {
    id: "logout",
    name: "Logout",
    route: "STATIC_LOGOUT",
    subMenuOptions: []
  },
];

// Firebase Cloud Messaging
const FIREBASE_SERVER_KEY = "AAAAC_ONQzM:APA91bG7UINXtt8LK9K8HsWVGrwqugEoKDjJznrZjIaX1zZAbGyw9QrtVP2QHHMmk5B-_S8YTM84XPvlVYw7go8fZzjoXaMeHWnOwyoSPrDnDzYLeEPpxtalSgsfYwc59__Crlq8mWy5";

export {
  APP_NAME,
  // DEV_LOGIN,
  APP_TYPE,
  ENV,
  DEV_HOST,
  AZURE_HOST,
  API_URL,
  STATUS_BAR_TYPE,
  HEADER_MENU_OPTIONS,
  PROXY_HOST,
  SWAGGER_URL,
  INTERNAL_HOST,
  PROD_HOST,
  LOC_HOST,
  FIREBASE_SERVER_KEY
};