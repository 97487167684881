/* eslint-disable no-unused-vars */
import { Platform } from 'react-native';
import { setAuthTokens } from 'react-native-axios-jwt';
import axios from 'axios';

// Init :: Config
import { API_URL } from '../../config';

// Redux :: Action Types
import {
  LOGIN_PENDING,
  LOGIN_IDLE,
  LOGIN_FAILED,
  LOGIN_SUCCEEDED,
  SIGNUP_PENDING,
  SIGNUP_IDLE,
  SIGNUP_FAILED,
  SIGNUP_SUCCEEDED,
  VENUE_PENDING,
  VENUE_IDLE,
  VENUE_FAILED,
  VENUE_SUCCEEDED,
  RESET_LOGIN_GROUP
} from '../constants';

// Redux :: API Instance
import { axiosInstance } from '../../services/api';

// API Endpoints
import * as API_Interface from '../../services/apiURLs';

// Other Redux Actions
import { setAppLoading } from '../actions/common';

const resetLoginState = () => async (dispatch) => dispatch({ type: RESET_LOGIN_GROUP });

const login = (credentials) => async (dispatch) => {
  dispatch(setAppLoading(true));
  dispatch({ type: LOGIN_PENDING, payload: true });
  try {
    const response = await axios({
      method: "POST",
      baseURL: API_URL,
      url: API_Interface.LOGIN_URL,
      data: credentials,
    }).then((response) => response).catch((error) => {
      if (error.response) {
        return error.response.data;
      } else if (error.request) {
        return error.request;
      } else if (error.message) {
        return error.message;
      } else {
        return error;
      }
    });

    if (response.status !== 200) {
      dispatch(setAppLoading(false));
      dispatch({ type: LOGIN_IDLE, payload: response });
      dispatch({ type: LOGIN_FAILED, payload: response?.message });
    } else {
      // Save tokens to storage
      await setAuthTokens({ accessToken: response.data?.token, refreshToken: response.data?.refreshToken.token });
      await dispatch({ type: LOGIN_SUCCEEDED, payload: response?.data });
    }
    dispatch(setAppLoading(false));

    return response;
  } catch (error) {
    dispatch(setAppLoading(false));
    dispatch({ type: LOGIN_FAILED, payload: error?.response });

    return error;
  }
}

const SignUp = (data) => async (dispatch) => {
  dispatch(setAppLoading(true));
  dispatch({ type: SIGNUP_PENDING, payload: true });

  try {
    const response = await axios({
      method: "POST",
      baseURL: API_URL,
      url: API_Interface.REGISTER_URL,
      data: { ...data, triggerFromPlatform: Platform.OS },
    }).then((response) => response);
    if (response.status !== 200) {
      dispatch(setAppLoading(false));
      dispatch({ type: SIGNUP_IDLE, payload: response });
    } else {
      await dispatch({ type: SIGNUP_SUCCEEDED, payload: response?.data });
    }
    dispatch(setAppLoading(false));

    return response;
  } catch (exception) {
    dispatch(setAppLoading(false));
    dispatch({ type: SIGNUP_FAILED, payload: exception });
    if (exception.response) {
      return({
        status:exception.response.status,
        data:exception.response.data
      })
    }
  }
}

const UpdateNotificationUUIDByUserID = (UUID, UserId, Platform) => async () => {
  try {
    const response = await axios({
      method: "POST",
      baseURL: API_URL,
      url: API_Interface.UPDATE_NOTIFICATION_UUID_BY_USER_ID + `?UserId=` + UserId + `&UUID=` + UUID + `&Platform=` + Platform
    }).then((response) => {
      response});
    return response;
  } catch (error) {
    if (error.response) {
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    }
  }
}

const GetNotificationUUIdByUserId = (userId) => async (dispatch) => {
  try {
    const response = await axios({
      method: "GET",
      baseURL: API_URL,
      url: API_Interface.GET_NOTIFICATION_UUID_BY_USER_ID + '/' + userId,
    }).then((response) => { return response })
      .catch((error) => error.response);
    return response;
  } catch (error) {
    if (error.response) {
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    }
  }
}

export {
  resetLoginState,
  login,
  SignUp,
  UpdateNotificationUUIDByUserID,
  GetNotificationUUIdByUserId
}