/* eslint-disable no-unsafe-optional-chaining */
import axios from 'axios';

// Init :: Config
import { API_URL } from '../../config';

// API Endpoints
import * as API_Interface from '../../services/apiURLs';

// Redux :: Action Types
import {
  VENUE_IDLE,
  VENUE_PENDING,
  VENUE_FAILED,
  VENUE_SUCCEEDED,
  VENUE_VIEW_IDLE,
  VENUE_VIEW_PENDING,
  VENUE_VIEW_FAILED,
  VENUE_VIEW_SUCCEEDED,
  GET_ALL_VENUE_IDLE,
  GET_ALL_VENUE_PENDING,
  GET_ALL_VENUE_FAILED,
  GET_ALL_VENUE_SUCCEEDED,
  RESERVATION_CHECKIN_IDLE,
  RESERVATION_CHECKIN_PENDING,
  RESERVATION_CHECKIN_FAILED,
  RESERVATION_CHECKIN_SUCCEEDED,
  RESERVATION_CANCEL_IDLE,
  RESERVATION_CANCEL_PENDING,
  RESERVATION_CANCEL_FAILED,
  RESERVATION_CANCEL_SUCCEEDED,

  // Venue Users
  GET_VENUE_USERS_BY_VENUE_ID_IDLE,
  GET_VENUE_USERS_BY_VENUE_ID_PENDING,
  GET_VENUE_USERS_BY_VENUE_ID_FAILED,
  GET_VENUE_USERS_BY_VENUE_ID_SUCCEEDED,

  //Venue user detail
  GET_VENUE_USERS_IDLE,
  GET_VENUE_USERS_PENDING,
  GET_VENUE_USERS_FAILED,
  GET_VENUE_USERS_SUCCEEDED,

  // Venue Block Dates
  GET_VENUE_BLOCK_DATES_ID_IDLE,
  GET_VENUE_BLOCK_DATES_ID_PENDING,
  GET_VENUE_BLOCK_DATES_ID_FAILED,
  GET_VENUE_BLOCK_DATES_ID_SUCCEEDED,

  // Venue Premium Date Range
  GET_VENUE_PREMIUM_DATE_RANGE_ID_IDLE,
  GET_VENUE_PREMIUM_DATE_RANGE_ID_PENDING,
  GET_VENUE_PREMIUM_DATE_RANGE_ID_FAILED,
  GET_VENUE_PREMIUM_DATE_RANGE_ID_SUCCEEDED,

  // Venue Settings By Day
  GET_VENUE_SETTING_BY_DAY_IDLE,
  GET_VENUE_SETTING_BY_DAY_PENDING,
  GET_VENUE_SETTING_BY_DAY_FAILED,
  GET_VENUE_SETTING_BY_DAY_SUCCEEDED,

  // Report
  GET_VENUE_CACHE_REPORT_BY_DATE_IDLE,
  GET_VENUE_CACHE_REPORT_BY_DATE_PENDING,
  GET_VENUE_CACHE_REPORT_BY_DATE_FAILED,
  GET_VENUE_CACHE_REPORT_BY_DATE_SUCCEEDED,

  // Venue Detail
  GET_VENUE_DETAIL_BY_VENUE_ID_IDLE,
  GET_VENUE_DETAIL_BY_VENUE_ID_PENDING,
  GET_VENUE_DETAIL_BY_VENUE_ID_FAILED,
  GET_VENUE_DETAIL_BY_VENUE_ID_SUCCEEDED,

  // Update Floor Settings
  UPDATE_VENUE_FLOOR_SETTING_IDLE,
  UPDATE_VENUE_FLOOR_SETTING_PENDING,
  UPDATE_VENUE_FLOOR_SETTING_FAILED,
  UPDATE_VENUE_FLOOR_SETTING_SUCCEEDED,

  // Venue Reservation Detail
  GET_VENUE_RESERVATION_DETAIL_BY_VENUE_ID_IDLE,
  GET_VENUE_RESERVATION_DETAIL_BY_VENUE_ID_PENDING,
  GET_VENUE_RESERVATION_DETAIL_BY_VENUE_ID_FAILED,
  GET_VENUE_RESERVATION_DETAIL_BY_VENUE_ID_SUCCEEDED,

  //Venue Table Detail
  GET_VENUE_TABLE_BY_TABLE_ID_IDLE,
  GET_VENUE_TABLE_BY_VENUE_ID_PENDING,
  GET_VENUE_TABLE_BY_VENUE_ID_FAILED,
  GET_VENUE_TABLE_BY_VENUE_ID_SUCCEEDED,

  // Venue Product Category
  GET_PRODUCT_CATEGORY_BY_VENUE_ID_IDLE,
  GET_PRODUCT_CATEGORY_BY_VENUE_ID_PENDING,
  GET_PRODUCT_CATEGORY_BY_VENUE_ID_FAILED,
  GET_PRODUCT_CATEGORY_BY_VENUE_ID_SUCCEEDED,

  //Delete venue
  DELETE_VENUE_USER_IDLE,
  DELETE_VENUE_USER_PENDING,
  DELETE_VENUE_USER_FAILED,
  DELETE_VENUE_USER_SUCCEEDED,

  // Accept Reservation 
  RESERVATION_ACCEPT_IDLE,
  RESERVATION_ACCEPT_PENDING,
  RESERVATION_ACCEPT_FAILED,
  RESERVATION_ACCEPT_SUCCEEDED,

  // cache Report
  GET_VENUE_CACHE_REPORT_BY_VENUE_ID_IDLE,
  GET_VENUE_CACHE_REPORT_BY_VENUE_ID_PENDING,
  GET_VENUE_CACHE_REPORT_BY_VENUE_ID_FAILED,
  GET_VENUE_CACHE_REPORT_BY_VENUE_ID_SUCCEEDED,

  // Get Cache Report by date //
  GET_CACHE_REPORT_BY_DATE_IDLE,
  GET_CACHE_REPORT_BY_DATE_PENDING,
  GET_CACHE_REPORT_BY_DATE_FAILED,
  GET_CACHE_REPORT_BY_DATE_SUCCEEDED,

  //Get Reservation  Report//
  GET_RESERVATION_REPORT_BY_VENUE_IDLE,
  GET_RESERVATION_REPORT_BY_VENUE_PENDING,
  GET_RESERVATION_REPORT_BY_VENUE_SUCCEEDED,
  GET_RESERVATION_REPORT_BY_VENUE_FAILED,

  //Get Guest Summary Report//
  GET_GUEST_SUMMARY_BY_USER_IDLE,
  GET_GUEST_SUMMARY_BY_USER_PENDING,
  GET_GUEST_SUMMARY_BY_USER_FAILED,
  GET_GUEST_SUMMARY_BY_USER_SUCCEEDED,

  // Venue Deposite Settings
  GET_VENUE_DEPOSITE_SETTINGS_IDLE,
  GET_VENUE_DEPOSITE_SETTINGS_PENDING,
  GET_VENUE_DEPOSITE_SETTINGS_FAILED,
  GET_VENUE_DEPOSITE_SETTINGS_SUCCEEDED,

  // Send Reservation Report
  SEND_RESERVATION_REPORT_IDLE,
  SEND_RESERVATION_REPORT_PENDING,
  SEND_RESERVATION_REPORT_FAILED,
  SEND_RESERVATION_REPORT_SUCCEEDED,

  // Send Guest Summary Report
  SEND_GUEST_SUMMARY_IDLE,
  SEND_GUEST_SUMMARY_PENDING,
  SEND_GUEST_SUMMARY_FAILED,
  SEND_GUEST_SUMMARY_SUCCEEDED,

  // Send Cache Report
  SEND_CACHE_REPORT_IDLE,
  SEND_CACHE_REPORT_PENDING,
  SEND_CACHE_REPORT_FAILED,
  SEND_CACHE_REPORT_SUCCEEDED,
} from '../constants';

// Other Redux Actions
import { setAppLoading } from '../actions/common';

const ToggleVenueState = (data) => async (dispatch) => {
  dispatch(setAppLoading(true));
  try {
    const response = await axios({
      method: "POST",
      baseURL: API_URL,
      url: `${API_Interface.TOGGLE_VENUE_STATE}/${data.id}?isActive=` + !data.isactive,
      params: data,
    }).then((response) => response)
      .catch((error) => error.response)
    dispatch(setAppLoading(false));
    return response;
  } catch (exception) {
    dispatch(setAppLoading(false));
  }
}

const DeleteVenueUserById = (Id) => async (dispatch) => {
  dispatch(setAppLoading(true));
  dispatch({ type: DELETE_VENUE_USER_PENDING, payload: true });
  dispatch({ type: DELETE_VENUE_USER_IDLE, payload: null });
  dispatch({ type: DELETE_VENUE_USER_FAILED, payload: null });
  dispatch({ type: DELETE_VENUE_USER_SUCCEEDED, payload: null });

  try {
    const response = await axios({
      method: 'POST',
      baseURL: API_URL,
      url: API_Interface.DELETE_VENUE_USER_BY_ID + "" + Id,
    }).then((response) => response)
      .catch((error) => error.response);
    dispatch(setAppLoading(false));
    return response;
  } catch (exception) {
    dispatch(setAppLoading(false));
    dispatch({ type: DELETE_VENUE_USER_FAILED, payload: exception });
    dispatch({ type: DELETE_VENUE_USER_PENDING, payload: false });
  }
}

const FetchReservationByUserID = (userID) => async (dispatch) => {
  dispatch({ type: VENUE_PENDING, payload: true });
  try {
    const response = await axios({
      method: "GET",
      baseURL: API_URL,
      url: `${API_Interface.GET_RESERVATION_BY_USER_ID}` + userID,
    }).then((response) => {
      return response;
    })
      .catch((error) => error.response)
    if (response.status !== 200) {
      dispatch({ type: VENUE_IDLE, payload: response });
    }
    else if (response.status == 417) {
      await dispatch({ type: VENUE_FAILED, payload: response.data });
    } else {
      await dispatch({ type: VENUE_SUCCEEDED, payload: response.data });
    }
    return response;
  } catch (exception) {
    dispatch({ type: VENUE_FAILED, payload: exception });
  }
}

const FetchVenueView = (reservationID) => async (dispatch) => {
  dispatch({ type: VENUE_VIEW_PENDING, payload: true });
  try {
    const response = await axios({
      method: "GET",
      baseURL: API_URL,
      url: `${API_Interface.GET_RESERVATION_DETAIL_BY_ID}` + reservationID,
    }).then((response) => response)
      .catch((error) => error.response)
    if (response.status !== 200) {
      dispatch(setAppLoading(false));
      dispatch({ type: VENUE_VIEW_IDLE, payload: response });
    } if (response.status == 417) {
      await dispatch({ type: VENUE_VIEW_FAILED, payload: response.data });
    } else {
      await dispatch({ type: VENUE_VIEW_SUCCEEDED, payload: response.data });
    }
    return response;
  } catch (exception) {
    dispatch({ type: VENUE_VIEW_FAILED, payload: exception });
  }
}

const CheckInReservation = (data) => async (dispatch) => {

  dispatch(setAppLoading(true));
  dispatch({ type: RESERVATION_CHECKIN_PENDING, payload: true });

  try {
    const response = await axios({
      method: "POST",
      baseURL: API_URL,
      url: `${API_Interface.CHECK_IN_RESERVATION}`,
      params: data,
    }).then((response) => response)
      .catch((error) => error.response)
    if (response.status !== 200) {
      dispatch(setAppLoading(false));
      dispatch({ type: RESERVATION_CHECKIN_IDLE, payload: response });
    } if (response.status == 417) {
      await dispatch({ type: RESERVATION_CHECKIN_FAILED, payload: response.data });
    } else {
      await dispatch({ type: RESERVATION_CHECKIN_SUCCEEDED, payload: response.data });
    }
    dispatch(setAppLoading(false));
    return response;
  } catch (exception) {
    dispatch(setAppLoading(false));
    dispatch({ type: RESERVATION_CHECKIN_FAILED, payload: exception });
  }
}

const UndoCheckInReservation = (data) => async (dispatch) => {
  dispatch(setAppLoading(true));
  try {
    const response = await axios({
      method: "POST",
      baseURL: API_URL,
      url: `${API_Interface.UNDO_CHECK_IN_RESERVATION}`,
      params: data,
    }).then((response) => response)
      .catch((error) => error.response)
    if (response.status !== 200) {
      dispatch(setAppLoading(false));
    }
    dispatch(setAppLoading(false));
    return response;
  } catch (exception) {
    dispatch(setAppLoading(false));
  }
}


const CompleteReservation = (data) => async (dispatch) => {
  dispatch(setAppLoading(true));
  try {
    const response = await axios({
      method: "POST",
      baseURL: API_URL,
      url: `${API_Interface.COMPLETE_RESERVATION}`,
      params: data,
    }).then((response) => response)
      .catch((error) => error.response)
    if (response.status !== 200) {
      dispatch(setAppLoading(false));
    }
    dispatch(setAppLoading(false));
    return response;
  } catch (exception) {
    dispatch(setAppLoading(false));
  }
}

const CancelReservation = (data) => async (dispatch) => {
  dispatch(setAppLoading(true));
  dispatch({ type: RESERVATION_CANCEL_PENDING, payload: true });

  try {
    const response = await axios({
      method: "POST",
      baseURL: API_URL,
      url: `${API_Interface.CANCEL_RESERVATION}`,
      params: data,
    }).then((response) => response)
      .catch((error) => error.response)
    if (response.status !== 200) {
      dispatch(setAppLoading(false));
      dispatch({ type: RESERVATION_CANCEL_IDLE, payload: response });
    } if (response.status == 417) {
      await dispatch({ type: RESERVATION_CANCEL_FAILED, payload: response.data });
    } else {
      await dispatch({ type: RESERVATION_CANCEL_SUCCEEDED, payload: response.data });
    }
    dispatch(setAppLoading(false));
    return response;
  } catch (exception) {
    dispatch(setAppLoading(false));
    dispatch({ type: RESERVATION_CANCEL_FAILED, payload: exception });
  }
}

const RequestCancellationForReservation = (data) => async (dispatch) => {
  dispatch(setAppLoading(true));
  dispatch({ type: RESERVATION_CANCEL_PENDING, payload: true });

  try {
    const response = await axios({
      method: "POST",
      baseURL: API_URL,
      url: `${API_Interface.REQUEST_CANCELLATION}`,
      params: data,
    }).then((response) => response)
      .catch((error) => error.response)
    if (response.status !== 200) {
      dispatch(setAppLoading(false));
      dispatch({ type: RESERVATION_CANCEL_IDLE, payload: response });
    } if (response.status == 417) {
      await dispatch({ type: RESERVATION_CANCEL_FAILED, payload: response.data });
    } else {
      await dispatch({ type: RESERVATION_CANCEL_SUCCEEDED, payload: response.data });
    }
    dispatch(setAppLoading(false));
    return response;
  } catch (exception) {
    dispatch(setAppLoading(false));
    dispatch({ type: RESERVATION_CANCEL_FAILED, payload: exception });
  }
}


const ClearReservation = (data) => async (dispatch) => {
  dispatch(setAppLoading(true));
  dispatch({ type: RESERVATION_CANCEL_PENDING, payload: true });

  try {
    const response = await axios({
      method: "POST",
      baseURL: API_URL,
      url: `${API_Interface.CLEAR_RESERVATION}`,
      params: data,
    }).then((response) => response)
      .catch((error) => error.response)
    if (response.status !== 200) {
      dispatch(setAppLoading(false));
      dispatch({ type: RESERVATION_CANCEL_IDLE, payload: response });
    } if (response.status == 417) {
      await dispatch({ type: RESERVATION_CANCEL_FAILED, payload: response.data });
    } else {
      await dispatch({ type: RESERVATION_CANCEL_SUCCEEDED, payload: response.data });
    }
    dispatch(setAppLoading(false));
    return response;
  } catch (exception) {
    dispatch(setAppLoading(false));
    dispatch({ type: RESERVATION_CANCEL_FAILED, payload: exception });
  }
}

const AcceptReservation = (data) => async (dispatch) => {
  dispatch(setAppLoading(true));
  dispatch({ type: RESERVATION_ACCEPT_PENDING, payload: true });

  try {
    const response = await axios({
      method: "POST",
      baseURL: API_URL,
      url: `${API_Interface.ACCEPT_RESERVATION}` + data.id,
    }).then((response) => response)
      .catch((error) => error.response)
    if (response.status !== 200) {
      dispatch(setAppLoading(false));
      dispatch({ type: RESERVATION_ACCEPT_IDLE, payload: response });
    } if (response.status == 417) {
      await dispatch({ type: RESERVATION_ACCEPT_FAILED, payload: response.data });
    } else {
      await dispatch({ type: RESERVATION_ACCEPT_SUCCEEDED, payload: response.data });
    }
    dispatch(setAppLoading(false));
    return response;
  } catch (exception) {
    dispatch(setAppLoading(false));
    dispatch({ type: RESERVATION_ACCEPT_FAILED, payload: exception });
  }
}

const DenyReservation = (data) => async (dispatch) => {
  dispatch(setAppLoading(true));
  dispatch({ type: RESERVATION_ACCEPT_PENDING, payload: true });

  try {
    const response = await axios({
      method: "POST",
      baseURL: API_URL,
      url: `${API_Interface.DENY_RESERVATION}` + data.id,
    }).then((response) => response)
      .catch((error) => error.response)

    if (response.status !== 200) {
      dispatch(setAppLoading(false));
      dispatch({ type: RESERVATION_ACCEPT_IDLE, payload: response });
    } if (response.status == 417) {
      await dispatch({ type: RESERVATION_ACCEPT_FAILED, payload: response.data });
    } else {
      await dispatch({ type: RESERVATION_ACCEPT_SUCCEEDED, payload: response.data });
    }
    dispatch(setAppLoading(false));
    return response;
  } catch (exception) {
    dispatch(setAppLoading(false));
    dispatch({ type: RESERVATION_ACCEPT_FAILED, payload: exception });
  }
}

const AcceptCancellation = (data) => async (dispatch) => {
  dispatch(setAppLoading(true));
  dispatch({ type: RESERVATION_ACCEPT_PENDING, payload: true });

  try {
    const response = await axios({
      method: "POST",
      baseURL: API_URL,
      url: `${API_Interface.ACCEPT_CANCELATION}` + data.id,
    }).then((response) => response)
      .catch((error) => error.response)

    if (response.status !== 200) {
      dispatch(setAppLoading(false));
      dispatch({ type: RESERVATION_ACCEPT_IDLE, payload: response });
    } if (response.status == 417) {
      await dispatch({ type: RESERVATION_ACCEPT_FAILED, payload: response.data });
    } else {
      await dispatch({ type: RESERVATION_ACCEPT_SUCCEEDED, payload: response.data });
    }
    dispatch(setAppLoading(false));
    return response;
  } catch (exception) {
    dispatch(setAppLoading(false));
    dispatch({ type: RESERVATION_ACCEPT_FAILED, payload: exception });
  }
}

const DenyCancellation = (data) => async (dispatch) => {
  dispatch(setAppLoading(true));
  dispatch({ type: RESERVATION_ACCEPT_PENDING, payload: true });

  try {
    const response = await axios({
      method: "POST",
      baseURL: API_URL,
      url: `${API_Interface.DENY_CANCELATION}` + data.id,
    }).then((response) => response)
      .catch((error) => error.response)

    if (response.status !== 200) {
      dispatch(setAppLoading(false));
      dispatch({ type: RESERVATION_ACCEPT_IDLE, payload: response });
    } if (response.status == 417) {
      await dispatch({ type: RESERVATION_ACCEPT_FAILED, payload: response.data });
    } else {
      await dispatch({ type: RESERVATION_ACCEPT_SUCCEEDED, payload: response.data });
    }
    dispatch(setAppLoading(false));
    return response;
  } catch (exception) {
    dispatch(setAppLoading(false));
    dispatch({ type: RESERVATION_ACCEPT_FAILED, payload: exception });
  }
}

const GetAllVenueDetailsWithPaging = (data, all) => async (dispatch) => {

  dispatch(setAppLoading(true));
  dispatch({ type: GET_ALL_VENUE_PENDING, payload: true });

  try {
    let axiosParam = {};
    axiosParam.baseURL = API_URL;
    if (all) {
      axiosParam.method = "GET";
      axiosParam.url = API_Interface.GET_ALL_VENUES;
    } else {
      axiosParam.method = "POST";
      axiosParam.url = API_Interface.GET_ALL_VENUES_WITH_PAGING;
      axiosParam.data = data;
    }
    const response = await axios(axiosParam).then((response) => response)
      .catch((error) => error.response);
    if (response.status !== 200) {
      dispatch(setAppLoading(false));
      dispatch({ type: GET_ALL_VENUE_IDLE, payload: response });
    } else if (response.status == 417) {
      await dispatch({ type: GET_ALL_VENUE_FAILED, payload: response.data.data });
    } else {
      await dispatch({ type: GET_ALL_VENUE_SUCCEEDED, payload: response.data.data });
    }
    dispatch(setAppLoading(false));
    return response;
  } catch (exception) {
    dispatch(setAppLoading(false));
    dispatch({ type: GET_ALL_VENUE_FAILED, payload: exception });
  }
}

const GetVenueContactDetail = (reservationId) => async (dispatch) => {
  dispatch({ type: GET_VENUE_USERS_PENDING, payload: true });
  try {
    const response = await axios({
      method: 'GET',
      baseURL: API_URL,
      url: API_Interface.GET_VENUE_CONTACT + reservationId,
    }).then((response) => response)
      .catch((error) => error.response);

    if (response.status !== 200) {
      dispatch({ type: GET_VENUE_USERS_IDLE, payload: response });
    } else if (response.status == 417) {
      await dispatch({ type: GET_VENUE_USERS_FAILED, payload: response.data });
    } else {
      await dispatch({ type: GET_VENUE_USERS_SUCCEEDED, payload: response.data });
    }
    return response;
  } catch (exception) {
    dispatch(setAppLoading(false));
    dispatch({ type: GET_VENUE_USERS_FAILED, payload: exception });
  }
}

const GetVenueCacheReportByDate = (date) => async (dispatch) => {
  dispatch({ type: GET_VENUE_CACHE_REPORT_BY_DATE_PENDING, payload: true });
  try {
    const response = await axios({
      method: 'GET',
      baseURL: API_URL,
      url: date ? `${API_Interface.GET_VENUE_CACHE_REPORT_BY_DATE}?date=${date}` : `${API_Interface.GET_VENUE_CACHE_REPORT_BY_DATE}`,
    }).then((response) => response)
      .catch((error) => error.response);
    if (response.status !== 200) {
      dispatch({ type: GET_VENUE_CACHE_REPORT_BY_DATE_IDLE, payload: response });
    } else if (response.status == 417) {
      await dispatch({ type: GET_VENUE_CACHE_REPORT_BY_DATE_FAILED, payload: response.data });
    } else {
      await dispatch({ type: GET_VENUE_CACHE_REPORT_BY_DATE_SUCCEEDED, payload: response.data });
    }
    return response;
  } catch (exception) {
    dispatch(setAppLoading(false));
    dispatch({ type: GET_VENUE_CACHE_REPORT_BY_DATE_FAILED, payload: exception });
  }
}

// ** Get Cache Report List by Date ( preview cache report modal ) ** //

const GetCacheReportDetailsByDate = (venueid, fromDate, toDate) => async (dispatch) => {
  dispatch({ type: GET_CACHE_REPORT_BY_DATE_PENDING, payload: true });
  try {
    const response = await axios({
      method: 'POST',
      baseURL: API_URL,
      url: `${API_Interface.GET_CACHE_REPORT_BY_DATE}?venueId=${venueid}&FromDate=${fromDate}&ToDate=${toDate}`,
    }).then((response) => response)
      .catch((error) => error.response);
    if (response.status !== 200) {
      dispatch({ type: GET_CACHE_REPORT_BY_DATE_IDLE, payload: response });
    } else if (response.status == 417) {
      await dispatch({ type: GET_CACHE_REPORT_BY_DATE_FAILED, payload: response.data });
    } else {
      await dispatch({ type: GET_CACHE_REPORT_BY_DATE_SUCCEEDED, payload: response.data });
    }
    return response;
  } catch (exception) {
    dispatch(setAppLoading(false));
    dispatch({ type: GET_CACHE_REPORT_BY_DATE_FAILED, payload: exception });
  }
}

const GetVenueCacheReportByVenueId = (Id) => async (dispatch) => {
  dispatch({ type: GET_VENUE_CACHE_REPORT_BY_VENUE_ID_PENDING, payload: true });
  try {
    const response = await axios({
      method: 'GET',
      baseURL: API_URL,
      url: API_Interface.GET_VENUE_CACHE_REPORT_BY_VENUE_ID + Id,
    }).then((response) => response)
      .catch((error) => error.response);
    if (response.status !== 200) {
      dispatch({ type: GET_VENUE_CACHE_REPORT_BY_VENUE_ID_IDLE, payload: response });
    } else if (response.status == 417) {
      await dispatch({ type: GET_VENUE_CACHE_REPORT_BY_VENUE_ID_FAILED, payload: response.data });
    } else {
      await dispatch({ type: GET_VENUE_CACHE_REPORT_BY_VENUE_ID_SUCCEEDED, payload: response.data });
    }
    return response;
  } catch (exception) {
    dispatch(setAppLoading(false));
    dispatch({ type: GET_VENUE_CACHE_REPORT_BY_VENUE_ID_FAILED, payload: exception });
  }
}

const GetVenueDetailsById = (id) => async (dispatch) => {
  dispatch({ type: GET_VENUE_DETAIL_BY_VENUE_ID_PENDING, payload: true });
  try {
    const response = await axios({
      method: 'GET',
      baseURL: API_URL,
      url: API_Interface.GET_VENUE_DETAIL_BY_VENUE_ID + id,
    }).then((response) => response)
      .catch((error) => error.response);
    if (response.status !== 200) {
      dispatch({ type: GET_VENUE_DETAIL_BY_VENUE_ID_IDLE, payload: response });
    } else if (response.status == 417) {
      await dispatch({ type: GET_VENUE_DETAIL_BY_VENUE_ID_FAILED, payload: response.data });
    } else {
      await dispatch({ type: GET_VENUE_DETAIL_BY_VENUE_ID_SUCCEEDED, payload: response.data });
    }
    return response;
  } catch (exception) {
    dispatch(setAppLoading(false));
    dispatch({ type: GET_VENUE_DETAIL_BY_VENUE_ID_FAILED, payload: exception });
  }
}

const UpdateVenueFloorSetting = (data) => async (dispatch) => {
  dispatch({ type: UPDATE_VENUE_FLOOR_SETTING_PENDING, payload: true });
  try {
    const response = await axios({
      method: 'POST',
      baseURL: API_URL,
      url: API_Interface.UPDATE_VENUE_FLOOR_SETTING,
      data: data,
    }).then((response) => response)
      .catch((error) => error.response);
    if (response.status !== 200) {
      dispatch({ type: UPDATE_VENUE_FLOOR_SETTING_IDLE, payload: response });
    } else if (response.status == 417) {
      await dispatch({ type: UPDATE_VENUE_FLOOR_SETTING_FAILED, payload: response.data });
    } else {
      await dispatch({ type: UPDATE_VENUE_FLOOR_SETTING_SUCCEEDED, payload: response.data });
    }
    return response;
  } catch (exception) {
    dispatch(setAppLoading(false));
    dispatch({ type: UPDATE_VENUE_FLOOR_SETTING_FAILED, payload: exception });
  }
}

const ResetVenueDetailForm = () => async (dispatch) => {
  dispatch({ type: GET_VENUE_DETAIL_BY_VENUE_ID_SUCCEEDED, payload: [] })
}


const PostVenue = (data) => async (dispatch) => {
  dispatch(setAppLoading(true));

  try {
    const response = await axios({
      method: "POST",
      baseURL: API_URL,
      //  url: API_Interface.INSERT_OR_UPDATE_VENUE_DETAIL,
      url: data.id ? API_Interface.INSERT_OR_UPDATE_VENUE_DETAIL : API_Interface.REGISTER_VENUE_ADMIN_INSERT_OR_UPDATE_VENUE_DETAIL,
      data: data,
    }).then((response) => response)
      .catch((error) => error.response)
    dispatch(setAppLoading(false));
    return response;
  } catch (exception) {
    dispatch(setAppLoading(false));
    return exception;
  }
}

const GetReservationDetailsWithPaging = (data, all) => async (dispatch) => {
  dispatch(setAppLoading(true));
  dispatch({ type: GET_VENUE_RESERVATION_DETAIL_BY_VENUE_ID_PENDING, payload: true });

  try {
    let axiosParam = {};
    axiosParam.baseURL = API_URL;
    if (all) {
      axiosParam.method = "GET";
      axiosParam.url = API_Interface.GET_VENUE_ANALYTICS;
    } else {
      axiosParam.method = "POST";
      axiosParam.url = API_Interface.GET_VENUE_ANALYTICS;
      axiosParam.data = data;
    }
    const response = await axios(axiosParam).then((response) => response)
      .catch((error) => error.response);
    if (response.status !== 200) {
      dispatch(setAppLoading(false));
      dispatch({ type: GET_VENUE_RESERVATION_DETAIL_BY_VENUE_ID_IDLE, payload: response });
    } else if (response.status == 417) {
      await dispatch({ type: GET_VENUE_RESERVATION_DETAIL_BY_VENUE_ID_FAILED, payload: response.data });
    } else {
      await dispatch({ type: GET_VENUE_RESERVATION_DETAIL_BY_VENUE_ID_SUCCEEDED, payload: response.data });
    }
    dispatch(setAppLoading(false));
    return response;
  } catch (exception) {
    dispatch(setAppLoading(false));
    dispatch({ type: GET_VENUE_RESERVATION_DETAIL_BY_VENUE_ID_FAILED, payload: exception });
  }
}

const GetVenueUsersByVenueId = (venueId) => async (dispatch) => {
  dispatch({ type: GET_VENUE_USERS_BY_VENUE_ID_PENDING, payload: true });
  try {
    const response = await axios({
      method: 'GET',
      baseURL: API_URL,
      url: API_Interface.GET_VENUE_USERS_BY_VENUE_ID + venueId,
    }).then((response) => response)
      .catch((error) => error.response);
    if (response.status !== 200) {
      dispatch({ type: GET_VENUE_USERS_BY_VENUE_ID_IDLE, payload: response });
    } else if (response.status == 417) {
      await dispatch({ type: GET_VENUE_USERS_BY_VENUE_ID_FAILED, payload: response.data });
    } else {
      await dispatch({ type: GET_VENUE_USERS_BY_VENUE_ID_SUCCEEDED, payload: response.data });
    }

    return response;
  } catch (exception) {
    dispatch(setAppLoading(false));
    dispatch({ type: GET_VENUE_USERS_BY_VENUE_ID_FAILED, payload: exception });
  }
}

const GetVenueTableByVenueId = (venueId) => async (dispatch) => {
  dispatch({ type: GET_VENUE_TABLE_BY_VENUE_ID_PENDING, payload: true });
  try {
    const response = await axios({
      method: 'GET',
      baseURL: API_URL,
      url: API_Interface.GET_VENUE_TABLE_BY_VENUE_ID + venueId,
    }).then((response) => response)
      .catch((error) => error.response);
    if (response.status !== 200) {
      dispatch({ type: GET_VENUE_TABLE_BY_TABLE_ID_IDLE, payload: response });
    } else if (response.status == 417) {
      await dispatch({ type: GET_VENUE_TABLE_BY_VENUE_ID_FAILED, payload: response.data });
    } else {
      await dispatch({ type: GET_VENUE_TABLE_BY_VENUE_ID_SUCCEEDED, payload: response.data });
    }

    return response;
  } catch (exception) {
    dispatch(setAppLoading(false));
    dispatch({ type: GET_VENUE_USERS_BY_VENUE_ID_FAILED, payload: exception });
  }
}

const GetProductCategoryByVenueId = (venueId) => async (dispatch) => {
  dispatch({ type: GET_PRODUCT_CATEGORY_BY_VENUE_ID_PENDING, payload: true });
  try {
    const response = await axios({
      method: 'GET',
      baseURL: API_URL,
      url: API_Interface.GET_PRODUCT_CATEGORY_BY_VENUE_ID_ADMIN + venueId,
    }).then((response) => response)
      .catch((error) => error.response);
    if (response.status !== 200) {
      dispatch({ type: GET_PRODUCT_CATEGORY_BY_VENUE_ID_IDLE, payload: response });
    } else if (response.status == 417) {
      await dispatch({ type: GET_PRODUCT_CATEGORY_BY_VENUE_ID_FAILED, payload: response?.data });
    } else {
      let framedCategoryProductResponse = [];
      if (response?.data && response?.data?.status === true && response?.data.categorydata?.length > 0) {
        for (let categoryItem of response?.data?.categorydata) {
          let categoryId = categoryItem?.id;
          if (categoryId && response?.data?.productdata) {
            let filteredProductsByCategoryId = response?.data?.productdata?.filter((x) => x?.categoryId === categoryId);
            framedCategoryProductResponse.push({
              ...categoryItem,
              ...{
                products: filteredProductsByCategoryId ? filteredProductsByCategoryId : []
              }
            });
          } else {
            framedCategoryProductResponse.push({
              ...categoryItem,
              ...{
                products: []
              }
            });
          }
        }
      }

      await dispatch({ type: GET_PRODUCT_CATEGORY_BY_VENUE_ID_SUCCEEDED, payload: framedCategoryProductResponse ? framedCategoryProductResponse : [] });
    }

    return response;
  } catch (exception) {
    dispatch(setAppLoading(false));
    dispatch({ type: GET_VENUE_USERS_BY_VENUE_ID_FAILED, payload: exception });
  }
}

const GetVenueTableByVenueIdAdmin = (venueId) => async (dispatch) => {
  dispatch({ type: GET_VENUE_TABLE_BY_VENUE_ID_PENDING, payload: true });
  try {
    const response = await axios({
      method: 'GET',
      baseURL: API_URL,
      url: API_Interface.GET_VENUE_TABLE_LIST_BY_VENUE_ID_ADMIN + venueId,
    }).then((response) => response)
      .catch((error) => error.response);
    if (response.status !== 200) {
      dispatch({ type: GET_VENUE_TABLE_BY_TABLE_ID_IDLE, payload: response });
    } else if (response.status == 417) {
      await dispatch({ type: GET_VENUE_TABLE_BY_VENUE_ID_FAILED, payload: response.data });
    } else {
      await dispatch({ type: GET_VENUE_TABLE_BY_VENUE_ID_SUCCEEDED, payload: response.data });
    }

    return response;
  } catch (exception) {
    dispatch(setAppLoading(false));
    dispatch({ type: GET_VENUE_USERS_BY_VENUE_ID_FAILED, payload: exception });
  }
}

const GetBlockDatesForVenue = (venueId, tableId) => async (dispatch) => {
  dispatch({ type: GET_VENUE_BLOCK_DATES_ID_PENDING, payload: true });
  try {
    const response = await axios({
      method: 'POST',
      baseURL: API_URL,
      url: `${API_Interface.GET_VENUE_BLOCK_DATE}${venueId}/${tableId}`,
    }).then((response) => response)
      .catch((error) => error.response);
    if (response.status !== 200) {
      dispatch({ type: GET_VENUE_BLOCK_DATES_ID_IDLE, payload: response });
    } else if (response.status == 417) {
      await dispatch({ type: GET_VENUE_BLOCK_DATES_ID_FAILED, payload: response.data });
    } else {
      await dispatch({ type: GET_VENUE_BLOCK_DATES_ID_SUCCEEDED, payload: response.data });
    }

    return response;
  } catch (exception) {
    dispatch(setAppLoading(false));
    dispatch({ type: GET_VENUE_BLOCK_DATES_ID_FAILED, payload: exception });
  }
}

const GetVenuePremiumDateRate = (venueId, tableId) => async (dispatch) => {
  dispatch({ type: GET_VENUE_PREMIUM_DATE_RANGE_ID_PENDING, payload: true });
  try {
    const response = await axios({
      method: 'POST',
      baseURL: API_URL,
      url: `${API_Interface.GET_VENUE_PREMIUM_RATE_DATE}${venueId}/${tableId}`,
    }).then((response) => response)
      .catch((error) => error.response);
    if (response.status !== 200) {
      dispatch({ type: GET_VENUE_PREMIUM_DATE_RANGE_ID_IDLE, payload: response });
    } else if (response.status == 417) {
      await dispatch({ type: GET_VENUE_PREMIUM_DATE_RANGE_ID_FAILED, payload: response.data });
    } else {
      await dispatch({ type: GET_VENUE_PREMIUM_DATE_RANGE_ID_SUCCEEDED, payload: response.data });
    }

    return response;
  } catch (exception) {
    dispatch(setAppLoading(false));
    dispatch({ type: GET_VENUE_BLOCK_DATES_ID_FAILED, payload: exception });
  }
}

const DownloadCacheReportForVenue = (venueId, fromDate, toDate) => async () => {
  try {
    const response = await axios({
      method: 'POST',
      baseURL: API_URL,
      url: `${API_Interface.DOWNLOAD_CACHE_REPORT}?venueId=${venueId}&FromDate=${fromDate}&ToDate=${toDate}`,
      responseType: 'blob', // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `CacheReport_${venueId}_${Date.now()}.xlsx`); //or any other extension
      document.body.appendChild(link);
      link.click();
      return response;
    }).catch((error) => error.response);

    return response;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else if (error.request) {
      return error.request;
    } else if (error.message) {
      return error.message;
    } else {
      return error;
    }
  }
}

const InsertOrUpdateBlockDate = (data) => async (dispatch) => {
  dispatch(setAppLoading(true));
  try {
    const response = await axios({
      method: "POST",
      baseURL: API_URL,
      url: `${API_Interface.INSERT_UPDATE_BLOCK_DATE}`,
      data: data,
    }).then((response) => response)
      .catch((error) => error.response)
    if (response.status !== 200) {
      dispatch(setAppLoading(false));
    }
    dispatch(setAppLoading(false));
    return response;
  } catch (exception) {
    dispatch(setAppLoading(false));
  }
}

const DeleteBlockDate = (data) => async (dispatch) => {
  dispatch(setAppLoading(true));
  try {
    const response = await axios({
      method: "POST",
      baseURL: API_URL,
      url: `${API_Interface.DELETE_BLOCK_DATE}`,
      data: data,
    }).then((response) => response)
      .catch((error) => error.response)
    if (response.status !== 200) {
      dispatch(setAppLoading(false));
    }
    dispatch(setAppLoading(false));
    return response;
  } catch (exception) {
    dispatch(setAppLoading(false));
  }
}

const GetVenueSettingByDay = (venueId) => async (dispatch) => {
  dispatch({ type: GET_VENUE_SETTING_BY_DAY_PENDING, payload: true });
  try {
    const response = await axios({
      method: 'GET',
      baseURL: API_URL,
      url: API_Interface.GET_VENUE_SETTING_BY_DAY + venueId,
    }).then((response) => response)
      .catch((error) => error.response);
    if (response.status !== 200) {
      dispatch({ type: GET_VENUE_SETTING_BY_DAY_IDLE, payload: response });
    } else if (response.status == 417) {
      await dispatch({ type: GET_VENUE_SETTING_BY_DAY_FAILED, payload: response.data });
    } else {
      await dispatch({ type: GET_VENUE_SETTING_BY_DAY_SUCCEEDED, payload: response.data });
    }

    return response;
  } catch (exception) {
    dispatch(setAppLoading(false));
    dispatch({ type: GET_VENUE_SETTING_BY_DAY_FAILED, payload: exception });
  }
}

const InsertOrUpdateVenueSettingByDay = (data) => async () => {
  try {
    const response = await axios({
      method: "POST",
      baseURL: API_URL,
      url: `${API_Interface.INSERT_OR_UPDATE_VENUE_SETTING_BY_DAY}`,
      data: data,
    }).then((response) => response)
      .catch((error) => error.response)
    return response;
  } catch (exception) {
    return exception;
  }
}

const InsertOrUpdateVenuePremiumRateDates = (data) => async () => {
  try {
    const response = await axios({
      method: "POST",
      baseURL: API_URL,
      url: `${API_Interface.INSERT_OR_UPDATE_VENUE_PREMIUM_RATE_DATES}`,
      data: data,
    }).then((response) => response)
      .catch((error) => error.response)
    return response;
  } catch (exception) {
    return exception;
  }
}

const GetReservationReportbyVenue = (data) => async (dispatch) => {
  dispatch(setAppLoading(true));
  dispatch({ type: GET_RESERVATION_REPORT_BY_VENUE_PENDING, payload: true });
  try {
    let axiosParam = {};
    axiosParam.baseURL = API_URL;
    axiosParam.method = "POST";
    axiosParam.url = API_Interface.GET_RESERVATION_REPORT_BY_VENUE;
    axiosParam.data = data;


    const response = await axios(axiosParam).then((response) => response)
      .catch((error) => error.response);
    if (response.status != 200) {
      dispatch(setAppLoading(false));
      dispatch({ type: GET_RESERVATION_REPORT_BY_VENUE_IDLE, payload: response });
    }
    else if (response.status == 417) {
      dispatch({ type: GET_RESERVATION_REPORT_BY_VENUE_FAILED, payload: response.data });
    }
    else {
      dispatch({ type: GET_RESERVATION_REPORT_BY_VENUE_SUCCEEDED, payload: response.data });
    }
    dispatch(setAppLoading(false));
    return response;
  }
  catch (exception) {
    dispatch(setAppLoading(false));
    dispatch({ type: GET_RESERVATION_REPORT_BY_VENUE_FAILED, payload: exception });
  }
}

const GetGuestSummarybyusers = (data) => async (dispatch) => {
  dispatch(setAppLoading(true));
  dispatch({ type: GET_GUEST_SUMMARY_BY_USER_PENDING, payload: true });
  try {
    let axiosParam = {};
    axiosParam.baseURL = API_URL;
    axiosParam.method = "POST";
    axiosParam.url = API_Interface.GET_GUEST_SUMMARY_BY_USER;
    axiosParam.data = data;


    const response = await axios(axiosParam).then((response) => response)
      .catch((error) => error.response);

    if (response.status != 200) {
      dispatch(setAppLoading(false));
      dispatch({ type: GET_GUEST_SUMMARY_BY_USER_IDLE, payload: response });
    }
    else if (response.status == 417) {
      dispatch({ type: GET_GUEST_SUMMARY_BY_USER_FAILED, payload: response.data });
    }
    else {
      dispatch({ type: GET_GUEST_SUMMARY_BY_USER_SUCCEEDED, payload: response.data });
    }
    dispatch(setAppLoading(false));
    return response;
  }
  catch (exception) {
    dispatch(setAppLoading(false));
    dispatch({ type: GET_GUEST_SUMMARY_BY_USER_FAILED, payload: exception });
  }
}

const DownloadReservationDetailsReportForVenue = (data) => async () => {
  try {
    const response = await axios({
      method: 'POST',
      baseURL: API_URL,
      url: `${API_Interface.DOWNLOAD_RESERVATION_REPORT}?venueId=${data.VenueId}&startDate=${data.StartDate}&endDate=${data.EndDate}`,
      responseType: 'blob', // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `VenueReservationReport_${data.venueId}_${Date.now()}.xlsx`); //or any other extension
      document.body.appendChild(link);
      link.click();
      return response;
    }).catch((error) => error.response);

    return response;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else if (error.request) {
      return error.request;
    } else if (error.message) {
      return error.message;
    } else {
      return error;
    }
  }
}

const DownloadReservationSummaryReportForVenue = (data) => async () => {
  try {
    const response = await axios({
      method: 'POST',
      baseURL: API_URL,
      url: `${API_Interface.DOWNLOAD_GUEST_SUMMARY_REPORT}?venueId=${data.VenueId}&startDate=${data.StartDate}&endDate=${data.EndDate}`,
      responseType: 'blob', // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `VenueGuestSummaryReport_${data.venueId}_${Date.now()}.xlsx`); //or any other extension
      document.body.appendChild(link);
      link.click();
      return response;
    }).catch((error) => error.response);

    return response;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else if (error.request) {
      return error.request;
    } else if (error.message) {
      return error.message;
    } else {
      return error;
    }
  }
}

const DownloadVenueAnalyticsReport = (data) => async () => {
  try {
    const response = await axios({
      method: 'POST',
      baseURL: API_URL,
      url: `${API_Interface.Download_VenueAnalytics_Report}?venueId=${data.venueId}&Fromdate=${data.Fromdate}&Todate=${data.Todate}`,
      responseType: 'blob', // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `VenueAnalyticsReport_${data.venueId}_${Date.now()}.xlsx`); //or any other extension
      document.body.appendChild(link);
      link.click();
      return response;
    }).catch((error) => error.response);
    return response;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else if (error.request) {
      return error.request;
    } else if (error.message) {
      return error.message;
    } else {
      return error;
    }
  }
}

const GetVenueDepositSettings = (venueId) => async (dispatch) => {
  dispatch(setAppLoading(true));
  dispatch({ type: GET_VENUE_DEPOSITE_SETTINGS_PENDING, payload: true });
  try {
    const response = await axios({
      method: 'GET',
      baseURL: API_URL,
      url: API_Interface.GET_VENUE_DEPOSITE_SETTINGS + venueId,
    }).then((response) => response)
      .catch((error) => error.response);
    if (response.status != 200) {
      dispatch(setAppLoading(false));
      dispatch({ type: GET_VENUE_DEPOSITE_SETTINGS_IDLE, payload: response });
    }
    else if (response.status == 417) {
      await dispatch({ type: GET_VENUE_DEPOSITE_SETTINGS_FAILED, payload: response.data });
    }
    else {
      await dispatch({ type: GET_VENUE_DEPOSITE_SETTINGS_SUCCEEDED, payload: response.data });
    }
    dispatch(setAppLoading(false));
    return response;
  }
  catch (exception) {
    dispatch(setAppLoading(false));
    dispatch({ type: GET_VENUE_DEPOSITE_SETTINGS_FAILED, payload: exception });
  }
}

const InsertOrUpdateVenueDeposite = (data) => async () => {
  try {
    const response = await axios({
      method: "POST",
      baseURL: API_URL,
      url: `${API_Interface.INSERT_UPDATE_VENUE_DEPOSITE}`,
      data: data,
    }).then((response) => response)
      .catch((error) => error.response)
    return response;
  } catch (exception) {
    return exception;
  }
}

// Send Reservation Report
const SendReservationReport = (userId, venueId) => async (dispatch) => {
  dispatch({ type: GET_VENUE_DEPOSITE_SETTINGS_PENDING, payload: true });
  try {
    const response = await axios({
      method: 'POST',
      baseURL: API_URL,
      url: API_Interface.SEND_RESERVATION_REPORT + userId + '/' + venueId,
    }).then((response) => response).catch((error) => error.response);

    if (response.status != 200) {
      dispatch({ type: SEND_RESERVATION_REPORT_IDLE, payload: response });
    } else if (response.status == 417) {
      await dispatch({ type: SEND_RESERVATION_REPORT_FAILED, payload: response.data });
    } else {
      await dispatch({ type: SEND_RESERVATION_REPORT_SUCCEEDED, payload: response.data });
    }

    return response;
  } catch (exception) {
    dispatch({ type: SEND_RESERVATION_REPORT_FAILED, payload: exception });
  }
}

// Send Cache Report By Date
const SendCacheReportByDate = (userId, venueId, date) => async (dispatch) => {
  dispatch({ type: SEND_CACHE_REPORT_PENDING, payload: true });

  try {

    const response = await axios({
      method: 'POST',
      baseURL: API_URL,
      url: `${API_Interface.SEND_CACHE_REPORT_BY_DATE}/${userId}/${venueId}/${date}`
    }).then((response) => {
      return response;
    }).catch((error) => {
      return error;
    });

    if (response.statusCode != 200) {
      await dispatch({ type: SEND_CACHE_REPORT_IDLE, payload: response });
    } else if (response.statusCode == 417) {
      await dispatch({ type: SEND_CACHE_REPORT_FAILED, payload: response.message });
    } else {
      await dispatch({ type: SEND_CACHE_REPORT_SUCCEEDED, payload: response.message });
    }
    const APIresponse = await response;
    return APIresponse;
  } catch (exception) {
    await dispatch({ type: SEND_CACHE_REPORT_FAILED, payload: exception });
  }
}

// Send Cache Report
const SendVenueCacheReport = (userId, venueId, startDate, endDate) => async (dispatch) => {

  dispatch({ type: SEND_RESERVATION_REPORT_PENDING, payload: true });

  try {

    let requrl = `${API_Interface.SEND_RESERVATION_REPORT}/${userId}/${venueId}?startDate=${startDate}&endDate=${endDate}`

    const response = await axios({
      method: 'POST',
      baseURL: API_URL,
      url: requrl
    }).then((response) => {
      return response;
    }).catch((error) => {
      return error;
    });

    if (response.statusCode != 200) {
      await dispatch({ type: SEND_RESERVATION_REPORT_IDLE, payload: response });
    } else if (response.statusCode == 417) {
      await dispatch({ type: SEND_RESERVATION_REPORT_FAILED, payload: response.message });
    } else {
      await dispatch({ type: SEND_RESERVATION_REPORT_SUCCEEDED, payload: response.message });
    }

    const APIresponse = await response;
    return APIresponse;
  } catch (exception) {
    await dispatch({ type: SEND_RESERVATION_REPORT_FAILED, payload: exception });
  }
}

// Send Guest Summary Report
const SendGuestSummaryReport = (userId, venueId, startDate, endDate) => async (dispatch) => {
  dispatch({ type: SEND_GUEST_SUMMARY_PENDING, payload: true });

  try {

    const response = await axios({
      method: 'POST',
      baseURL: API_URL,
      url: `${API_Interface.SEND_GUEST_SUMMARY_REPORT}/${userId}/${venueId}?startDate=${startDate}&endDate=${endDate}`
    }).then((response) => {
      return response;
    }).catch((error) => {
      return error;
    });

    if (response.statusCode != 200) {
      await dispatch({ type: SEND_GUEST_SUMMARY_IDLE, payload: response });
    } else if (response.statusCode == 417) {
      await dispatch({ type: SEND_GUEST_SUMMARY_FAILED, payload: response.message });
    } else {
      await dispatch({ type: SEND_GUEST_SUMMARY_SUCCEEDED, payload: response.message });
    }

    const APIresponse = await response;
    return APIresponse;
  } catch (exception) {
    await dispatch({ type: SEND_GUEST_SUMMARY_FAILED, payload: exception });
  }
}

export {
  FetchReservationByUserID,
  FetchVenueView,
  CheckInReservation,
  CancelReservation,
  RequestCancellationForReservation,
  ClearReservation,
  GetAllVenueDetailsWithPaging,
  GetVenueContactDetail,
  GetVenueCacheReportByDate,
  GetVenueDetailsById,
  PostVenue,
  ResetVenueDetailForm,
  GetReservationDetailsWithPaging,
  GetVenueUsersByVenueId,
  GetVenueTableByVenueId,
  GetProductCategoryByVenueId,
  DeleteVenueUserById,
  AcceptReservation,
  DenyReservation,
  GetVenueCacheReportByVenueId,
  GetCacheReportDetailsByDate,
  UndoCheckInReservation,
  GetVenueTableByVenueIdAdmin,
  DownloadCacheReportForVenue,
  CompleteReservation,
  GetBlockDatesForVenue,
  GetVenuePremiumDateRate,
  InsertOrUpdateBlockDate,
  DeleteBlockDate,
  ToggleVenueState,
  AcceptCancellation,
  DenyCancellation,
  GetVenueSettingByDay,
  InsertOrUpdateVenueSettingByDay,
  InsertOrUpdateVenuePremiumRateDates,
  GetReservationReportbyVenue,
  GetGuestSummarybyusers,
  DownloadReservationDetailsReportForVenue,
  DownloadReservationSummaryReportForVenue,
  GetVenueDepositSettings,
  InsertOrUpdateVenueDeposite,
  SendReservationReport,
  SendCacheReportByDate,
  SendVenueCacheReport,
  SendGuestSummaryReport,
  DownloadVenueAnalyticsReport,
  UpdateVenueFloorSetting
}

