import React, { useEffect, useState } from "react";
import { Flex, Box, Heading, HStack, Button, Icon, Text, Center, useToast, Switch, Divider } from 'native-base';
import { Ionicons } from "@expo/vector-icons";
import { FontAwesome } from '@expo/vector-icons';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Redux :: Components
import Linking from '../../../shared/templates/customLinking';
import { SpinnerLoading } from '../../../shared/templates/SpinnerLoading';
import { primaryColors } from '../../../shared/utils/colors';
import AlertDialog from '../../AlertDialog';

/* ----- REDUX :: ACTIONS ----- */
import * as VenueActions from '../../../shared/redux/actions/venue';
import * as TableActions from '../../../shared/redux/actions/venuetable';
import * as FloorActions from '../../../shared/redux/actions/floor';
import AccordionListItem from '../../../shared/templates/AccordianList';
import { capitalize } from "../../../shared/utils/common";

const VenueTable = (props) => {
	const { navigation, venueTableResponse, venueTableLoading, headerTitleOptions, venueId,
		GetVenueTableByVenueId, venueDetail, deletetabledetails, PostTable,GetFloorListByVenueId,FloorListResponse, DeleteFloorListById, UpdateVenueFloorSetting,
		GetVenueDetailsById } = props;

	const [showAlert, setshowAlert] = useState(false);
	const [showAlertForFloor, setShowAlertForFloor] = useState(false);
	const [activeItem, setActiveItem] = useState(null);
	const [tableListWithoutFloorid, setTableListWithoutFloorid] = useState([]);
	const [multifloor, setMultifloor] = useState(false);
	const [multifloorStatus, setMultifloorStatus] = useState(false);

	const toast = useToast();

	useEffect(() => {
		loadTableData(venueDetail?.id || '');
		setMultifloor(venueDetail?.multifloor)
		setMultifloorStatus(venueDetail?.multifloor)
	}, [venueDetail]);

	const loadTableData = async (venueId) => {
		if (venueId) {
			await GetVenueTableByVenueId(venueId);
			await GetFloorListByVenueId(venueId)
		}
	}

	const HandleDeleteVenueTable = async (id, venueid) => {
		toast.closeAll();

		if (id !== "") {
			const PostVenueUser_Response = await deletetabledetails(id).then((response) => response);
			if (PostVenueUser_Response?.data?.status === "Success") {
				toast.show({
					render: () => <Box bg="emerald.500" px="2" py="1" rounded="sm" mb={5}>Table Deleted Successfully!</Box>
				});
				await loadTableData(venueid);
			} else {
				toast.show({
					render: () => <Box bg="error.600" px="2" py="1" rounded="sm" mb={5}>Failed! Please try again later</Box>
				});
			}
		} else {
			toast.show({
				render: () => <Box bg="error.600" px="2" py="1" rounded="sm" mb={5}>Something went wrong!</Box>
			});
		}
		setTimeout(() => {
			toast.closeAll();

		}, 1000);

	}

	const HandleDeleteVenueFloor = async (floorId) => {
		toast.closeAll();

		if (floorId !== "") {
			const PostVenueUser_Response = await DeleteFloorListById(floorId).then((response) => response);
			if (PostVenueUser_Response?.data?.status === "Success") {
				toast.show({
					render: () => <Box bg="emerald.500" px="2" py="1" rounded="sm" mb={5}>Floor Deleted Successfully!</Box>
				});
				await loadTableData(venueid);
			} else {
				toast.show({
					render: () => <Box bg="error.600" px="2" py="1" rounded="sm" mb={5}>Failed! Please try again later</Box>
				});
			}
		} else {
			toast.show({
				render: () => <Box bg="error.600" px="2" py="1" rounded="sm" mb={5}>Something went wrong!</Box>
			});
		}
		setTimeout(() => {
			toast.closeAll();

		}, 1000);

	}

	const HandleActiveVenueTable = async (rowItem, status) => {
		let param = rowItem;
		param.status = status ? '1' : '0';
		const PostTable_Response = await PostTable(param).then((response) => response);
		if (PostTable_Response?.data?.status === "Success") {
			loadTableData(venueDetail?.id || '');
		}
	}

	const renderRowItem = (rowItem, rowIteration) => {
		return <HStack justifyContent="flex-start" space={3}>
			{

				headerTitleOptions.map((item, iteration) => {
					
					let minSpendLabel = '';
					minSpendLabel = ((rowItem?.tableRateTier || [])
						?.sort((a, b) => a.ratetierid - b.ratetierid)
						?.map((tableTierItem) => `$${tableTierItem?.minimumSpendAmount}` || `$0`) || [])
						?.join(` - `);

					return <Box key={`${item?.width}_${rowIteration}_v1${iteration}`} w={`205`}>
						{item?.label === "Table Name" && <Text fontSize="md" pl="4">{rowItem.tableName ? rowItem.tableName : "-"}</Text>}
						{item?.label === "Active" && <Switch color="secondary" size="sm" isChecked={rowItem?.status == '1' ? true : false} onToggle={(status) => HandleActiveVenueTable(rowItem, status)} />}
						{item?.label === "Count" && <Text fontSize="md">
							{`${rowItem.count ? rowItem.count : "0"} Guests`}
						</Text>}
						{item?.label === "Minimum Spent" && <Text fontSize="md">{minSpendLabel || ''}</Text>}
						{item?.label === "User Actions" && <>
							<Button.Group size="sm">
								<Button
									variant="solid"
									colorScheme='blue'
									size="xs"
									pl="3"
									pr="3"
									pt="1"
									pb="1"
									mr="2"
									borderRadius="full"
									leftIcon={<Icon as={FontAwesome} name="edit" size="xs" />}
									onPress={() => redirectURL(`/AddOrEditTables/${rowItem?.venueid}/${rowItem?.tableid}/${rowItem?.floorid}`, false)}
									_text={{
										fontSize: 'sm',
										color: 'white'
									}}
								>
									Edit Table
								</Button>
								
								<Button
									variant="solid"																																																																								
									colorScheme='red'
									size="xs"
									borderRadius="full"
									pl="3"
									pr="3"
									pt="1"
									pb="1"
									mr="2"
									leftIcon={<Icon as={Ionicons} name="trash" size="xs" />}
									onPress={() => {
										setshowAlert(true);
										setActiveItem(rowItem);
									}}
									_text={{																																																																							
										fontSize: 'sm',
										color: 'white'
									}}
								>
									Delete Table
								</Button>
							</Button.Group>
						</>}
					</Box>;
				})
			}
		</HStack>;
	}

	const redirectURL = (redirectBaseURL, isTargetBlank) => {
		if (isTargetBlank) {
			Linking.openURL(redirectBaseURL, '_blank')
		} else {
			Linking.openURL(redirectBaseURL)
		}
	}
    
    const floorList = FloorListResponse.map((data)=>{
		data.content=venueTableResponse.filter(el=>el.floorid === data.id );
		return data;
	}) 

	useEffect(()=>{
		if(venueTableResponse != null){
			const withoutFloorid = venueTableResponse.filter(el=>el.floorid == null );
			setTableListWithoutFloorid(withoutFloorid);
		}
		
	},[venueTableResponse])

	const updateFloorSetting =async()=>{
		const param ={
			"venueId": venueDetail?.id,
			"multifloor": multifloor
		  }
		const update_multi_floor = await UpdateVenueFloorSetting(param).then((response) => response);
		if (update_multi_floor?.data?.status === "Success") {
			toast.show({
				render: () => <Box bg="emerald.500" px="2" py="1" rounded="sm" mb={5}>Updated Successfully!</Box>
			});
			setMultifloorStatus(multifloor)
		} else {
			toast.show({
				render: () => <Box bg="error.600" px="2" py="1" rounded="sm" mb={5}>Failed! Please try again later</Box>
			});
		}
	}
   
	return <>
	    <Flex flexDirection="row" alignItems="center" mb="4" w="75%">
			<Heading size='lg'>Floor Setting</Heading>
		</Flex>
	    <Flex flexDirection="row" mb="4">
			<Flex
				px={3}
				py={2}
				bg="#dcdcdc"
				borderRadius={15}
				flexDirection="row"
				justifyContent="space-between"
				alignItems="center"
				w={"fit-content"}
			>
				<Flex flexDirection="row" alignItems="center">
					<Text bold fontSize="lg">MultiFloor</Text>
					<Box px={2}>
						<Switch
							color="secondary"
							size="md"
							isChecked={multifloor}
							onToggle={(status) => {setMultifloor(status)}}
						/>
					</Box>
				</Flex>
			</Flex>
			<Button.Group size="sm" ml="5">
				<Button
					variant="solid"
					colorScheme='blue'
					size="xs"
					pl="3"
					pr="3"
					
					mr="2"
					borderRadius="full"
					style={{ marginRight: "80px" }}
					onPress={() => {updateFloorSetting()}}
					_text={{
						fontSize: 'sm',
						color: 'white'
					}}
					fontSize="14px"
				>
					Update Setting
				</Button>
			</Button.Group>
		</Flex>
		<Divider my="3" bg="muted.400" w={"80%"} /> 
		<Flex flexDirection="row" alignItems="center" mt="4" pb="4" w="75%">
			<Heading size='lg' minWidth="200px">Table List</Heading>
			{multifloorStatus && <Button
				background={primaryColors.primaryRed}
				w="150"
				pl="3"
				pr="3"
				pt="1"
				pb="1"
				ml="2"
				mr="2"
				variant="outline"
				borderRadius="full"
				// onPress={() => addVenueTable(0, venueId)}
				onPress={() => redirectURL(`/AddFloor/${venueDetail?.id || 0}`, false)}
				leftIcon={<Icon as={FontAwesome} name="plus-circle" size="xs" fontWeight="bold" color="white" />}
				_text={{
					fontSize: 'xs',
					fontWeight: "bold",
					color: 'white'
				}}
			>
				Add Floor
			</Button>}
		</Flex>
      
	{multifloorStatus && floorList.length>0  && floorList.map((item, index) => {
        return <HStack key={`Stack_${index}`} >
          <Box w={"80%"} key={`Container_${index}`} >
            <AccordionListItem
              key={`Header_${index}`}
              title={item?.floorName}
			  type={"floor"}
              addProductHandler={() => redirectURL(`/AddOrEditTables/${venueDetail?.id || 0}/0/${item.id}`, false)}
              editCategoryHandler={() => redirectURL(`/AddFloor/${venueDetail?.id || 0}/${item.id}`, false)}
              deleteCategoryHandler={() => {setShowAlertForFloor(true);setActiveItem(item);}}
            >
              {
                item.content.length ?
                  (item.content || []).map((table, iteration) => <Box key={`Content_${iteration}`} p={"2"}>
                     <Box
						key={`VenueTableList_${table.id}_${iteration}`}
						my="2"
						w="100%"
					>
						{renderRowItem(table, iteration)}
					</Box>
                  </Box>
                  )
                  :
                  <Box bold key={`Content_${index} `} p={"2"}>
                    <Text fontSize="md" bold>{capitalize("No data found")}
                    </Text>
                  </Box>
              }
            </AccordionListItem>
          </Box>
        </HStack>
      })}
	{tableListWithoutFloorid.length > 0 &&
			(tableListWithoutFloorid || []).map((table, iteration) => <Box key={`Content_${iteration}`} p={"2"}>
				<Box
				key={`VenueTableList_${table.id}_${iteration}`}
				my="2"
				w="100%"
			>
				{renderRowItem(table, iteration)}
			</Box>
			</Box>
			)
	}
	 
	{tableListWithoutFloorid.length === 0 && floorList.length === 0 && <Box my={2}>
		<Text bold fontSize="lg">No Floor List found!</Text>
	</Box>}
    

		<AlertDialog
			showAlert={showAlert || false}
			Header='Delete Table'
			Body={
				<Center>
					<Text fontSize="lg">Are you sure want to delete table?</Text>
					<Text bold fontSize="md">{activeItem?.tableName}</Text>
				</Center>
			}
			Name={""}
			handleClose={
				async (status, deleteType) => {
					if (deleteType == "DELETE") {
						HandleDeleteVenueTable(activeItem?.tableid, activeItem?.venueid)
						await loadTableData(venueDetail?.id);
						await setActiveItem(null);
						await setshowAlert(status);
					} else {
						await setActiveItem(null);
						await setshowAlert(status);
					}
				}}
		/>

        <AlertDialog
			showAlert={showAlertForFloor || false}
			Header='Delete Floor'
			Body={
				<Center>
					<Text fontSize="lg">Are you sure want to delete Floor?</Text>
					<Text bold fontSize="md">{activeItem?.floorName}</Text>
				</Center>
			}
			Name={""}
			handleClose={
				async (status, deleteType) => {
					setShowAlertForFloor(false)
					if (deleteType == "DELETE") {
						HandleDeleteVenueFloor(activeItem?.id)
					}
				}}
		/>

	</>;
}

VenueTable.defaultProps = {
	venueTableResponse: [],
	headerTitleOptions: [
		{ key: '0_TableName', label: "Table Name", width: "22.3%" },
		{ key: '1_TableActive', label: "Active", width: "5.3%" },
		{ key: '1_TableCount', label: "Count", width: "15.3%" },
		{ key: '2_TableMinimumSpend', label: "Minimum Spent", width: "25.3%" },
		{ key: '3_TableActions', label: "User Actions", width: "28.3%" },
	]
}

VenueTable.propTypes = {
	venueTableResponse: PropTypes.array,
	venueId: PropTypes.any,
	GetVenueTableByVenueId: PropTypes.func,
	headerTitleOptions: PropTypes.array,
	venueTableLoading: PropTypes.bool,
	navigation: PropTypes.any,
	ResetTableDetailForm: PropTypes.func,
	deletetabledetails: PropTypes.func,
	venueDetail: PropTypes.any,
	PostTable: PropTypes.func,
	GetFloorListByVenueId: PropTypes.func,
	FloorListLoading:PropTypes.bool,
	FloorListResponse:PropTypes.any,
	DeleteFloorListById:PropTypes.func
}

const mapStateToProps = (state) => {
	const { venueTableResponse, venueTableLoading, VenuesDetailResponse, FloorListLoading, FloorListResponse} = state.venue;
	return {
		venueTableResponse,
		venueTableLoading,
		FloorListLoading,
		FloorListResponse,
		venueDetail: VenuesDetailResponse || []
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		GetVenueTableByVenueId: (venueId) => dispatch(VenueActions.GetVenueTableByVenueIdAdmin(venueId)).then((response) => response),
		ResetTableDetailForm: () => dispatch(TableActions.ResetTableDetailForm()).then((response) => response),
		deletetabledetails: (Id) => dispatch(TableActions.deletetabledetailsById(Id)).then((response) => response),
		PostTable: (postreq) => dispatch(TableActions.PostTable(postreq)).then((response) => response),
		GetFloorListByVenueId: (venueId) => dispatch(FloorActions.GetFloorListByVenueId(venueId)).then((response) => response),
		DeleteFloorListById: (floorId) => dispatch(FloorActions.DeleteFloorById(floorId)).then((response) => response),
		UpdateVenueFloorSetting: (data) => dispatch(VenueActions.UpdateVenueFloorSetting(data)).then((response) => response),
		GetVenueDetailsById: (venueId) => dispatch(VenueActions.GetVenueDetailsById(venueId)).then((response) => response),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(VenueTable);