/* eslint-disable react-native/no-inline-styles */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { Linking } from 'react-native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { getHeaderTitle } from '@react-navigation/elements';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

/* ----- COMPONENTS ----- */
import LoginScreen from './pages/auth';
import ForgotPasswordScreen from './pages/auth/forgotPassword';
import VenueList from './pages/venues';
import Settings from './pages/settings';
import VenueDetails from './pages/venues/venueDetails';
import AddOrEditVenue from './pages/venues/addOrEditVenues';
import ViewVenue from './pages/venues/viewVenue';
import UsersScreen from './pages/users';
import AddOrEditUsers from './pages/users/addOrEditUsers';
import MenuScreen from './pages/operations';
import { Header } from './layouts'
import AddOrEditTable from './pages/venues/addOrEditTables';
import AddOrEditProduct from './pages/venues/addOrEditProduct';
import ReservationReport from './pages/venues/ReservationReport';
import VenueWebsiteOperations from './pages/venues/VenueWebsiteOperations';
import AddFloor from './pages/venues/addFloor';
import Widget from './pages/settings/widget';
import { isEmpty } from 'lodash';

/* ----- STACK NAVIGATORS ----- */
const Stack = createNativeStackNavigator();

const RootInterface = (props) => {
  const { loginResponse } = props;
  const { Navigator, Screen } = Stack;
  const { userRoles } = loginResponse;

  return <Navigator
    initialRouteName={loginResponse?.user?.id ? "Venue" : "Login"}
  >

    <Screen
      name="Login"
      component={LoginScreen}
      options={{
        header: ({ navigation, route, options, back }) => {
          return null;
        }
      }}
    />
    <Screen
      name="ForgotPassword"
      component={ForgotPasswordScreen}
      options={{
        header: ({ navigation, route, options, back }) => {
          return null;
        }
      }}
    />
    <Screen
      name={"Venue"}
      component={VenueList}
      options={{
        header: ({ navigation, route, options, back }) => {
          const title = getHeaderTitle(options, route.name);
          if(userRoles?.includes('Admin')){
            return <Header title={title} navigation={navigation} route={route} />;
          }
        },
      }}
      style={{ paddingTop: 150 }}
    />
    <Screen
      name="VenueDetails"
      component={VenueDetails}
      options={{
        header: ({ navigation, route, options, back }) => {
          const title = getHeaderTitle(options, route.name);
          if(loginResponse?.user?.id){
            return <Header title={title} navigation={navigation} route={route} />;
          }
        }
      }}
    />
    <Screen
      name="AddOrEditVenue"
      component={AddOrEditVenue}
      options={{
        header: ({ navigation, route, options, back }) => {
          const title = getHeaderTitle(options, route.name);
          return <Header title={title} navigation={navigation} route={route} />;
        }
      }}
    />
    <Screen
      name="ViewVenue"
      component={ViewVenue}
      options={{
        header: ({ navigation, route, options, back }) => {
          const title = getHeaderTitle(options, route.name);
          return <Header title={title} navigation={navigation} route={route} />;
        }
      }}
    />
    <Screen
      name="Users"
      component={UsersScreen}
      options={{
        header: ({ navigation, route, options, back }) => {
          const title = getHeaderTitle(options, route.name);
          return <Header title={title} navigation={navigation} route={route} />;
        }
      }}
    />
    <Screen
      name="AddOrEditUsers"
      component={AddOrEditUsers}
      options={{
        header: ({ navigation, route, options, back }) => {
          const title = getHeaderTitle(options, route.name);
          return <Header title={title} navigation={navigation} route={route} />;
        }
      }}
    />
    <Screen
      name="Menu"
      component={MenuScreen}
      options={{
        header: ({ navigation, route, options, back }) => {
          const title = getHeaderTitle(options, route.name);
          return <Header title={title} navigation={navigation} route={route} />;
        }
      }}
    />
    <Screen
      name="AddOrEditTables"
      component={AddOrEditTable}
      options={{
        header: ({ navigation, route, options, back }) => {
          const title = getHeaderTitle(options, route.name);
          return <Header title={title} navigation={navigation} route={route} />;
        }
      }}
    />
    <Screen
      name="ReservationReport"
      component={ReservationReport}
      options={{
        header: ({ navigation, route, options, back }) => {
          const title = getHeaderTitle(options, route.name);
          return <Header title={title} navigation={navigation} route={route} />;
        }
      }}
    />
    <Screen
      name="VenueWebsiteOperations"
      component={VenueWebsiteOperations}
      options={{
        header: ({ navigation, route, options, back }) => {
          const title = getHeaderTitle(options, route.name);
          return <Header title={title} navigation={navigation} route={route} />;
        }
      }}
    />
    <Screen
      name="Settings"
      component={Settings}
      options={{
        header: ({ navigation, route, options, back }) => {
          const title = getHeaderTitle(options, route.name);
          return <Header title={title} navigation={navigation} route={route} />;
        }
      }}
    />
    <Screen
      name="Widget"
      component={Widget}
      options={{
        header: ({ navigation, route, options, back }) => {
          const title = getHeaderTitle(options, route.name);
          return <Header title={title} navigation={navigation} route={route} />;
        }
      }}
    />
    <Screen
      name="AddOrEditProduct"
      component={AddOrEditProduct}
      options={{
        header: ({ navigation, route, options, back }) => {
          const title = getHeaderTitle(options, route.name);
          return <Header title={title} navigation={navigation} route={route} />;
        }
      }}
    />
    <Screen
      name="AddFloor"
      component={AddFloor}
      options={{
        header: ({ navigation, route, options, back }) => {
          const title = getHeaderTitle(options, route.name);
          return <Header title={title} navigation={navigation} route={route} />;
        }
      }}
    />
  </Navigator>;
}

const mapStateToProps = (state) => {
  const { loginResponse } = state.auth;

  return {
    loginResponse
  };
}

RootInterface.propTypes = {
  loginResponse: PropTypes.any
}

export default connect(mapStateToProps, null)(RootInterface);