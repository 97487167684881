// Redux :: Store
import { store } from '../redux/store/configureStore';

// Config
import * as CONFIG from '../../shared/config';

const getAppSettingsData = (assetName) => {
  const persistedSettingsData = store.getState()?.appsetting;
  if (persistedSettingsData?.appsettingResponse?.[assetName]) {
    return persistedSettingsData?.appsettingResponse?.[assetName];
  }
  return null;
}

const LOGIN_BG_ICON = require('../assets/images/LoginBgImage.png');

const APP_ICON = CONFIG.APP_TYPE === "User" ? require('../assets/images/applogo_user.png') : ( CONFIG.APP_TYPE === "VenueAdmin" || CONFIG.APP_TYPE === "VenueUser" ) &&  require('../assets/images/applogo_venue.png');
const WEB_APP_ICON = require('../assets/images/web_admin_logo.png');
const BG_ICON = getAppSettingsData('backgroundImagePath') ? { uri: getAppSettingsData('backgroundImagePath') } : require('../assets/images/ClubqBG.png');
const BG_WEB_IMG = require('../assets/images/ClubqBG_WEB.png');
const PASSWORD_ICON = require('../assets/images/password-icon.png');
const PHONE_ICON = require('../assets/images/phone-icon.png');
const TOP_BAR_ICON = CONFIG.APP_TYPE === "User" ? require('../assets/images/applogo_user.png') : require('../assets/images/applogo_venue.png');
const BACK_ICON = require('../assets/images/back.png');
const SETTING_ICON = require('../assets/images/settings.png');
const SIGNOUT_ICON = require('../assets/images/signout.png');
const Q_ICON = require('../assets/images/Q.png');
const Q_ACTIVE_ICON = require('../assets/images/Q-active.png');
const TIMER_ICON = require('../assets/images/Group53.png');
const DATEPICKER_ICON = require('../assets/images/Group56.png');
const PLUS_ICON = require('../assets/images/Group58.png');
const WARNING_ICON = require('../assets/images/warning-icon.png');


const HOME_ACTIVE_ICON = require('../assets/images/HomeIcon-Active.png');
const HOME_ICON = require('../assets/images/HomeIcon.png');

const SETTINGS_ICON = require('../assets/images/SettingsIcon.png');
const CONFIRM_ICON = require('../assets/images/ConfirmLogo.png');
const CLEAR_ICON = require('../assets/images/ClearLogo.png');

const UNDO_ICON = require('../assets/images/UndoLogo.png');
// const IMAGE_PLACEHOLDER = require('../assets/images/ImagePlaceholder.png');

const LIST_ICON = require('../assets/images/ListIcon.png');
const LIST_ACTIVE_ICON = require('../assets/images/ListIcon-Active.png');

const ACTION_ICON = require('../assets/images/ActionIcon.png');
const ACTION_ACTIVE_ICON = require('../assets/images/ActionIcon-Active.png');


export {
  APP_ICON,
  WEB_APP_ICON,
  BG_ICON,
  BG_WEB_IMG,
  PASSWORD_ICON,
  PHONE_ICON,
  TOP_BAR_ICON,
  BACK_ICON,
  SETTING_ICON,
  SIGNOUT_ICON,
  Q_ICON,
  Q_ACTIVE_ICON,
  TIMER_ICON,
  DATEPICKER_ICON,
  PLUS_ICON,
  WARNING_ICON,

  HOME_ACTIVE_ICON,
  HOME_ICON,
  SETTINGS_ICON,
  CONFIRM_ICON,
  CLEAR_ICON,
  LIST_ICON,
  LIST_ACTIVE_ICON,
  ACTION_ICON,
  ACTION_ACTIVE_ICON,
  UNDO_ICON,
  // IMAGE_PLACEHOLDER,

  LOGIN_BG_ICON
}
