/* eslint-disable react-native?no-raw-text */
import React, { useState, useEffect } from "react";
import { Box, HStack, Text, Center, Flex, Heading, Divider, Popover, Button, Icon } from "native-base";
import { connect } from "react-redux";
import { DataTable } from "react-native-paper";
import moment from "moment";
import { Calendar } from 'react-date-range';
import { MaterialIcons } from "@expo/vector-icons";
import PropTypes from 'prop-types';
import { FontAwesome } from '@expo/vector-icons';

/*Components */
import { SpinnerLoading } from '../../../shared/templates/SpinnerLoading';

/*---UTILS---*/
import { primaryColors } from '../../../shared/utils/colors';

/* ----- REDUX :: ACTIONS ----- */
import * as VenueActions from '../../../shared/redux/actions/venue';
import { id } from "date-fns/locale";
import VenueReservationDetail from './ReservationReport';
import { MaterialCommunityIcons } from "@expo/vector-icons";
import ModalWrapper from "../../Modal"; 




const venueReservationReports = (props) => {
	const { venueReservationReport, venuesReservationReportWithPagingLoading, venuesReservationReportWithPagingResponse, headerTitleOptions,
		GetReservationReportbyVenue, venueId, DownloadReservationDetailsReport } = props;
	const [startDate, setStartDate] = useState(moment().subtract(7, 'days').format('MM/DD/YYYY'));
	const [endDate, setEndDate] = useState(moment().format('MM/DD/YYYY'));
	const [openStartDate, setOpenStartDate] = useState(false);
	const [openEndDate, setOpenEndDate] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [type, setType] = useState("");
	const [selectedRowData, setSelectedRowData] = useState({});

	const data = { 'VenueId': venueId, 'StartDate': startDate, 'EndDate': endDate };

	useEffect(() => {
		if (venueId > 0) {
			loadReservationReport(venueId);
		}
	}, [venueId]);

	useEffect(() => {
		if (startDate) {
			setTimeout(() => loadReservationReport(venueId), 50);
		}
	}, [startDate]);

	useEffect(() => {
		if (endDate) {
			setTimeout(() => loadReservationReport(venueId), 50);
		}
	}, [endDate]);

	const loadReservationReport = async (venueId) => {
		await GetReservationReportbyVenue(
			{
				venueId: venueId,
				startDate: moment(startDate, "MM/DD/YYYY").format("YYYY-MM-DD[T]HH:mm:ss") || '',
				endDate: moment(endDate, "MM/DD/YYYY").format("YYYY-MM-DD[T]HH:mm:ss") || '',
			},
			false
		);
	}

	const handleViewTransaction = (rowItem) =>{
       setShowModal(true);
	   setType('view_transaction');
	   setSelectedRowData(rowItem);
	}

	const renderHeader = (headers) => {
		return <HStack space={2}>
			{
				headers.map((item, iteration) => {
					return <Box w={item?.width} key={`${item?.key}_${iteration}`} h="10px" display="flex" flexDirection="row" alignItems="center"
						justifyContent={(item?.label === "Table" || item?.label === "Phone Number" || item?.label === "Name" || item?.label === "Email")
						 ? "center"
							: (item?.label === "Minimum"  || item?.label === "# Guests" || item?.label === "Time" || item?.label === "Date") 
							? "center"
								:item?.label === "Order Total" ? "center" : "center"} >
						{(item?.label === "Date") ? <Text fontWeight="bold" italic>{item?.label}</Text>
							: <Center><Text fontWeight="bold" italic>{item?.label}</Text></Center>
						}
					</Box>;
				})
			}
		</HStack>
	}

	const renderRowItem = (rowItem, rowIteration) => {
		return <HStack justifyContent="flex-start" space={2}>
			{
				headerTitleOptions.map((item, iteration) => {
					return <Box key={`${item?.width}_${rowIteration}_v1${iteration}`} w={`${item?.width}`} >
						{item?.label === "Name" && <Text fontSize="md" textAlign="center" >{rowItem.name}</Text>}
						{item?.label === "Phone Number" && <Text fontSize="md" textAlign="center">{rowItem.phoneNumber ? rowItem.phoneNumber : 0}</Text>}
						{item?.label === "Email" && <Text fontSize="md" textAlign="center" >{rowItem.email}</Text>}
						{item?.label === "Date" && <Text fontSize="md" fontWeight="bold" textAlign="center" >{rowItem.date ? moment(rowItem?.date).format("MM/DD") : 0}</Text>}
						{item?.label === "Time" && <Text fontSize="md" fontWeight="bold" textAlign="center">{rowItem.time ? rowItem?.time : 0}</Text>}
						{item?.label === "Table" && <Text fontSize="md" textAlign="center" >{rowItem.table}</Text>}
						{item?.label === "# Guests" && <Text fontSize="md" textAlign="center" >{rowItem.guests ? rowItem.guests : 0}</Text>}
						{item?.label === "Minimum" && <Text fontSize="md" textAlign="center" >{rowItem.minimum ? rowItem.minimum.toLocaleString("en-US", { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) : 0.00}</Text>}
						{item?.label === "Order Total" && <Text fontSize="md" textAlign="center" >{rowItem.orderTotal ? rowItem.orderTotal.toLocaleString("en-US", { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) : 0.00}</Text>}
						{item?.key === "9_Actions" && 
									<Button
									variant="solid"
									colorScheme='rgb(208, 208, 208)'
									size="xs"
									pt="1"
									pb="1"
									mr="2"
									borderRadius="full"
									leftIcon={<Icon
										as={MaterialCommunityIcons}
										onPress={()=>{handleViewTransaction(rowItem)}}
										name="eye"
										size="5"
										style={{paddingLeft:6,paddingRight:3}}
										color={'black'}
									/>}
									onPress={()=>{handleViewTransaction(rowItem)}}
									_text={{
									  fontSize: 'sm',
									  color: 'black',
									  paddingBottom:'1',
									  paddingRight:'2'
									}}
								  >
									View Transaction
								  </Button>}
					</Box>;
				})
			}
		</HStack>;
	}

	return <>
		<Flex flexDirection="row" alignItems="center" w={"100%"}>
			<Heading size='lg'>Reservation Report</Heading>
		</Flex>
		<Divider my="3" bg="muted.400" />
		<Flex
			flexDirection="row"
			alignItems="center"
			justifyContent="space-between"
			my="3"
			w={'100%'}
			mt={6}
		>

			<Flex
				flexDirection="row"
				alignItems="center"
				justifyContent="space-around"
			>
				<Text whiteSpace="nowrap" bold w="100%" pr="2" pl="1" fontSize="md">
					Start Date
				</Text>
				<Box pr={1} />
				<Popover
					isOpen={openStartDate}
					onOpen={() => {
						setOpenStartDate(true)
					}}
					onClose={() => setOpenEndDate(false)}
					placement="bottom left"
					trigger={(triggerProps) => {
						return (
							<Button
								w="130"
								px="1"
								py="1"
								borderRadius="md"
								variant="outline"
								colorScheme="dark.50"
								leftIcon={
									<Icon
										as={MaterialIcons}
										name="calendar-today"
										size="4"
									/>
								}
								onPress={() => setOpenStartDate(!openStartDate)}
								{...triggerProps}
							>
								{startDate}
							</Button>
						);
					}}
				>
					<Popover.Content
						accessibilityLabel="Filter By Date"
						w="full"
						bg="muted.300"
					>
						<Popover.Arrow />
						<Popover.Body>
							<Calendar
								className="webCalendarPicker"
								startDate={moment(startDate, 'MM/DD/YYYY').toDate()}
								maxDate={moment(endDate, 'MM/DD/YYYY').toDate()}
								direction="vertical"
								fixedHeight
								color={primaryColors.primaryRed}
								onChange={(startDate) => {
									setStartDate(moment(startDate).format('MM/DD/YYYY'));
									setOpenStartDate(false);
								}}
							/>
						</Popover.Body>
					</Popover.Content>
				</Popover>

				<Box pr={3} />

				<Text whiteSpace="nowrap" bold w="100%" pr="2" pl="1" fontSize="md">
					End Date
				</Text>
				<Box pr={1} />
				<Popover
					isOpen={openEndDate}
					onOpen={() => {
						setOpenEndDate(true)
					}}
					onClose={() => setOpenEndDate(false)}
					placement="bottom left"
					trigger={(triggerProps) => {
						return (
							<Button
								w="130"
								px="1"
								py="1"
								borderRadius="md"
								variant="outline"
								colorScheme="dark.50"
								leftIcon={
									<Icon
										as={MaterialIcons}
										name="calendar-today"
										size="4"
									/>
								}
								onPress={() => setOpenEndDate(!openEndDate)}
								{...triggerProps}
							>
								{endDate}
							</Button>
						);
					}}
				>
					<Popover.Content
						accessibilityLabel="Filter By Date"
						w="full"
						bg="muted.300"
					>
						<Popover.Arrow />
						<Popover.Body>
							<Calendar
								className="webCalendarPicker"
								endDate={moment(endDate, 'MM/DD/YYYY').toDate()}
								direction="vertical"
								fixedHeight
								color={primaryColors.primaryRed}
								onChange={(endDate) => {
									setEndDate(moment(endDate).format('MM/DD/YYYY'));
									setOpenEndDate(false);
								}}
							/>
						</Popover.Body>
					</Popover.Content>
				</Popover>
			</Flex>

			<Button
				background={primaryColors.primaryRed}
				onPress={() => DownloadReservationDetailsReport(data)}
				leftIcon={<Icon as={MaterialIcons} name="file-download" size="5" />}
			>
				Download
			</Button>

		</Flex>

		<Box px="1" py="4" pl="2" bg="rgb(208, 208, 208)">{renderHeader(headerTitleOptions || [])}</Box>
		<Box minHeight="50">
			{
				!venuesReservationReportWithPagingLoading ? <>
					{
						venuesReservationReportWithPagingResponse && venuesReservationReportWithPagingResponse.length > 0 ? <>
							{
								venuesReservationReportWithPagingResponse.map((item, iteration) => {
									return <Box
										key={`venueReservationReport${item.venueId}_${iteration}`}
										px="1"
										pl="2"
										py="2">
										{renderRowItem(item, iteration)}
									</Box>
								}
								)
							}
						</>
							: <Box px="8" py="2" fontSize={15} display='flex' justifyContent='center' alignSelf='center'>NO DATA FOUND</Box>
					}
				</> : <SpinnerLoading />
			}
		</Box>

		{showModal == true ? (
            <ModalWrapper
			  type={type}
              IsOpen={showModal}
              closeModal={()=>{setType('');setShowModal(false)}}
			  selectedRowData={selectedRowData}
            />
          ) : null}
	</>
}

const mapStateToProps = (state) => {
	const { venueReservationReportResponse, venuesReservationReportWithPagingResponse, venuesReservationReportWithPagingLoading } = state.venue;
	return {
		venuesReservationReportWithPagingResponse,
		venuesReservationReportWithPagingLoading,
		venueReservationReport: venueReservationReportResponse || []
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		GetReservationReportbyVenue: (data) => dispatch(VenueActions.GetReservationReportbyVenue(data)).then((response) => response),
		DownloadReservationDetailsReport: (data) => dispatch(VenueActions.DownloadReservationDetailsReportForVenue(data)).then((response) => response)
	};
}

venueReservationReports.defaultProps = {
	headerTitleOptions: [
		{ key: '0_Name', label: "Name", width: "10%" , marginRight:"20%" },
		{ Key: '1_Phone Number', label: "Phone Number", width: "10%" },
		{ Key: '2_Email', label: "Email", width: "18%" },
		{ key: '3_Date', label: "Date", width: "8%" },
		{ key: '4_Time', label: "Time", width: "8%" },
		{ key: '5_Table', label: "Table", width: "10%" },
		{ key: '6_Guests', label: "# Guests", width: "7%" },
		{ key: '7_Minimum', label: "Minimum", width: "8%" },
		{ key: '8_OrderTotal', label: "Order Total", width: "8%", marginRight:"120px",position:"relative",right:"25px" },
		{ key: '9_Actions', label: "", width: "8%", },
	]
}


venueReservationReports.propTypes = {
	headerTitleOptions: PropTypes.any,
	venuesReservationReportWithPagingResponse: PropTypes.any,
	venuesReservationReportWithPagingLoading: PropTypes.any,
	GetReservationReportWithPaging: PropTypes.func,
	venueReservationReports: PropTypes.any
};

export default connect(mapStateToProps, mapDispatchToProps)(venueReservationReports);