import React, { useState, useRef } from 'react';
import { Modal, Center } from 'native-base';
import PropTypes from 'prop-types';

// Components
import AddOrEditUsers from '../web/pages/users/addOrEditUsers';
import AddOrEditProductCategory from '../web/pages/venues/addOrEditProductCategory';
import AddOrEditProduct from '../web/pages/venues/addOrEditProductModal';
import ViewTransactionDetails from './pages/venues/viewTransactionDetails';

const ModalWrapper = (props) => {
  const { Id, modalType, IsOpen, handleClose, venueid, type, CatId, productId, HandleToast, createForUserType, closeModal } = props;
  const [showModal, setShowModal] = useState(IsOpen);

  let UserId = Id;
  let UserVenueId = venueid;

  const childRef = useRef(null)

  const toggleResetModal = () => {
    childRef.current?.handleReset();
    setShowModal(false)
    if(type === "view_transaction"){
      closeModal();
    }
  }

  return <Center>
    <Modal
      isOpen={showModal}
      size="lg"
      onClose={() => toggleResetModal()}
      _backdrop={{
        bg: "muted.600"
      }}
    >
      {
        type == 'productcategory' ?
          <AddOrEditProductCategory
            CategoryId={CatId}
            venueid={UserVenueId}
            Id={UserId}
            modalType={modalType}
            handleClose={(status, type) => handleClose(status, type)}
          />
          :
          type == 'product' ?
            <AddOrEditProduct
              CatId={CatId}
              ProductId={productId}
              venueid={UserVenueId}
              Id={UserId}
              modalType={modalType}
              handleClose={(status, type) => handleClose(status, type)}
            />
            :
            type == 'view_transaction'?
            <ViewTransactionDetails
              rowData={props.selectedRowData}
            />
            :
            <AddOrEditUsers
              venueid={UserVenueId}
              Id={UserId}
              modalType={modalType}
              ref={childRef}
              IsOpen={IsOpen}
              createForUserType={createForUserType}
              handleClose={(status, type) => handleClose(status, type)}
              HandleToast={(msg, bg) => typeof HandleToast === 'function' && HandleToast(msg, bg)}
            />
      }

    </Modal>
  </Center>;
};

ModalWrapper.propTypes = {
  Id: PropTypes.any,
  IsOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  modalType: PropTypes.string,
  HandleToast: PropTypes.any,
  createForUserType: PropTypes.any,
  closeModal: PropTypes.any
}

export default ModalWrapper;