// Redux :: Action Types
import {
  ACTIVITY_IDLE,
  ACTIVITY_PENDING,
  ACTIVITY_FAILED,
  ACTIVITY_SUCCEEDED,
  ACTIVITY_SUCCEEDED_BY_GUEST,
  ACTIVITY_SUCCEEDED_BY_RESERVATION,
  // Insert or update activity 
  INSERT_OR_UPDATE_ACTIVITY_IDLE,
  INSERT_OR_UPDATE_ACTIVITY_PENDING,
  INSERT_OR_UPDATE_ACTIVITY_FAILED,
  INSERT_OR_UPDATE_ACTIVITY_SUCCEEDED,
} from '../constants';

// ** static data list ** //
import { datalistByGuest } from '../../utils/constants'

/* ----- INITIAL STATE ----- */
const initialState = {
  loading: false,
  idleErr: null,
  activityErr: null,
  activityResponse: [],
  activityResponseByGuest: [],
  activityResponseByReservation: []
}

/* ----- REDUCER ----- */
const activityReducer = (state = initialState, action) => {
  switch (action.type) {

    case ACTIVITY_PENDING:
      return {
        ...state,
        loading: true,
        activityResponse: action.payload ?  state.activityResponse : []
      };
    case ACTIVITY_IDLE:
      return {
        ...state,
        loading: false,
        idleErr: action.payload
      };
    case ACTIVITY_SUCCEEDED:
      return {
        ...state,
        loading: false,
        activityResponse: action.payload
      };
    case ACTIVITY_SUCCEEDED_BY_GUEST:
      return {
        ...state,
        loading: false,
        activityResponseByGuest: action.payload
      };
    case ACTIVITY_SUCCEEDED_BY_RESERVATION: 
    return {
      ...state,
      loading: false,
      activityResponseByReservation: action.payload
    };

    case ACTIVITY_FAILED:
      return {
        ...state,
        loading: false,
        activityErr: action.payload
      };

    default:
      return state;

  }
}


/* ----- INSERT OR UPDATE ACTIVITY INITIAL STATE ----- */
const InsertOrUpdateInitialState = {
  loading: false,
  updateIdleErr: null,
  updateActivityErr: null,
  updateActivityResponse: [],
}


/* ----- INSERT OR UPDATE REDUCER ----- */
const insertOrUpdateActivityReducer = (state = InsertOrUpdateInitialState, action) => {
  switch (action.type) {

    case INSERT_OR_UPDATE_ACTIVITY_PENDING:
      return {
        ...state,
        loading: true,
        updateActivityResponse: []
      };
    case INSERT_OR_UPDATE_ACTIVITY_IDLE:
      return {
        ...state,
        loading: false,
        updateIdleErr: action.payload
      };
    case INSERT_OR_UPDATE_ACTIVITY_SUCCEEDED:
      return {
        ...state,
        loading: false,
        updateActivityResponse: action.payload
      };

    case INSERT_OR_UPDATE_ACTIVITY_FAILED:
      return {
        ...state,
        loading: false,
        updateActivityErr: action.payload
      };

    default:
      return state;

  }
}

export {
  activityReducer,
  insertOrUpdateActivityReducer
}
