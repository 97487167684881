// Common
const APP_LOADING = "APP_LOADING";
const CURRENT_ROUTE_NAME = "CURRENT_ROUTE_NAME";
const CURRENT_SELECTED_ROUTE = "CURRENT_SELECTED_ROUTE";
const NOTIFICATION_ROUTE = "NOTIFICATION_ROUTE";

// Auth
const LOGIN_IDLE = "LOGIN_IDLE";
const LOGIN_PENDING = "LOGIN_PENDING";
const LOGIN_FAILED = "LOGIN_FAILED";
const LOGIN_SUCCEEDED = "LOGIN_SUCCEEDED";
const RESET_LOGIN_GROUP = "RESET_LOGIN_GROUP";

// Signup
const SIGNUP_IDLE = "SIGNUP_IDLE";
const SIGNUP_PENDING = "SIGNUP_PENDING";
const SIGNUP_FAILED = "SIGNUP_FAILED";
const SIGNUP_SUCCEEDED = "SIGNUP_SUCCEEDED";

// Forgot password
const FORGOT_PASSWORD_IDLE = "FORGOT_PASSWORD_IDLE";
const FORGOT_PASSWORD_PENDING = "FORGOT_PASSWORD_PENDING";
const FORGOT_PASSWORD_FAILED = "FORGOT_PASSWORD_FAILED";
const FORGOT_PASSWORD_SUCCEEDED = "FORGOT_PASSWORD_SUCCEEDED";

// OTP
const OTP_IDLE = "OTP_IDLE";
const OTP_PENDING = "OTP_PENDING";
const OTP_FAILED = "OTP_FAILED";
const OTP_SUCCEEDED = "OTP_SUCCEEDED";

// Checkin Reservation
const RESERVATION_CHECKIN_IDLE = "RESERVATION_CHECKIN_IDLE";
const RESERVATION_CHECKIN_PENDING = "RESERVATION_CHECKIN_PENDING";
const RESERVATION_CHECKIN_FAILED = "RESERVATION_CHECKIN_FAILED";
const RESERVATION_CHECKIN_SUCCEEDED = "RESERVATION_CHECKIN_SUCCEEDED";

// Reservation
const RESERVATION_IDLE = "RESERVATION_IDLE";
const RESERVATION_PENDING = "RESERVATION_PENDING";
const RESERVATION_FAILED = "RESERVATION_FAILED";
const RESERVATION_SUCCEEDED = "RESERVATION_SUCCEEDED";
const SET_RESERVATION_FILTER_STATE = "SET_RESERVATION_FILTER_STATE";

//Filter reservation for venue online reservation screen 
const FILTER_WIDGET_RESERVATION_IDLE = "FILTER_WIDGET_RESERVATION_IDLE";
const FILTER_WIDGET_RESERVATION_PENDING = "FILTER_WIDGET_RESERVATION_PENDING";
const FILTER_WIDGET_RESERVATION_FAILED = "FILTER_WIDGET_RESERVATION_FAILED";
const FILTER_WIDGET_RESERVATION_SUCCEEDED = "FILTER_WIDGET_RESERVATION_SUCCEEDED";
const SET_FILTER_WIDGET_RESERVATION_FILTER_STATE = "SET_FILTER_WIDGET_RESERVATION_FILTER_STATE";

//Header Filter reservation for venue online reservation screen 
const HEADER_FILTER_WIDGET_RESERVATION_IDLE = "HEADER_FILTER_WIDGET_RESERVATION_IDLE";
const HEADER_FILTER_WIDGET_RESERVATION_PENDING = "HEADER_FILTER_WIDGET_RESERVATION_PENDING";
const HEADER_FILTER_WIDGET_RESERVATION_FAILED = "HEADER_FILTER_WIDGET_RESERVATION_FAILED";
const HEADER_FILTER_WIDGET_RESERVATION_SUCCEEDED = "HEADER_FILTER_WIDGET_RESERVATION_SUCCEEDED";

// Web Reservation
const GET_WEB_RESERVATION_BY_VENUE_ID_IDLE = "GET_WEB_RESERVATION_BY_VENUE_ID_IDLE";
const GET_WEB_RESERVATION_BY_VENUE_ID_PENDING = "GET_WEB_RESERVATION_BY_VENUE_ID_PENDING";
const GET_WEB_RESERVATION_BY_VENUE_ID_FAILED = "GET_WEB_RESERVATION_BY_VENUE_ID_FAILED";
const GET_WEB_RESERVATION_BY_VENUE_ID_SUCCEEDED = "GET_WEB_RESERVATION_BY_VENUE_ID_SUCCEEDED";

// Reservation detail
const RESERVATION_DETAIL_IDLE = "RESERVATION_DETAIL_IDLE";
const RESERVATION_DETAIL_PENDING = "RESERVATION_DETAIL_PENDING";
const RESERVATION_DETAIL_FAILED = "RESERVATION_DETAIL_FAILED";
const RESERVATION_DETAIL_SUCCEEDED = "RESERVATION_DETAIL_SUCCEEDED";

// Create Reservation
const CREATE_RESERVATION_IDLE = "CREATE_RESERVATION_IDLE";
const CREATE_RESERVATION_PENDING = "CREATE_RESERVATION_PENDING";
const CREATE_RESERVATION_FAILED = "CREATE_RESERVATION_FAILED";
const CREATE_RESERVATION_SUCCEEDED = "CREATE_RESERVATION_SUCCEEDED";

//Search user or get user detail
const GET_USER_DETAIL_IDLE = "GET_USER_DETAIL_IDLE";
const GET_USER_DETAIL_PENDING = "GET_USER_DETAIL_PENDING";
const GET_USER_DETAIL_FAILED = "GET_USER_DETAIL_FAILED";
const GET_USER_DETAIL_SUCCEEDED = "GET_USER_DETAIL_SUCCEEDED";

// Cancel Reservation 
const RESERVATION_CANCEL_IDLE = "RESERVATION_CANCEL_IDLE";
const RESERVATION_CANCEL_PENDING = "RESERVATION_CANCEL_PENDING";
const RESERVATION_CANCEL_FAILED = "RESERVATION_CANCEL_FAILED";
const RESERVATION_CANCEL_SUCCEEDED = "RESERVATION_CANCEL_SUCCEEDED";

// Accept Reservation 
const RESERVATION_ACCEPT_IDLE = "RESERVATION_ACCEPT_IDLE";
const RESERVATION_ACCEPT_PENDING = "RESERVATION_ACCEPT_PENDING";
const RESERVATION_ACCEPT_FAILED = "RESERVATION_ACCEPT_FAILED";
const RESERVATION_ACCEPT_SUCCEEDED = "RESERVATION_ACCEPT_SUCCEEDED";

// Table Detail for Reservation 
const GET_TABLE_DETAIL_IDLE = "GET_TABLE_DETAIL_IDLE";
const GET_TABLE_DETAIL_PENDING = "GET_TABLE_DETAIL_PENDING";
const GET_TABLE_DETAIL_FAILED = "GET_TABLE_DETAIL_FAILED";
const GET_TABLE_DETAIL_SUCCEEDED = "GET_TABLE_DETAIL_SUCCEEDED";

// End QUEUE
const GET_QUEUE_RESERVATION_IDLE = "GET_QUEUE_RESERVATION_IDLE";
const GET_QUEUE_RESERVATION_PENDING = "GET_QUEUE_RESERVATION_PENDING";
const GET_QUEUE_RESERVATION_FAILED = "GET_QUEUE_RESERVATION_FAILED";
const GET_QUEUE_RESERVATION_SUCCEEDED = "GET_QUEUE_RESERVATION_SUCCEEDED";

// Venue QUEUE
const GET_VENUE_RESERVATIONS_QUEUE_IDLE = "GET_VENUE_RESERVATIONS_QUEUE_IDLE";
const GET_VENUE_RESERVATIONS_QUEUE_PENDING = "GET_VENUE_RESERVATIONS_QUEUE_PENDING";
const GET_VENUE_RESERVATIONS_QUEUE_FAILED = "GET_VENUE_RESERVATIONS_QUEUE_FAILED";
const GET_VENUE_RESERVATIONS_QUEUE_SUCCEEDED = "GET_VENUE_RESERVATIONS_QUEUE_SUCCEEDED";

// TOGGLE VENUE STATE
const TOGGLE_VENUE_STATE_IDLE = "TOGGLE_VENUE_STATE_IDLE";
const TOGGLE_VENUE_STATE_PENDING = "TOGGLE_VENUE_STATE_PENDING";
const TOGGLE_VENUE_STATE_FAILED = "TOGGLE_VENUE_STATE_FAILED";
const TOGGLE_VENUE_STATE_SUCCEEDED = "TOGGLE_VENUE_STATE_SUCCEEDED";

// QUEUE PRODUCT
const GET_QUEUE_RESERVATION_PRODUCT_IDLE = "GET_QUEUE_RESERVATION_PRODUCT_IDLE";
const GET_QUEUE_RESERVATION_PRODUCT_PENDING = "GET_QUEUE_RESERVATION_PRODUCT_PENDING";
const GET_QUEUE_RESERVATION_PRODUCT_FAILED = "GET_QUEUE_RESERVATION_PRODUCT_FAILED";
const GET_QUEUE_RESERVATION_PRODUCT_SUCCEEDED = "GET_QUEUE_RESERVATION_PRODUCT_SUCCEEDED";

// QUEUE SUB PRODUCT
const GET_QUEUE_RESERVATION_SUB_PRODUCT_IDLE = "GET_QUEUE_RESERVATION_SUB_PRODUCT_IDLE";
const GET_QUEUE_RESERVATION_SUB_PRODUCT_PENDING = "GET_QUEUE_RESERVATION_SUB_PRODUCT_PENDING";
const GET_QUEUE_RESERVATION_SUB_PRODUCT_FAILED = "GET_QUEUE_RESERVATION_SUB_PRODUCT_FAILED";
const GET_QUEUE_RESERVATION_SUB_PRODUCT_SUCCEEDED = "GET_QUEUE_RESERVATION_SUB_PRODUCT_SUCCEEDED";

// QUEUE STATE(Requeset server/Request Manager/Close Check)
const UPDATE_QUEUE_RESERVATION_STATE_IDLE = "UPDATE_QUEUE_RESERVATION_STATE_IDLE";
const UPDATE_QUEUE_RESERVATION_STATE_PENDING = "UPDATE_QUEUE_RESERVATION_STATE_PENDING";
const UPDATE_QUEUE_RESERVATION_STATE_FAILED = "UPDATE_QUEUE_RESERVATION_STATE_FAILED";
const UPDATE_QUEUE_RESERVATION_STATE_SUCCEEDED = "UPDATE_QUEUE_RESERVATION_STATE_SUCCEEDED";

// Reset password
const RESET_PASSWORD_IDLE = "RESET_PASSWORD_IDLE";
const RESET_PASSWORD_PENDING = "RESET_PASSWORD_PENDING";
const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";
const RESET_PASSWORD_SUCCEEDED = "RESET_PASSWORD_SUCCEEDED";

//Venue Detail
const VENUE_IDLE = "VENUE_IDLE";
const VENUE_PENDING = "VENUE_PENDING";
const VENUE_FAILED = "VENUE_FAILED";
const VENUE_SUCCEEDED = "VENUE_SUCCEEDED";

//User Detail
const GET_USER_DETAIL = "GET_USER_DETAIL";
const GET_ALL_USERS_IDLE = "GET_ALL_USERS_IDLE";
const GET_ALL_USERS_PENDING = "GET_ALL_USERS_PENDING";
const GET_ALL_USERS_FAILED = "GET_ALL_USERS_FAILED";
const GET_ALL_USERS_SUCCEEDED = "GET_ALL_USERS_SUCCEEDED";

// Delete User Detail
const DELETE_USERS_IDLE = "DELETE_USERS_IDLE";
const DELETE_USERS_PENDING = "DELETE_USERS_PENDING";
const DELETE_USERS_FAILED = "DELETE_USERS_FAILED";
const DELETE_USERS_SUCCEEDED = "DELETE_USERS_SUCCEEDED";

// Delete table details
const DELETE_VENUE_TABLE_DETAIL_PENDING = " DELETE_VENUE_TABLE_DETAIL_PENDING";
const DELETE_VENUE_TABLE_DETAIL_IDLE = " DELETE_VENUE_TABLE_DETAIL_IDLE;"
const DELETE_VENUE_TABLE_DETAIL_FAILED = "DELETE_VENUE_TABLE_DETAIL_FAILED"
const DELETE_VENUE_TABLE_DETAIL_SUCCEEDED = "DELETE_VENUE_TABLE_DETAIL_SUCCEEDED"

// Delete venue user 
const DELETE_VENUE_USER_IDLE = "DELETE_VENUE_USER_IDLE";
const DELETE_VENUE_USER_PENDING = "DELETE_VENUE_USER_PENDING";
const DELETE_VENUE_USER_FAILED = "DELETE_VENUE_USER_FAILED";
const DELETE_VENUE_USER_SUCCEEDED = "DELETE_VENUE_USER_SUCCEEDED";
//Venue user detail
const GET_VENUE_USERS_IDLE = "GET_VENUE_USERS_IDLE";
const GET_VENUE_USERS_PENDING = "GET_VENUE_USERS_PENDING";
const GET_VENUE_USERS_FAILED = "GET_VENUE_USERS_FAILED";
const GET_VENUE_USERS_SUCCEEDED = "GET_VENUE_USERS_SUCCEEDED";

//Update Profile
const UPDATE_PROFILE_IDLE = "UPDATE_PROFILE_IDLE";
const UPDATE_PROFILE_PENDING = "UPDATE_PROFILE_PENDING";
const UPDATE_PROFILE_FAILED = "UPDATE_PROFILE_FAILED";
const UPDATE_PROFILE_SUCCEEDED = "UPDATE_PROFILE_SUCCEEDED";

//Venue Detail View
const VENUE_VIEW_IDLE = "VENUE_VIEW_IDLE";
const VENUE_VIEW_PENDING = "VENUE_VIEW_PENDING";
const VENUE_VIEW_FAILED = "VENUE_VIEW_FAILED";
const VENUE_VIEW_SUCCEEDED = "VENUE_VIEW_SUCCEEDED";

const GET_ALL_VENUE_IDLE = "GET_ALL_VENUE_IDLE";
const GET_ALL_VENUE_PENDING = "GET_ALL_VENUE_PENDING";
const GET_ALL_VENUE_FAILED = "GET_ALL_VENUE_FAILED";
const GET_ALL_VENUE_SUCCEEDED = "GET_ALL_VENUE_SUCCEEDED";

// Product
const PRODUCT_IDLE = "PRODUCT_IDLE";
const PRODUCT_PENDING = "PRODUCT_PENDING";
const PRODUCT_FAILED = "PRODUCT_FAILED";
const PRODUCT_SUCCEEDED = "PRODUCT_SUCCEEDED";
const PRODUCT_CATEGORY_IDLE = "PRODUCT_CATEGORY_IDLE";
const PRODUCT_CATEGORY_PENDING = "PRODUCT_CATEGORY_PENDING";
const PRODUCT_CATEGORY_FAILED = "PRODUCT_CATEGORY_FAILED";
const PRODUCT_CATEGORY_SUCCEEDED = "PRODUCT_CATEGORY_SUCCEEDED";

// Product category
const PRODUCT_CATEGORY_BY_VENUE_ID_IDLE = "PRODUCT_CATEGORY_BY_VENUE_ID_IDLE";
const PRODUCT_CATEGORY_BY_VENUE_ID_PENDING = "PRODUCT_CATEGORY_BY_VENUE_ID_PENDING";
const PRODUCT_CATEGORY_BY_VENUE_ID_FAILED = "PRODUCT_CATEGORY_BY_VENUE_ID_FAILED";
const PRODUCT_CATEGORY_BY_VENUE_ID_SUCCEEDED = "PRODUCT_CATEGORY_BY_VENUE_ID_SUCCEEDED";

const INSERT_UPDATE_PRODUCT_CATEGORY_IDLE = "INSERT_UPDATE_PRODUCT_CATEGORY_IDLE";
const INSERT_UPDATE_PRODUCT_CATEGORY_PENDING = "INSERT_UPDATE_PRODUCT_CATEGORY_PENDING";
const INSERT_UPDATE_PRODUCT_CATEGORY_FAILED = "INSERT_UPDATE_PRODUCT_CATEGORY_FAILED";
const INSERT_UPDATE_PRODUCT_CATEGORY_SUCCEEDED = "INSERT_UPDATE_PRODUCT_CATEGORY_SUCCEEDED";

const INSERT_UPDATE_PRODUCT_IDLE = "INSERT_UPDATE_PRODUCT_IDLE";
const INSERT_UPDATE_PRODUCT_PENDING = "INSERT_UPDATE_PRODUCT_PENDING";
const INSERT_UPDATE_PRODUCT_FAILED = "INSERT_UPDATE_PRODUCT_FAILED";
const INSERT_UPDATE_PRODUCT_SUCCEEDED = "INSERT_UPDATE_PRODUCT_SUCCEEDED";

// Order item
const GET_ORDER_ITEMS_BY_RESERVATION_ID_IDLE = "GET_ORDER_ITEMS_BY_RESERVATION_ID_IDLE";
const GET_ORDER_ITEMS_BY_RESERVATION_ID_PENDING = "GET_ORDER_ITEMS_BY_RESERVATION_ID_PENDING";
const GET_ORDER_ITEMS_BY_RESERVATION_ID_FAILED = "GET_ORDER_ITEMS_BY_RESERVATION_ID_FAILED";
const GET_ORDER_ITEMS_BY_RESERVATION_ID_SUCCEEDED = "GET_ORDER_ITEMS_BY_RESERVATION_ID_SUCCEEDED";

// Insert Order item
const INSERT_ORDER_BY_RESERVATION_ID_IDLE = "INSERT_ORDER_BY_RESERVATION_ID_IDLE";
const INSERT_ORDER_BY_RESERVATION_ID_PENDING = "INSERT_ORDER_BY_RESERVATION_ID_PENDING";
const INSERT_ORDER_BY_RESERVATION_ID_FAILED = "INSERT_ORDER_BY_RESERVATION_ID_FAILED";
const INSERT_ORDER_BY_RESERVATION_ID_SUCCEEDED = "INSERT_ORDER_BY_RESERVATION_ID_SUCCEEDED";

// Delete Order item
const DELETE_ORDER_ITEMS_IDLE = "DELETE_ORDER_ITEMS_IDLE";
const DELETE_ORDER_ITEMS_PENDING = "DELETE_ORDER_ITEMS_PENDING";
const DELETE_ORDER_ITEMS_FAILED = "DELETE_ORDER_ITEMS_FAILED";
const DELETE_ORDER_ITEMS_SUCCEEDED = "DELETE_ORDER_ITEMS_SUCCEEDED";

// Send Order item
const SEND_ORDER_ITEMS_IDLE = "SEND_ORDER_ITEMS_IDLE";
const SEND_ORDER_ITEMS_PENDING = "SEND_ORDER_ITEMS_PENDING";
const SEND_ORDER_ITEMS_FAILED = "SEND_ORDER_ITEMS_FAILED";
const SEND_ORDER_ITEMS_SUCCEEDED = "SEND_ORDER_ITEMS_SUCCEEDED";

// Activity
const ACTIVITY_IDLE = "ACTIVITY_IDLE";
const ACTIVITY_PENDING = "ACTIVITY_PENDING";
const ACTIVITY_FAILED = "ACTIVITY_FAILED";
const ACTIVITY_SUCCEEDED = "ACTIVITY_SUCCEEDED";

// Activity BY GUEST - Venue 
const ACTIVITY_SUCCEEDED_BY_GUEST = "ACTIVITY_SUCCEEDED_BY_GUEST";

// Activity By Reservation - End User
const ACTIVITY_SUCCEEDED_BY_RESERVATION = "ACTIVITY_SUCCEEDED_BY_RESERVATION";

// Insert or update Activity
const INSERT_OR_UPDATE_ACTIVITY_IDLE = " INSERT_OR_UPDATE_ACTIVITY_IDLE";
const INSERT_OR_UPDATE_ACTIVITY_PENDING = " INSERT_OR_UPDATE_ACTIVITY_PENDING";
const INSERT_OR_UPDATE_ACTIVITY_FAILED = " INSERT_OR_UPDATE_ACTIVITY_FAILED";
const INSERT_OR_UPDATE_ACTIVITY_SUCCEEDED = " INSERT_OR_UPDATE_ACTIVITY_SUCCEEDED"

// Others
const COUNTER_CHANGE = "COUNTER_CHANGE";

// Report
const GET_VENUE_CACHE_REPORT_BY_DATE_IDLE = "GET_VENUE_CACHE_REPORT_BY_DATE_IDLE";
const GET_VENUE_CACHE_REPORT_BY_DATE_PENDING = "GET_VENUE_CACHE_REPORT_BY_DATE_PENDING";
const GET_VENUE_CACHE_REPORT_BY_DATE_FAILED = "GET_VENUE_CACHE_REPORT_BY_DATE_FAILED";
const GET_VENUE_CACHE_REPORT_BY_DATE_SUCCEEDED = "GET_VENUE_CACHE_REPORT_BY_DATE_SUCCEEDED";

// ** Cache Report ** //
const GET_CACHE_REPORT_BY_DATE_IDLE = "GET_CACHE_REPORT_BY_DATE_IDLE";
const GET_CACHE_REPORT_BY_DATE_PENDING = "GET_CACHE_REPORT_BY_DATE_PENDING";
const GET_CACHE_REPORT_BY_DATE_FAILED = "GET_CACHE_REPORT_BY_DATE_FAILED";
const GET_CACHE_REPORT_BY_DATE_SUCCEEDED = "GET_CACHE_REPORT_BY_DATE_SUCCEEDED";

// Venue Users
const GET_VENUE_USERS_BY_VENUE_ID_IDLE = "GET_VENUE_USERS_BY_VENUE_ID_IDLE";
const GET_VENUE_USERS_BY_VENUE_ID_PENDING = "GET_VENUE_USERS_BY_VENUE_ID_PENDING";
const GET_VENUE_USERS_BY_VENUE_ID_FAILED = "GET_VENUE_USERS_BY_VENUE_ID_FAILED";
const GET_VENUE_USERS_BY_VENUE_ID_SUCCEEDED = "GET_VENUE_USERS_BY_VENUE_ID_SUCCEEDED";

// Get Venue Settings By Day
const GET_VENUE_SETTING_BY_DAY_IDLE = "GET_VENUE_SETTING_BY_DAY_IDLE";
const GET_VENUE_SETTING_BY_DAY_PENDING = "GET_VENUE_SETTING_BY_DAY_PENDING";
const GET_VENUE_SETTING_BY_DAY_FAILED = "GET_VENUE_SETTING_BY_DAY_FAILED";
const GET_VENUE_SETTING_BY_DAY_SUCCEEDED = "GET_VENUE_SETTING_BY_DAY_SUCCEEDED";

// Venue Detail
const GET_VENUE_DETAIL_BY_VENUE_ID_IDLE = "GET_VENUE_DETAIL_BY_VENUE_ID_IDLE";
const GET_VENUE_DETAIL_BY_VENUE_ID_PENDING = "GET_VENUE_DETAIL_BY_VENUE_ID_PENDING";
const GET_VENUE_DETAIL_BY_VENUE_ID_FAILED = "GET_VENUE_DETAIL_BY_VENUE_ID_FAILED";
const GET_VENUE_DETAIL_BY_VENUE_ID_SUCCEEDED = "GET_VENUE_DETAIL_BY_VENUE_ID_SUCCEEDED";

// Update Venue Floor Settings
const UPDATE_VENUE_FLOOR_SETTING_IDLE = "UPDATE_VENUE_FLOOR_SETTING_IDLE";
const UPDATE_VENUE_FLOOR_SETTING_PENDING = "UPDATE_VENUE_FLOOR_SETTING_PENDING";
const UPDATE_VENUE_FLOOR_SETTING_FAILED = "UPDATE_VENUE_FLOOR_SETTING_FAILED";
const UPDATE_VENUE_FLOOR_SETTING_SUCCEEDED = "UPDATE_VENUE_FLOOR_SETTING_SUCCEEDED";

// City
const FETCH_ALL_CITY_IDLE = "FETCH_ALL_CITY_IDLE";
const FETCH_ALL_CITY_PENDING = "FETCH_ALL_CITY_PENDING";
const FETCH_ALL_CITY_FAILED = "FETCH_ALL_CITY_FAILED";
const FETCH_ALL_CITY_SUCCEEDED = "FETCH_ALL_CITY_SUCCEEDED";

// State
const FETCH_ALL_STATE_IDLE = "FETCH_ALL_STATE_IDLE";
const FETCH_ALL_STATE_PENDING = "FETCH_ALL_STATE_PENDING";
const FETCH_ALL_STATE_FAILED = "FETCH_ALL_STATE_FAILED";
const FETCH_ALL_STATE_SUCCEEDED = "FETCH_ALL_STATE_SUCCEEDED";

// Country
const FETCH_ALL_COUNTRY_IDLE = "FETCH_ALL_COUNTRY_IDLE";
const FETCH_ALL_COUNTRY_PENDING = "FETCH_ALL_COUNTRY_PENDING";
const FETCH_ALL_COUNTRY_FAILED = "FETCH_ALL_COUNTRY_FAILED";
const FETCH_ALL_COUNTRY_SUCCEEDED = "FETCH_ALL_COUNTRY_SUCCEEDED";

// Venue Reservation Detail
const GET_VENUE_RESERVATION_DETAIL_BY_VENUE_ID_IDLE = "GET_VENUE_RESERVATION_DETAIL_BY_VENUE_ID_IDLE";
const GET_VENUE_RESERVATION_DETAIL_BY_VENUE_ID_PENDING = "GET_VENUE_RESERVATION_DETAIL_BY_VENUE_ID_PENDING";
const GET_VENUE_RESERVATION_DETAIL_BY_VENUE_ID_FAILED = "GET_VENUE_RESERVATION_DETAIL_BY_VENUE_ID_FAILED";
const GET_VENUE_RESERVATION_DETAIL_BY_VENUE_ID_SUCCEEDED = "GET_VENUE_RESERVATION_DETAIL_BY_VENUE_ID_SUCCEEDED";

//Get User details
const GET_USERS_BY_ID_IDLE = "GET_USERS_BY_ID_IDLE";
const GET_USERS_BY_ID_PENDING = "GET_USERS_BY_ID_PENDING";
const GET_USERS_BY_ID_FAILED = "GET_USERS_BY_ID_FAILED";
const GET_USERS_BY_ID_SUCCEEDED = "GET_USERS_BY_ID_SUCCEEDED";

//Post user details
const POST_USERS_BY_ID_IDLE = "GET_USERS_BY_ID_IDLE";
const POST_USERS_BY_ID_PENDING = "GET_USERS_BY_ID_PENDING";
const POST_USERS_BY_ID_FAILED = "GET_USERS_BY_ID_FAILED";
const POST_USERS_BY_ID_SUCCEEDED = "GET_USERS_BY_ID_SUCCEEDED";

//Get Table details
const GET_VENUE_TABLE_BY_TABLE_ID_IDLE = "GET_VENUE_TABLE_BY_TABLE_ID_IDLE";
const GET_VENUE_TABLE_BY_VENUE_ID_PENDING = "GET_VENUE_TABLE_BY_VENUE_ID_PENDING";
const GET_VENUE_TABLE_BY_VENUE_ID_FAILED = "GET_VENUE_TABLE_BY_VENUE_ID_FAILED";
const GET_VENUE_TABLE_BY_VENUE_ID_SUCCEEDED = "GET_VENUE_TABLE_BY_VENUE_ID_SUCCEEDED";

// Get Block Dates
const GET_VENUE_BLOCK_DATES_ID_IDLE = "GET_VENUE_BLOCK_DATES_ID_IDLE";
const GET_VENUE_BLOCK_DATES_ID_PENDING = "GET_VENUE_BLOCK_DATES_ID_PENDING";
const GET_VENUE_BLOCK_DATES_ID_FAILED = "GET_VENUE_BLOCK_DATES_ID_FAILED";
const GET_VENUE_BLOCK_DATES_ID_SUCCEEDED = "GET_VENUE_BLOCK_DATES_ID_SUCCEEDED";

// Get Venue Premium Date Range
const GET_VENUE_PREMIUM_DATE_RANGE_ID_IDLE = "GET_VENUE_PREMIUM_DATE_RANGE_ID_IDLE";
const GET_VENUE_PREMIUM_DATE_RANGE_ID_PENDING = "GET_VENUE_PREMIUM_DATE_RANGE_ID_PENDING";
const GET_VENUE_PREMIUM_DATE_RANGE_ID_FAILED = "GET_VENUE_PREMIUM_DATE_RANGE_ID_FAILED";
const GET_VENUE_PREMIUM_DATE_RANGE_ID_SUCCEEDED = "GET_VENUE_PREMIUM_DATE_RANGE_ID_SUCCEEDED";

//Get Product Category
const GET_PRODUCT_CATEGORY_BY_VENUE_ID_IDLE = "GET_PRODUCT_CATEGORY_BY_VENUE_ID_IDLE";
const GET_PRODUCT_CATEGORY_BY_VENUE_ID_PENDING = "GET_PRODUCT_CATEGORY_BY_VENUE_ID_PENDING";
const GET_PRODUCT_CATEGORY_BY_VENUE_ID_FAILED = "GET_PRODUCT_CATEGORY_BY_VENUE_ID_FAILED";
const GET_PRODUCT_CATEGORY_BY_VENUE_ID_SUCCEEDED = "GET_PRODUCT_CATEGORY_BY_VENUE_ID_SUCCEEDED";

//** Get current date by venue id */
const GET_CURRENT_DATE_BY_VENUE_ID_IDLE = "GET_CURRENT_DATE_BY_VENUE_ID_IDLE";
const GET_CURRENT_DATE_BY_VENUE_ID_PENDING = "GET_CURRENT_DATE_BY_VENUE_ID_PENDING";
const GET_CURRENT_DATE_BY_VENUE_ID_FAILED = "GET_CURRENT_DATE_BY_VENUE_ID_FAILED";
const GET_CURRENT_DATE_BY_VENUE_ID_SUCCEEDED = "GET_CURRENT_DATE_BY_VENUE_ID_SUCCEEDED";

// Table Detail
const GET_TABLE_DETAIL_BY_ID_IDLE = "GET_TABLE_DETAIL_BY_ID_IDLE";
const GET_TABLE_DETAIL_BY_ID_PENDING = "GET_TABLE_DETAIL_BY_ID_PENDING";
const GET_TABLE_DETAIL_BY_ID_FAILED = "GET_TABLE_DETAIL_BY_ID_FAILED";
const GET_TABLE_DETAIL_BY_ID_SUCCEEDED = "GET_TABLE_DETAIL_BY_ID_SUCCEEDED";

// Get Floor List
const GET_FLOOR_LIST_BY_VENUE_ID_IDLE = "GET_FLOOR_LIST_BY_VENUE_ID_IDLE";
const GET_FLOOR_LIST_BY_VENUE_ID_PENDING = "GET_FLOOR_LIST_BY_VENUE_ID_PENDING";
const GET_FLOOR_LIST_BY_VENUE_ID_FAILED = "GET_FLOOR_LIST_BY_VENUE_ID_FAILED";
const GET_FLOOR_LIST_BY_VENUE_ID_SUCCEEDED = "GET_FLOOR_LIST_BY_VENUE_ID_SUCCEEDED";

const SELECTED_FLOOR = "SELECTED_FLOOR";

//Product Category by Id
const GET_PRODUCT_CATEGORY_BY_ID_IDLE = "GET_PRODUCT_CATEGORY_BY_ID_IDLE";
const GET_PRODUCT_CATEGORY_BY_ID_PENDING = "GET_PRODUCT_CATEGORY_BY_ID_PENDING";
const GET_PRODUCT_CATEGORY_BY_ID_FAILED = "GET_PRODUCT_CATEGORY_BY_ID_FAILED";
const GET_PRODUCT_CATEGORY_BY_ID_SUCCEEDED = "GET_PRODUCT_CATEGORY_BY_ID_SUCCEEDED";

// Product  (By  ID)
const PRODUCT_BY_ID_IDLE = "PRODUCT_BY_ID_IDLE";
const PRODUCT_BY_ID_PENDING = "PRODUCT_BY_ID_PENDING";
const PRODUCT_BY_ID_FAILED = "PRODUCT_BY_ID_FAILED";
const PRODUCT_BY_ID_SUCCEEDED = "PRODUCT_BY_ID_SUCCEEDED";

// Product  (By Category ID)
const PRODUCT_BY_CATEGORY_ID_IDLE = "PRODUCT_BY_CATEGORY_ID_IDLE";
const PRODUCT_BY_CATEGORY_ID_PENDING = "PRODUCT_BY_CATEGORY_ID_PENDING";
const PRODUCT_BY_CATEGORY_ID_FAILED = "PRODUCT_BY_CATEGORY_ID_FAILED";
const PRODUCT_BY_CATEGORY_ID_SUCCEEDED = "PRODUCT_BY_CATEGORY_ID_SUCCEEDED";

//App Settings
const GET_APP_SETTINGS_DETAIL_PENDING = "GET_APP_SETTINGS_DETAIL_PENDING";
const GET_APP_SETTINGS_DETAIL_IDLE = "GET_APP_SETTINGS_DETAIL_IDLE";
const GET_APP_SETTINGS_DETAIL_FAILED = "GET_APP_SETTINGS_DETAIL_FAILED";
const GET_APP_SETTINGS_DETAIL_SUCCEEDED = "GET_APP_SETTINGS_DETAIL_SUCCEEDED";

// cache Report
const GET_VENUE_CACHE_REPORT_BY_VENUE_ID_IDLE = "GET_VENUE_CACHE_REPORT_BY_VENUE_ID_IDLE";
const GET_VENUE_CACHE_REPORT_BY_VENUE_ID_PENDING = "GET_VENUE_CACHE_REPORT_BY_VENUE_ID_PENDING";
const GET_VENUE_CACHE_REPORT_BY_VENUE_ID_FAILED = "GET_VENUE_CACHE_REPORT_BY_VENUE_ID_FAILED";
const GET_VENUE_CACHE_REPORT_BY_VENUE_ID_SUCCEEDED = "GET_VENUE_CACHE_REPORT_BY_VENUE_ID_SUCCEEDED";

// Delete product
const DELETE_PRODUCT_BY_ID_IDLE = "DELETE_PRODUCT_BY_ID_IDLE";
const DELETE_PRODUCT_BY_ID_PENDING = "DELETE_PRODUCT_BY_ID_PENDING";
const DELETE_PRODUCT_BY_ID_FAILED = "DELETE_PRODUCT_BY_ID_FAILED";
const DELETE_PRODUCT_BY_ID_SUCCEEDED = "DELETE_PRODUCT_BY_ID_SUCCEEDED";

// Cart
const CART_LOADING = "CART_LOADING";
const ADD_TO_CART = "ADD_TO_CART";
const UPDATE_CART = "UPDATE_CART";
const CLEAR_CART = "CLEAR_CART";

// Reservation_REPORT by Venue
const GET_RESERVATION_REPORT_BY_VENUE_IDLE = "GET_RESERVATION_REPORT_BY_VENUE_IDLE";
const GET_RESERVATION_REPORT_BY_VENUE_PENDING = "GET_RESERVATION_REPORT_BY_VENUE_PENDING";
const GET_RESERVATION_REPORT_BY_VENUE_FAILED = "GET_RESERVATION_REPORT_BY_VENUE_FAILED";
const GET_RESERVATION_REPORT_BY_VENUE_SUCCEEDED = "GET_RESERVATION_REPORT_BY_VENUE_SUCCEEDED";

// Reservation_Summary by user
const GET_GUEST_SUMMARY_BY_USER_IDLE = "GET_GUEST_SUMMARY_BY_USER_IDLE";
const GET_GUEST_SUMMARY_BY_USER_PENDING = "GET_GUEST_SUMMARY_BY_USER_PENDING";
const GET_GUEST_SUMMARY_BY_USER_FAILED = "GET_GUEST_SUMMARY_BY_USER_FAILED";
const GET_GUEST_SUMMARY_BY_USER_SUCCEEDED = "GET_GUEST_SUMMARY_BY_USER_SUCCEEDED";

//Deposite_Settings by Venue
const GET_VENUE_DEPOSITE_SETTINGS_IDLE = "GET_VENUE_DEPOSITE_SETTINGS_IDLE";
const GET_VENUE_DEPOSITE_SETTINGS_PENDING = "GET_VENUE_DEPOSITE_SETTINGS_PENDING";
const GET_VENUE_DEPOSITE_SETTINGS_FAILED = "GET_VENUE_DEPOSITE_SETTINGS_FAILED";
const GET_VENUE_DEPOSITE_SETTINGS_SUCCEEDED = "GET_VENUE_DEPOSITE_SETTINGS_SUCCEEDED";

// Send Reservation Report
const SEND_RESERVATION_REPORT_IDLE = "SEND_RESERVATION_REPORT_IDLE";
const SEND_RESERVATION_REPORT_PENDING = "SEND_RESERVATION_REPORT_PENDING";
const SEND_RESERVATION_REPORT_FAILED = "SEND_RESERVATION_REPORT_FAILED";
const SEND_RESERVATION_REPORT_SUCCEEDED = "SEND_RESERVATION_REPORT_SUCCEEDED";

// Send Guest Summary Report
const SEND_GUEST_SUMMARY_IDLE = "SEND_GUEST_SUMMARY_IDLE";
const SEND_GUEST_SUMMARY_PENDING = "SEND_GUEST_SUMMARY_PENDING";
const SEND_GUEST_SUMMARY_FAILED = "SEND_GUEST_SUMMARY_FAILED";
const SEND_GUEST_SUMMARY_SUCCEEDED = "SEND_GUEST_SUMMARY_SUCCEEDED";

// Send Cache Report
const SEND_CACHE_REPORT_IDLE = "SEND_CACHE_REPORT_IDLE";
const SEND_CACHE_REPORT_PENDING = "SEND_CACHE_REPORT_PENDING";
const SEND_CACHE_REPORT_FAILED = "SEND_CACHE_REPORT_FAILED";
const SEND_CACHE_REPORT_SUCCEEDED = "SEND_CACHE_REPORT_SUCCEEDED";

// Widget Venue Time for Table
const GET_WIDGET_VENUE_TIME_PENDING = "GET_WIDGET_VENUE_TIME_PENDING";
const GET_WIDGET_VENUE_TIME_IDLE = "GET_WIDGET_VENUE_TIME_IDLE";
const GET_WIDGET_VENUE_TIME_FAILED = "GET_WIDGET_VENUE_TIME_FAILED";
const GET_WIDGET_VENUE_TIME_SUCCEEDED = "GET_WIDGET_VENUE_TIME_SUCCEEDED";

export {
  // Common
  APP_LOADING,
  CURRENT_ROUTE_NAME,
  CURRENT_SELECTED_ROUTE,
  NOTIFICATION_ROUTE,

  // Auth
  LOGIN_IDLE,
  LOGIN_PENDING,
  LOGIN_FAILED,
  LOGIN_SUCCEEDED,
  RESET_LOGIN_GROUP,

  // signup
  SIGNUP_IDLE,
  SIGNUP_PENDING,
  SIGNUP_FAILED,
  SIGNUP_SUCCEEDED,

  // Forgot password
  FORGOT_PASSWORD_IDLE,
  FORGOT_PASSWORD_PENDING,
  FORGOT_PASSWORD_FAILED,
  FORGOT_PASSWORD_SUCCEEDED,

  // OTP
  OTP_IDLE,
  OTP_PENDING,
  OTP_FAILED,
  OTP_SUCCEEDED,

  // Reset password
  RESET_PASSWORD_IDLE,
  RESET_PASSWORD_PENDING,
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_SUCCEEDED,

  //profile
  UPDATE_PROFILE_IDLE,
  UPDATE_PROFILE_PENDING,
  UPDATE_PROFILE_FAILED,
  UPDATE_PROFILE_SUCCEEDED,

  // Venue detail
  VENUE_IDLE,
  VENUE_PENDING,
  VENUE_FAILED,
  VENUE_SUCCEEDED,
  GET_ALL_VENUE_IDLE,
  GET_ALL_VENUE_PENDING,
  GET_ALL_VENUE_FAILED,
  GET_ALL_VENUE_SUCCEEDED,

  // Delete venue user
  DELETE_VENUE_USER_IDLE,
  DELETE_VENUE_USER_PENDING,
  DELETE_VENUE_USER_FAILED,
  DELETE_VENUE_USER_SUCCEEDED,

  // Delete table details
  DELETE_VENUE_TABLE_DETAIL_PENDING,
  DELETE_VENUE_TABLE_DETAIL_IDLE,
  DELETE_VENUE_TABLE_DETAIL_FAILED,
  DELETE_VENUE_TABLE_DETAIL_SUCCEEDED,

  // Checkin Reservation 
  RESERVATION_CHECKIN_IDLE,
  RESERVATION_CHECKIN_PENDING,
  RESERVATION_CHECKIN_FAILED,
  RESERVATION_CHECKIN_SUCCEEDED,

  // Cancel Reservation 
  RESERVATION_CANCEL_IDLE,
  RESERVATION_CANCEL_PENDING,
  RESERVATION_CANCEL_FAILED,
  RESERVATION_CANCEL_SUCCEEDED,

  // Reservation 
  RESERVATION_IDLE,
  RESERVATION_PENDING,
  RESERVATION_FAILED,
  RESERVATION_SUCCEEDED,
  SET_RESERVATION_FILTER_STATE,

  // Reservation Detail
  RESERVATION_DETAIL_IDLE,
  RESERVATION_DETAIL_PENDING,
  RESERVATION_DETAIL_FAILED,
  RESERVATION_DETAIL_SUCCEEDED,

  //** Get current date by venue id */
  GET_CURRENT_DATE_BY_VENUE_ID_IDLE,
  GET_CURRENT_DATE_BY_VENUE_ID_PENDING,
  GET_CURRENT_DATE_BY_VENUE_ID_FAILED,
  GET_CURRENT_DATE_BY_VENUE_ID_SUCCEEDED,

  // Create Reservation 
  CREATE_RESERVATION_IDLE,
  CREATE_RESERVATION_PENDING,
  CREATE_RESERVATION_FAILED,
  CREATE_RESERVATION_SUCCEEDED,

  // QUEUE
  GET_QUEUE_RESERVATION_IDLE,
  GET_QUEUE_RESERVATION_PENDING,
  GET_QUEUE_RESERVATION_FAILED,
  GET_QUEUE_RESERVATION_SUCCEEDED,

  // QUEUE PRODUCT
  GET_QUEUE_RESERVATION_PRODUCT_IDLE,
  GET_QUEUE_RESERVATION_PRODUCT_PENDING,
  GET_QUEUE_RESERVATION_PRODUCT_FAILED,
  GET_QUEUE_RESERVATION_PRODUCT_SUCCEEDED,

  // QUEUE SUB PRODUCT
  GET_QUEUE_RESERVATION_SUB_PRODUCT_IDLE,
  GET_QUEUE_RESERVATION_SUB_PRODUCT_PENDING,
  GET_QUEUE_RESERVATION_SUB_PRODUCT_FAILED,
  GET_QUEUE_RESERVATION_SUB_PRODUCT_SUCCEEDED,

  // QUEUE STATE(Requeset server/Request Manager/Close Check)
  UPDATE_QUEUE_RESERVATION_STATE_IDLE,
  UPDATE_QUEUE_RESERVATION_STATE_PENDING,
  UPDATE_QUEUE_RESERVATION_STATE_FAILED,
  UPDATE_QUEUE_RESERVATION_STATE_SUCCEEDED,

  //Search user or get user detail
  GET_USER_DETAIL_IDLE,
  GET_USER_DETAIL_PENDING,
  GET_USER_DETAIL_FAILED,
  GET_USER_DETAIL_SUCCEEDED,

  //User Detail
  GET_USER_DETAIL,
  GET_ALL_USERS_IDLE,
  GET_ALL_USERS_PENDING,
  GET_ALL_USERS_FAILED,
  GET_ALL_USERS_SUCCEEDED,

  //Delete User Detail
  DELETE_USERS_IDLE,
  DELETE_USERS_PENDING,
  DELETE_USERS_FAILED,
  DELETE_USERS_SUCCEEDED,

  // Venue detail view
  VENUE_VIEW_IDLE,
  VENUE_VIEW_PENDING,
  VENUE_VIEW_FAILED,
  VENUE_VIEW_SUCCEEDED,

  //Product
  PRODUCT_IDLE,
  PRODUCT_PENDING,
  PRODUCT_FAILED,
  PRODUCT_SUCCEEDED,
  PRODUCT_CATEGORY_IDLE,
  PRODUCT_CATEGORY_PENDING,
  PRODUCT_CATEGORY_FAILED,
  PRODUCT_CATEGORY_SUCCEEDED,

  // Product category
  PRODUCT_CATEGORY_BY_VENUE_ID_IDLE,
  PRODUCT_CATEGORY_BY_VENUE_ID_PENDING,
  PRODUCT_CATEGORY_BY_VENUE_ID_FAILED,
  PRODUCT_CATEGORY_BY_VENUE_ID_SUCCEEDED,
  INSERT_UPDATE_PRODUCT_CATEGORY_IDLE,
  INSERT_UPDATE_PRODUCT_CATEGORY_PENDING,
  INSERT_UPDATE_PRODUCT_CATEGORY_FAILED,
  INSERT_UPDATE_PRODUCT_CATEGORY_SUCCEEDED,
  INSERT_UPDATE_PRODUCT_IDLE,
  INSERT_UPDATE_PRODUCT_PENDING,
  INSERT_UPDATE_PRODUCT_FAILED,
  INSERT_UPDATE_PRODUCT_SUCCEEDED,

  // Activity
  ACTIVITY_IDLE,
  ACTIVITY_PENDING,
  ACTIVITY_FAILED,
  ACTIVITY_SUCCEEDED,

  // Activity BY GUEST - Venue
  ACTIVITY_SUCCEEDED_BY_GUEST,

  // Activity By Reservation - End User
  ACTIVITY_SUCCEEDED_BY_RESERVATION,

  //Insert or update activity 
  INSERT_OR_UPDATE_ACTIVITY_IDLE,
  INSERT_OR_UPDATE_ACTIVITY_PENDING,
  INSERT_OR_UPDATE_ACTIVITY_FAILED,
  INSERT_OR_UPDATE_ACTIVITY_SUCCEEDED,

  //Venue user detail
  GET_VENUE_USERS_IDLE,
  GET_VENUE_USERS_PENDING,
  GET_VENUE_USERS_FAILED,
  GET_VENUE_USERS_SUCCEEDED,

  // Table Detail for Reservation 
  GET_TABLE_DETAIL_IDLE,
  GET_TABLE_DETAIL_PENDING,
  GET_TABLE_DETAIL_FAILED,
  GET_TABLE_DETAIL_SUCCEEDED,

  // Order item
  GET_ORDER_ITEMS_BY_RESERVATION_ID_IDLE,
  GET_ORDER_ITEMS_BY_RESERVATION_ID_PENDING,
  GET_ORDER_ITEMS_BY_RESERVATION_ID_FAILED,
  GET_ORDER_ITEMS_BY_RESERVATION_ID_SUCCEEDED,

  // Insert Order item
  INSERT_ORDER_BY_RESERVATION_ID_IDLE,
  INSERT_ORDER_BY_RESERVATION_ID_PENDING,
  INSERT_ORDER_BY_RESERVATION_ID_FAILED,
  INSERT_ORDER_BY_RESERVATION_ID_SUCCEEDED,

  // Delete Order item
  DELETE_ORDER_ITEMS_IDLE,
  DELETE_ORDER_ITEMS_PENDING,
  DELETE_ORDER_ITEMS_FAILED,
  DELETE_ORDER_ITEMS_SUCCEEDED,

  // Send Order item
  SEND_ORDER_ITEMS_IDLE,
  SEND_ORDER_ITEMS_PENDING,
  SEND_ORDER_ITEMS_FAILED,
  SEND_ORDER_ITEMS_SUCCEEDED,

  // Others
  COUNTER_CHANGE,

  // Report
  GET_VENUE_CACHE_REPORT_BY_DATE_IDLE,
  GET_VENUE_CACHE_REPORT_BY_DATE_PENDING,
  GET_VENUE_CACHE_REPORT_BY_DATE_FAILED,
  GET_VENUE_CACHE_REPORT_BY_DATE_SUCCEEDED,

  // Venue Users
  GET_VENUE_USERS_BY_VENUE_ID_IDLE,
  GET_VENUE_USERS_BY_VENUE_ID_PENDING,
  GET_VENUE_USERS_BY_VENUE_ID_FAILED,
  GET_VENUE_USERS_BY_VENUE_ID_SUCCEEDED,

  // Venue Settings By Day
  GET_VENUE_SETTING_BY_DAY_IDLE,
  GET_VENUE_SETTING_BY_DAY_PENDING,
  GET_VENUE_SETTING_BY_DAY_FAILED,
  GET_VENUE_SETTING_BY_DAY_SUCCEEDED,

  // Venue Detail
  GET_VENUE_DETAIL_BY_VENUE_ID_IDLE,
  GET_VENUE_DETAIL_BY_VENUE_ID_PENDING,
  GET_VENUE_DETAIL_BY_VENUE_ID_FAILED,
  GET_VENUE_DETAIL_BY_VENUE_ID_SUCCEEDED,

  // City
  FETCH_ALL_CITY_IDLE,
  FETCH_ALL_CITY_PENDING,
  FETCH_ALL_CITY_FAILED,
  FETCH_ALL_CITY_SUCCEEDED,

  // State
  FETCH_ALL_STATE_IDLE,
  FETCH_ALL_STATE_PENDING,
  FETCH_ALL_STATE_FAILED,
  FETCH_ALL_STATE_SUCCEEDED,

  // Country
  FETCH_ALL_COUNTRY_IDLE,
  FETCH_ALL_COUNTRY_PENDING,
  FETCH_ALL_COUNTRY_FAILED,
  FETCH_ALL_COUNTRY_SUCCEEDED,

  // Venue Reservation Detail
  GET_VENUE_RESERVATION_DETAIL_BY_VENUE_ID_IDLE,
  GET_VENUE_RESERVATION_DETAIL_BY_VENUE_ID_PENDING,
  GET_VENUE_RESERVATION_DETAIL_BY_VENUE_ID_FAILED,
  GET_VENUE_RESERVATION_DETAIL_BY_VENUE_ID_SUCCEEDED,

  // Get User Detail By Id

  GET_USERS_BY_ID_IDLE,
  GET_USERS_BY_ID_PENDING,
  GET_USERS_BY_ID_FAILED,
  GET_USERS_BY_ID_SUCCEEDED,

  //Post user details
  POST_USERS_BY_ID_IDLE,
  POST_USERS_BY_ID_PENDING,
  POST_USERS_BY_ID_FAILED,
  POST_USERS_BY_ID_SUCCEEDED,

  //Post Table details
  GET_VENUE_TABLE_BY_TABLE_ID_IDLE,
  GET_VENUE_TABLE_BY_VENUE_ID_PENDING,
  GET_VENUE_TABLE_BY_VENUE_ID_FAILED,
  GET_VENUE_TABLE_BY_VENUE_ID_SUCCEEDED,

  // Get Block Dates
  GET_VENUE_BLOCK_DATES_ID_IDLE,
  GET_VENUE_BLOCK_DATES_ID_PENDING,
  GET_VENUE_BLOCK_DATES_ID_FAILED,
  GET_VENUE_BLOCK_DATES_ID_SUCCEEDED,

  // Get Venue Premium Dates
  GET_VENUE_PREMIUM_DATE_RANGE_ID_IDLE,
  GET_VENUE_PREMIUM_DATE_RANGE_ID_PENDING,
  GET_VENUE_PREMIUM_DATE_RANGE_ID_FAILED,
  GET_VENUE_PREMIUM_DATE_RANGE_ID_SUCCEEDED,

  //Get Product Category
  GET_PRODUCT_CATEGORY_BY_VENUE_ID_IDLE,
  GET_PRODUCT_CATEGORY_BY_VENUE_ID_PENDING,
  GET_PRODUCT_CATEGORY_BY_VENUE_ID_FAILED,
  GET_PRODUCT_CATEGORY_BY_VENUE_ID_SUCCEEDED,

  // Table Detail
  GET_TABLE_DETAIL_BY_ID_IDLE,
  GET_TABLE_DETAIL_BY_ID_PENDING,
  GET_TABLE_DETAIL_BY_ID_FAILED,
  GET_TABLE_DETAIL_BY_ID_SUCCEEDED,

  // Web Reservation
  GET_WEB_RESERVATION_BY_VENUE_ID_IDLE,
  GET_WEB_RESERVATION_BY_VENUE_ID_PENDING,
  GET_WEB_RESERVATION_BY_VENUE_ID_FAILED,
  GET_WEB_RESERVATION_BY_VENUE_ID_SUCCEEDED,

  // Accept Reservation 
  RESERVATION_ACCEPT_IDLE,
  RESERVATION_ACCEPT_PENDING,
  RESERVATION_ACCEPT_FAILED,
  RESERVATION_ACCEPT_SUCCEEDED,

  //Product Category by id

  GET_PRODUCT_CATEGORY_BY_ID_IDLE,
  GET_PRODUCT_CATEGORY_BY_ID_PENDING,
  GET_PRODUCT_CATEGORY_BY_ID_FAILED,
  GET_PRODUCT_CATEGORY_BY_ID_SUCCEEDED,

  // Product  (By  ID)
  PRODUCT_BY_ID_IDLE,
  PRODUCT_BY_ID_PENDING,
  PRODUCT_BY_ID_FAILED,
  PRODUCT_BY_ID_SUCCEEDED,

  // Product  (By Category ID)
  PRODUCT_BY_CATEGORY_ID_IDLE,
  PRODUCT_BY_CATEGORY_ID_PENDING,
  PRODUCT_BY_CATEGORY_ID_FAILED,
  PRODUCT_BY_CATEGORY_ID_SUCCEEDED,

  //App settings
  GET_APP_SETTINGS_DETAIL_PENDING,
  GET_APP_SETTINGS_DETAIL_IDLE,
  GET_APP_SETTINGS_DETAIL_FAILED,
  GET_APP_SETTINGS_DETAIL_SUCCEEDED,

  // cache Report
  GET_VENUE_CACHE_REPORT_BY_VENUE_ID_IDLE,
  GET_VENUE_CACHE_REPORT_BY_VENUE_ID_PENDING,
  GET_VENUE_CACHE_REPORT_BY_VENUE_ID_FAILED,
  GET_VENUE_CACHE_REPORT_BY_VENUE_ID_SUCCEEDED,

  //Delete product
  DELETE_PRODUCT_BY_ID_IDLE,
  DELETE_PRODUCT_BY_ID_PENDING,
  DELETE_PRODUCT_BY_ID_FAILED,
  DELETE_PRODUCT_BY_ID_SUCCEEDED,

  //Filter reservation for venue online reservation screen 
  FILTER_WIDGET_RESERVATION_IDLE,
  FILTER_WIDGET_RESERVATION_PENDING,
  FILTER_WIDGET_RESERVATION_FAILED,
  FILTER_WIDGET_RESERVATION_SUCCEEDED,
  SET_FILTER_WIDGET_RESERVATION_FILTER_STATE,

  // Venue QUEUE
  GET_VENUE_RESERVATIONS_QUEUE_IDLE,
  GET_VENUE_RESERVATIONS_QUEUE_PENDING,
  GET_VENUE_RESERVATIONS_QUEUE_FAILED,
  GET_VENUE_RESERVATIONS_QUEUE_SUCCEEDED,

  // Cart
  CART_LOADING,
  ADD_TO_CART,
  UPDATE_CART,
  CLEAR_CART,

  //Header Filter reservation for venue online reservation screen 
  HEADER_FILTER_WIDGET_RESERVATION_IDLE,
  HEADER_FILTER_WIDGET_RESERVATION_PENDING,
  HEADER_FILTER_WIDGET_RESERVATION_FAILED,
  HEADER_FILTER_WIDGET_RESERVATION_SUCCEEDED,

  // TOGGLE VENUE STATE
  TOGGLE_VENUE_STATE_IDLE,
  TOGGLE_VENUE_STATE_PENDING,
  TOGGLE_VENUE_STATE_FAILED,
  TOGGLE_VENUE_STATE_SUCCEEDED,

  // ** Get Cache Report by Date ** //
  GET_CACHE_REPORT_BY_DATE_IDLE,
  GET_CACHE_REPORT_BY_DATE_PENDING,
  GET_CACHE_REPORT_BY_DATE_FAILED,
  GET_CACHE_REPORT_BY_DATE_SUCCEEDED,

  // Reservation_Report by venue
  GET_RESERVATION_REPORT_BY_VENUE_IDLE,
  GET_RESERVATION_REPORT_BY_VENUE_PENDING,
  GET_RESERVATION_REPORT_BY_VENUE_SUCCEEDED,
  GET_RESERVATION_REPORT_BY_VENUE_FAILED,

  // Reservation_Summary by user
  GET_GUEST_SUMMARY_BY_USER_IDLE,
  GET_GUEST_SUMMARY_BY_USER_PENDING,
  GET_GUEST_SUMMARY_BY_USER_FAILED,
  GET_GUEST_SUMMARY_BY_USER_SUCCEEDED,


  // Venue Deposite Settings
  GET_VENUE_DEPOSITE_SETTINGS_IDLE,
  GET_VENUE_DEPOSITE_SETTINGS_PENDING,
  GET_VENUE_DEPOSITE_SETTINGS_FAILED,
  GET_VENUE_DEPOSITE_SETTINGS_SUCCEEDED,

  // Send Reservation Report
  SEND_RESERVATION_REPORT_IDLE,
  SEND_RESERVATION_REPORT_PENDING,
  SEND_RESERVATION_REPORT_FAILED,
  SEND_RESERVATION_REPORT_SUCCEEDED,

  // Send Guest Summary Report
  SEND_GUEST_SUMMARY_IDLE,
  SEND_GUEST_SUMMARY_PENDING,
  SEND_GUEST_SUMMARY_FAILED,
  SEND_GUEST_SUMMARY_SUCCEEDED,

  // Send Cache Report
  SEND_CACHE_REPORT_IDLE,
  SEND_CACHE_REPORT_PENDING,
  SEND_CACHE_REPORT_FAILED,
  SEND_CACHE_REPORT_SUCCEEDED,

  // Widget Venue Time for Table
  GET_WIDGET_VENUE_TIME_PENDING,
  GET_WIDGET_VENUE_TIME_IDLE,
  GET_WIDGET_VENUE_TIME_FAILED,
  GET_WIDGET_VENUE_TIME_SUCCEEDED,

  // Get Floor List
  GET_FLOOR_LIST_BY_VENUE_ID_IDLE, 
  GET_FLOOR_LIST_BY_VENUE_ID_PENDING,
  GET_FLOOR_LIST_BY_VENUE_ID_FAILED,
  GET_FLOOR_LIST_BY_VENUE_ID_SUCCEEDED,
  
  SELECTED_FLOOR,

  // Update Venue Floor Setting
  UPDATE_VENUE_FLOOR_SETTING_IDLE,
  UPDATE_VENUE_FLOOR_SETTING_PENDING,
  UPDATE_VENUE_FLOOR_SETTING_FAILED,
  UPDATE_VENUE_FLOOR_SETTING_SUCCEEDED
}
